import { api_call, make_response_object } from 'api/base'
import { param } from 'jquery'


export async function listStudents(schoolId, params) {
    const r = await api_call('/schools/' + schoolId + '/students', 'GET', {}, params)
    return await make_response_object(r)
}


export async function getStudent(schoolId, studentId, params = {}) {
    const r = await api_call('/schools/' + schoolId + '/students/' + studentId, 'GET', {}, params)
    return await make_response_object(r)
}


export async function createStudent(schoolId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/students',
        'POST',
        body
    )
    return await make_response_object(r)
}


export async function updateStudent(schoolId, studentId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/students/' + studentId,
        'PUT',
        body
        )
    return await make_response_object(r)
}


export async function deleteStudent(schoolId, studentId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/students/' + studentId,
        'DELETE',
    )
    return await make_response_object(r)
}


export async function undeleteStudent(schoolId, studentId) {
    const r = await api_call(
        '/schools/' + schoolId + '/students/deleted/' + studentId,
        'PATCH',
    )
    return await make_response_object(r)
}


export async function listUnpaidAssignedStoreItems(schoolId, studentId) {
    const r = await api_call(
        '/schools/' + schoolId + '/students/' + studentId + '/reports/unpaid',
        'GET'
    )
    return await make_response_object(r)
}


export async function listOrderItemsByStudent(schoolId, studentId, params) {
    const r= await api_call(
        '/schools/' + schoolId + '/students/' + studentId + '/order_items',
        'GET',
        {},
        params
    )
    return await make_response_object(r)
}


export async function listPaidStoreItems(schoolId, studentId) {
    const r = await api_call(
        '/schools/' + schoolId + '/students/' + studentId + '/reports/paid',
        'GET'
    )
    return await make_response_object(r)
}


export async function listStudentOrderItems(schoolId, studentId, params) {
    const r = await api_call(
        '/schools/' + schoolId + '/students/' + studentId + '/order_items',
        'GET',
        {},
        params
    )
    return await make_response_object(r)
}