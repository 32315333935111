import React from 'react';


export const SchoolContext = React.createContext(['', () => {}])

export const SchoolProvider = (props) => {
    const [currentSchool, setcurrentSchool] = React.useState({})

    var cSchool = localStorage.getItem('currentSchool')
    if (cSchool == null){
        cSchool = {};
    } else{
        cSchool = JSON.parse(cSchool)
    }

    function setSchool(school) {
        let s = {}
        s['value'] = school.id
        s['label'] = school.display_name

        if (school.lunches_settings) {
            s['lunches_settings'] = school.lunches_settings
          }
        else {
            s['lunches_settings'] = { nslp_enabled: false }
        }
        localStorage.setItem('currentSchool', JSON.stringify(s))
        setcurrentSchool(s)
    }

    return (
        <SchoolContext.Provider value={[cSchool, setSchool]}>
            {props.children}
        </SchoolContext.Provider>
    )
}
