import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Paper from '@material-ui/core/Paper';
import { SchoolContext } from 'components/SchoolProvider.js'
import { UserContext } from 'components/UserProvider.js'
import { useHistory } from 'react-router-dom';
import { ControlledInputInternals } from 'components/CustomInput/CustomInput';
import { getStoreGroup } from 'api/storegroups';
import { TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Box, Grid, IconButton } from '@material-ui/core'
import { MaterialNumberFormat } from 'components/CustomInput/NumberFormat.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { SubmitButton } from 'components/CustomButtons/Button';
import { createStoreGroup } from 'api/storegroups';
import { updateStoreGroup } from 'api/storegroups';
import { createStoreItem } from 'api/storeitems';
import { deleteStoreItem } from 'api/storeitems';
import StoreItemPayoutMethodSelect from 'components/CustomInput/StoreItemPayoutMethodSelect';



const useStyles = makeStyles(styles)


export default function EditLunchVendor(props) {
    const classes = useStyles()
    const currentSchool = React.useContext(SchoolContext)[0]
    const [loading, setLoading] = React.useState(true)
    const [submitting, setSubmitting] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const [vendor, setVendor] = React.useState({})
    const [items, setItems] = React.useState([])
    const [displayNewItemForm, setDisplayNewItemForm] = React.useState(false)
    const [itemSubmitting, setItemSubmitting] = React.useState(false)
    const [itemSuccess, setItemSuccess] = React.useState(false)
    const [itemDeleting, setItemDeleting] = React.useState(false)
    let history = useHistory()

    const { enqueueSnackbar } = useSnackbar()

    // Determine if we are creating or editing
    var editing = false;
    var vendorId = '';
    if (props.location.pathname.split('/')[3] === 'edit_vendor') {
        editing = true;
        vendorId = props.location.pathname.split('/')[4]
    }

    const [formState, dispatch, basicInputOnChange, onChange] = ControlledInputInternals()

    // Inputs for StoreGroup
    const inputs = ['display_name', 'lunches_order_cutoff_days', 'payout_method_id']
    const { display_name, lunches_order_cutoff_days, payout_method_id } = formState


    const [itemFormState, itemDispatch, itemBasicInputOnChange] = ControlledInputInternals()

    // Inputs for LunchesFields
    const itemInputs = ['new_item_display_name', 'new_item_cost', 'new_item_nslp_eligible']
    const { new_item_display_name, new_item_cost, new_item_nslp_eligible } = itemFormState


    React.useEffect(() => {
        async function initializePage() {
            if (editing) {
                let r = await getStoreGroup(currentSchool.value, vendorId)
                if (r.ok) {
                    setVendor(r.data)
                    inputs.map(input => (
                        dispatch({ field: input, value: r.data[input] })
                    ))
                    if (r.data.items !== undefined) {
                        setItems(r.data.items)
                    }
                    setLoading(false)
                }
                else {
                    window.location = '/admin'
                }
            }
            else {
                setLoading(false)
            }
        }
        initializePage()
    }, [])


    async function saveVendor(e) {
        e.preventDefault()
        let form = document.querySelector('#vendorForm')
        if (!form.reportValidity()){
            return
        }
        setSubmitting(true)
        setSuccess(false)
        
        let r = null
        let snackbarText = ''
        if (editing) {
            r = await updateStoreGroup(
                currentSchool.value,
                vendorId,
                formState
            )
            snackbarText = 'updated'
        }
        else {
            r = await createStoreGroup(
                currentSchool.value,
                {...formState, ...{category_type: 'lunches'}}
            )
            snackbarText = 'created'
        }
        setSubmitting(false)
        if (r.ok) {
            enqueueSnackbar('Vendor ' + snackbarText, {variant: 'success'})
            setVendor(r.data)
            setSuccess(true)
            if (!editing) {
                history.push('/admin/lunches/edit_vendor/' + r.data.id)
            }
        }
        
        
    }


    async function saveItem(e) {
        e.preventDefault()
        let form = document.querySelector('#newItemForm')
        if (!form.reportValidity()){
            return
        }

        setItemSubmitting(true)
        setItemSuccess(false)

        // Marshmallow on server won't accept empty string as bool which occurs when adding multiple items
        let elg = new_item_nslp_eligible
        if (elg === '') {
            elg = undefined
        }

        const r = await createStoreItem(
            currentSchool.value,
            {store_item: {
                display_name: new_item_display_name,
                cost: new_item_cost,
                category_type: 'lunches',
                group_id: vendorId,
                lunches_fields: {
                    nslp_eligible: elg
                }
            }
        }
        )
        if (r.ok) {
            const r = await getStoreGroup(currentSchool.value, vendorId)
            if (r.ok) {
                setVendor(r.data)
                inputs.map(input => (
                    dispatch({ field: input, value: r.data[input] })
                ))
                if (r.data.items !== undefined) {
                    setItems(r.data.items)
                }
                setItemSuccess(true)
                itemInputs.map(i => (
                    itemDispatch({ field: i, value: ''})
                ))
                setDisplayNewItemForm(false)
            }
            else {
                window.location = '/admin'
            }
        }
        else {
            enqueueSnackbar('Error creating menu item', {variant: 'error'})
        }

        setItemSubmitting(false)
    }


    async function deleteItem(itemId) {
        setItemDeleting(true)
        const r = await deleteStoreItem(currentSchool.value, itemId)
        if (r.ok) {
            const r = await getStoreGroup(currentSchool.value, vendorId)
            if (r.ok) {
                setVendor(r.data)
                inputs.map(input => (
                    dispatch({ field: input, value: r.data[input] })
                ))
                if (r.data.items !== undefined) {
                    setItems(r.data.items)
                }
            }
            else {
                window.location = '/admin'
            }
        }
        else {
            enqueueSnackbar('Error deleting menu item', {variant: 'error'})
        }
        setItemDeleting(false)
    }


    if (loading) {
        return (null)
    }
    else {
        return (
            <Paper
                className={classes.pagePaper}
            >
                <form id='vendorForm'>
                    <Box>
                        <TextField
                            id='display_name'
                            label='Vendor Name'
                            variant='outlined'
                            value={display_name}
                            onChange={basicInputOnChange}
                            required
                        />
                    </Box>
                    <br />
                    <Box>
                        <TextField
                            id='lunches_order_cutoff_days'
                            label='Days in advance to cut off orders'
                            variant='outlined'
                            value={lunches_order_cutoff_days}
                            onChange={basicInputOnChange}
                            type='number'
                        />
                    </Box>
                    <br />
                    <Box>
                        <StoreItemPayoutMethodSelect
                            id='payout_method_id'
                            value={payout_method_id}
                            onChange={onChange}
                        />

                    </Box>
                    <Grid
                        container
                        direction='row'
                        justify='flex-end'
                        alignItems='center'
                    >
                        <SubmitButton
                            submitting={submitting}
                            success={success}
                            buttonText={editing ? 'Update Vendor Name' : 'Create Vendor'}
                            onClick={saveVendor}
                        />
                    </Grid>
                </form>
                {!editing ? '' : (
                    <>
                        <br />
                        <Box fontSize={18}>
                            Menu Items
                        </Box>
                        {items.map(item => (
                                    <Box m={1}>
                                        <Grid
                                            container
                                            spacing={1}
                                            key={item.id}
                                        >
                                            <Grid item>
                                                <TextField
                                                    name='item_display_name'
                                                    label='Item Name'
                                                    variant='outlined'
                                                    value={item.display_name}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item>
                                                <MaterialNumberFormat
                                                    name='item_cost'
                                                    label='Price'
                                                    variant='outlined'
                                                    value={item.cost}
                                                    disabled
                                                />
                                            </Grid>
                                        {(currentSchool.lunches_settings.nslp_enabled && currentSchool.lunches_settings.item_eligibility === 'specific') &&
                                            <Grid item style={{ display: 'flex', alignItems: 'center'}}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={item.lunches_fields.nslp_eligible}
                                                            color='primary'
                                                            disabled
                                                        />
                                                    }
                                                    label='FRL Enabled'
                                                />
                                            </Grid>
                                            }
                                            <Grid item >
                                                <SubmitButton
                                                    iconButton
                                                    onClick={() => deleteItem(item.id)}
                                                    submitting={itemDeleting}
                                                    icon={faTrashAlt}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                        <br />
                        <form
                            id='newItemForm'
                            style={{ display: displayNewItemForm ? '' : 'none' }}
                        >
                            <Box m={1}>
                                <Grid
                                    container
                                    spacing={1}
                                >
                                    <Grid item>
                                        <TextField
                                            id='new_item_display_name'
                                            label='Item Name'
                                            variant='outlined'
                                            disabled={!displayNewItemForm}
                                            onChange={itemBasicInputOnChange}
                                            value={new_item_display_name}
                                            required
                                        />
                                    </Grid>
                                    <Grid item>
                                        <MaterialNumberFormat
                                            id='new_item_cost'
                                            label='Price'
                                            variant='outlined'
                                            disabled={!displayNewItemForm}
                                            onChange={itemBasicInputOnChange}
                                            value={new_item_cost}
                                            required
                                        />
                                    </Grid>
                                    {(currentSchool.lunches_settings.nslp_enabled && currentSchool.lunches_settings.item_eligibility === 'specific') &&
                                        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id='new_item_nslp_eligible'
                                                        disabled={!displayNewItemForm}
                                                        checked={new_item_nslp_eligible}
                                                        onChange={itemBasicInputOnChange}
                                                        value='new_item_nslp_eligible'
                                                        color='primary'
                                                    />
                                                }
                                                label='FRL Enabled'
                                            />
                                        </Grid>
                                    }
                                    <Grid item>
                                        <SubmitButton
                                            iconButton
                                            onClick={saveItem}
                                            submitting={itemSubmitting}
                                            icon={faSave}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                        <br />
                        <Button
                            variant='contained'
                            color='primary'
                            startIcon={<FontAwesomeIcon icon={faPlus} />}
                            onClick={() => setDisplayNewItemForm(true)}
                        >
                            Add Menu Item
                        </Button>
                    </>
                )}
            </Paper>
        )
    }
    }