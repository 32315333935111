import React from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Paper from '@material-ui/core/Paper';
import { SchoolContext } from 'components/SchoolProvider.js'
import { listSuperAdmins } from 'api/users.js'
import { make_params_from_react_table } from 'api/base.js'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant'
import { useHistory } from "react-router-dom";
import ReactTablePreloader from 'components/Preloader/ReactTablePreloader.js';


const useStyles = makeStyles(styles);


export default function GlobalAccountsPage() {
    const classes = useStyles();
    const [userList, setUserList] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [pages, setPages] = React.useState(0)
    const reactTable = React.createRef();
    let history = useHistory();

    React.useEffect(() => {
        const params = make_params_from_react_table(reactTable.current.state)
        listSuperAdmins(params)
            .then(r => {
                setUserList(r.data.results)
                setPages(r.data.pages)
                setLoading(false)
            })
    }, []) // Never refire

    // Debounce listSuperAdmins API call so it doesn't fire on every filter keystroke
    const listSuperAdminsDebounced = useConstant(() =>
        AwesomeDebouncePromise(
            listSuperAdmins,
            400
        )
    );

    async function handleOnFetchData(state, instance) {
        // Don't fire on initial page load. Intial data load is handled in useEffect
        if (!loading) {
            setLoading(true)
            const params = make_params_from_react_table(state)
            const r = await listSuperAdminsDebounced(params)
            setUserList(r.data.results)
            setPages(r.data.pages)
            //setCurrentPage()
            setLoading(false)
        }
    }

    function rowHandlers(state, rowInfo, column, instance) {
        return{
            onClick: e => {
                history.push('/admin/edit_user/' + rowInfo.original.id)
            },
            style: {
                cursor: 'pointer'
            }
        } 
    }

    const columns = [
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'Email',
            accessor: 'email',
        }
    ]

    return(
        <Paper
            className={classes.pagePaper}
        >
            <ReactTable
                ref={reactTable}
                data={userList}
                columns={columns}
                //page={currentPage}
                pages={pages}
                defaultPageSize={10}
                filterable={true}
                loading={loading}
                LoadingComponent={ReactTablePreloader}
                onFetchData={handleOnFetchData}
                getTrProps={rowHandlers}
                className='-highlight'
                manual
            />
        </Paper>
    )


}