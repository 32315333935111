import React from 'react';
import { faBusAlt, faDollarSign, faHandHoldingUsd, faShoppingCart, faTrashAlt, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { faBellSchool } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, TableBody, TableContainer, List, ListItem, ListItemIcon, Button, Box, TextField, FormControl, InputLabel, MenuItem, FormLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from "@material-ui/core/styles";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { SchoolContext } from 'components/SchoolProvider.js';
import { UserContext } from 'components/UserProvider.js';
import { useSnackbar } from 'notistack';
import useConstant from 'use-constant'
import currency from 'currency.js';
import stripeLogo from 'assets/img/powered_by_stripe.png';
import { calculateTotals, checkout } from 'api/cart';



//import 'tinymce/themes/silver'
//import 'tinymce/plugins/paste';
//import 'tinymce/plugins/link';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-table-6/react-table.css';
import { make_filter_params } from 'api/base';
import { listStoreItems } from 'api/storeitems';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { listCategories } from 'api/category';
import { getGuardian } from 'api/guardians'
import GuardiansPage from 'views/Guardians/Guardians'
import ItemView from 'views/OnSiteTransaction/ItemView'
import Select from '@material-ui/core/Select';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import 'assets/css/stripeStyle.css';
import { SubmitButton } from 'components/CustomButtons/Button';
import Preloader from 'components/Preloader/Preloader';
import { api_base } from 'api/base';



const useStyles = makeStyles(styles)


export default function OnSiteTransaction(props) {
    const classes = useStyles()
    const currentSchool = React.useContext(SchoolContext)[0]
    const currentUser = React.useContext(UserContext)[0]
    const [activeStep, setActiveStep] = React.useState(0)
    const [currentCategory, setCurrentCategory] = React.useState(null)
    const [currentCart, setCurrentCart] = React.useState([])
    const [selectedItem, setSelectedItem] = React.useState(null)
    const [storeItems, setStoreItems] = React.useState([])
    const [storeItemsLoading, setStoreItemsLoading] = React.useState(false)
    const [studentSelectionRequired, setStudentSelectionRequired] = React.useState(false)
    const [categoriesAvail, setCategoriesAvail] = React.useState([])
    const [customerId, setCustomerId] = React.useState(null)
    const [customerAccount, setCustomerAccount] = React.useState({})
    const [paymentType, setPaymentType] = React.useState('card')
    const [subtotal, setSubtotal] = React.useState(currency(0))
    const [cardSubmitting, setCardSubmitting] = React.useState(false)
    const [cardSuccess, setCardSuccess] = React.useState(false)
    const [chosenPaymentMethod, setChosenPaymentMethod] = React.useState('')
    const [checkoutLoading, setCheckoutLoading] = React.useState(true)
    const [totals, setTotals] = React.useState({})
    const [submitting, setSubmitting] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const [customerOrder, setCustomerOrder] = React.useState({})
    const stripe = useStripe()
    const elements = useElements()
    const [checkNumber, setCheckNumber] = React.useState(null)

    const { enqueueSnackbar } = useSnackbar();

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        }
    }

    React.useEffect(() => {
        async function handleSchoolChange() {
            const categories = await listCategories(currentSchool.value)
            let cA = []
            categories.data.results.forEach(c => {
                if (c.category_type !== 'lunches') {
                    cA.push(c.category_type)
                }
            })
            setCategoriesAvail(cA)
        }
        handleSchoolChange()
    }, [currentSchool])


    React.useEffect(() => {
        if (activeStep === 4) {
            async function initCheckout() {
                let r = null
                if (paymentType === 'card') {
                    r = await calculateTotals({
                        cart: currentCart,
                        payment_method_type: chosenPaymentMethod.method_type,
                    })
                }
                else if (paymentType === 'cash')  {
                    r = await calculateTotals({
                        cart: currentCart,
                        cash_payment: true
                    })
                }
                else if (paymentType === 'check') {
                    r = await calculateTotals({
                        cart: currentCart,
                        cash_payment: true
                    })
                }
                if (r.ok) {
                    setTotals(r.data)
                }
                else {
                    enqueueSnackbar('An error occurred', {variant: 'error'})
                }
                setCheckoutLoading(false)
            }
            
            initCheckout()
        }
    }, [activeStep])


    React.useEffect(() => {
        async function handleCustomerChange() {
            if (customerId) {
                const r = await getGuardian(currentSchool.value, customerId)
                setCustomerAccount(r.data)
            }
            else {
                setCustomerAccount({})
            }
        }
        handleCustomerChange()
    }, [customerId])


    React.useEffect(() => {

        let sT = currency(0)
        currentCart.map((item) => {
            sT = sT.add(item.cost)
        })
        setSubtotal(sT)

    }, [currentCart])


    async function handleCardSubmit(e) {
        e.preventDefault()

        setCardSubmitting(true)
        setCardSuccess(false)

        const card = elements.getElement(CardElement)

        const stripeR = await stripe.createToken(card)
        if (stripeR.error) {
            enqueueSnackbar(stripeR.error.message, { variant: 'error' })
            setCardSubmitting(false)
            return
        }
        
        const stripeCard = stripeR.token.card
        setChosenPaymentMethod({
            token: stripeR.token.id,
            method_type: 'card',
            card_brand: stripeCard.brand,
            last_four: stripeCard.last4,
            card_exp_month: stripeCard.exp_month,
            card_exp_year: stripeCard.exp_year,
        })
        setCardSuccess(true)
        setCardSubmitting(false)
        setActiveStep(4)
    }


    async function doListStoreItems(display_name_search_value) {
        setStoreItemsLoading(true)
        let filters = []
        filters.push({ field: 'category_type', op: 'eq', value: currentCategory })
        filters.push({ field: 'approval_status', op: 'eq', value: 'approved' })
        if (display_name_search_value !== '') {
            filters.push({ field: 'display_name', op: 'contains', value: display_name_search_value })
        }

        const params = make_filter_params(filters)

        const sI = await listStoreItems(currentSchool.value, params)
        setStoreItems(sI.data.results)
        setStoreItemsLoading(false)
    }


    React.useEffect(() => {
        if (!currentCategory) return

        if (currentCategory !== 'donations') {
            setStudentSelectionRequired(true)
        }

        if (activeStep !== 2) return
        
        doListStoreItems('')
    }, [currentCategory])


    function handleMoveToCheckout(e) {
        e.preventDefault()
        const form = document.getElementById('payment-form')
        if (!form.reportValidity()) {
            return
        }
        setActiveStep(4)
    }


    async function handleCheckout(e) {
        e.preventDefault()
        
        // if (anonUser) {
        //     const form = document.querySelector('form')
        //     if (!form.reportValidity()) {
        //         return
        //     }
        // }

        setSubmitting(true)

        let r = null;

        // if (anonUser) {
        //     r = await checkout(
        //         {
        //             cart: currentCart,
        //             payment_method: chosenPaymentMethod,
        //             user: {
        //                 first_name: formState.first_name,
        //                 last_name: formState.last_name,
        //                 email: formState.email,
        //                 create_account: false,
        //             }
        //         },
        //         {
        //             public: true
        //         }
        //     )
        // }

        if (paymentType === 'card') {
            r = await checkout(
                {
                    cart: currentCart,
                    payment_method: chosenPaymentMethod,
                    customer_account: customerAccount
                },
                {
                    on_site: true,
                    school_id: currentSchool.value,
                }
            )
        }

        else if (paymentType === 'cash') {
            r = await checkout(
                {
                    cart: currentCart,
                    customer_account: customerAccount
                },
                {
                    cash_payment: true,
                    on_site: true,
                    school_id: currentSchool.value
                }
            )
        }
        else if (paymentType === 'check') {
            r = await checkout(
                {
                    cart: currentCart,
                    customer_account: customerAccount
                },
                {
                    check_payment: true,
                    check_number: checkNumber,
                    on_site: true,
                    school_id: currentSchool.value
                }
            )
        }
        

        if (r.ok) {
            setCustomerOrder(r.data)
            setSuccess(true)
            removeAllFromCart()
            //setCustomerId(null)
            setSubmitting(false)
            enqueueSnackbar('Order Placed', {variant: 'success'})
            //setActiveStep(0)
            // if (anonUser) {
            //     history.push({
            //         pathname: '/cart/order',
            //         state: {
            //             anonUser: true,
            //             expandOrder: r.data.id,
            //             order: r.data
            //         }
            //     })
            // }
            // else {
            //     history.push({
            //         pathname: '/profile/transactions',
            //         state: { expandOrder: r.data.id }
            //     })
            // }
        }
        else {
            enqueueSnackbar(r.data.text, {variant: 'error'})
        }
        setSubmitting(false)
    }


    async function handleSearch(value) {
        doListStoreItems(value,)
    }

    const handleSearchDebounced = useConstant(() =>
        AwesomeDebouncePromise(
            handleSearch,
            400
        )
    )

    function handleRowClick(item) {
        setSelectedItem(item)
    }


    const steps = [
        'Customer Account',
        'Category',
        'Item',
        'Payment Info',
        'Checkout'
    ]

    function handleNext() {
        setActiveStep(activeStep + 1)
    }

    function handleBack() {
        setActiveStep(activeStep - 1)
    }

    function handleReset() {
        setActiveStep(0)
    }


    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <GuardiansPage
                        setGuardian={
                            (c) => {
                                setCustomerId(c.id)
                                setActiveStep(1)
                            }
                        }
                    />
                )
            
            case 1:
                return (
                    <>
                    <List>
                        {categoriesAvail.includes('donations') &&
                        <ListItem
                            button
                            onClick={() => {
                                setCurrentCategory('donations')
                                setActiveStep(2)
                            }
                            }
                        >
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faHandHoldingUsd} size='2x'/>
                            </ListItemIcon>
                            <ListItemText primary='Donations' />
                        </ListItem>
                        }
                        {categoriesAvail.includes('lunches') &&
                        <ListItem
                            button
                            onClick={() => {
                                setCurrentCategory('lunches')
                                setActiveStep(2)
                            }
                            }
                        >
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faUtensils} size='2x' />
                            </ListItemIcon>
                            <ListItemText primary='Lunches' />
                        </ListItem>
                        }
                        {categoriesAvail.includes('childcare') &&
                        <ListItem
                            button
                            onClick={() => {
                                setCurrentCategory('childcare')
                                setActiveStep(2)
                            }
                            }
                        >
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faBellSchool} size='2x' />
                            </ListItemIcon>
                            <ListItemText primary='Before & After School / Childcare' />
                        </ListItem>
                        }
                        {categoriesAvail.includes('fees') &&
                        <ListItem
                            button
                            onClick={() => {
                                setCurrentCategory('fees')
                                setActiveStep(2)
                            }
                            }
                        >
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faDollarSign} size='2x' />
                            </ListItemIcon>
                            <ListItemText primary='Fees' />
                        </ListItem>
                        }
                        {categoriesAvail.includes('activities') &&
                        <ListItem
                            button
                            onClick={() => {
                                setCurrentCategory('activities')
                                setActiveStep(2)
                            }
                            }
                        >
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faBusAlt} size='2x' />
                            </ListItemIcon>
                            <ListItemText primary='Activities & Field Trips' />
                        </ListItem>
                        }
                    </List>
                    </>
                )


            case 2:
                if (selectedItem === null) {
                    return (
                        <>
                            <Typography>
                                <Box fontSize={22}>
                                    {props.catPlural}
                                </Box>
                            </Typography>
                            <br />
                            <TextField label='Search' onChange={e => handleSearch(e.target.value)} />
                            <br /><br />
                            {storeItems.length === 0 ? 'No ' + currentCategory.toLowerCase() + ' found' :
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableBody>
                                            {storeItems.map(item => (
                                                <TableRow
                                                    hover
                                                    key={item.id}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleRowClick(item)}
                                                >
                                                    <TableCell component='th' scope='row'>
                                                        <ListItemText
                                                            primary={item.display_name}
                                                            secondary={item.school.display_name}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.cost}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </>
                    )
                }

                return (
                    <>
                    <ItemView
                        schoolId={currentSchool.value}
                        itemId={selectedItem.id}
                        addToCart={addToCart}
                        customer={customerAccount}
                    />
                    </>
                )

            case 3:
                return (
                    <Paper style={{padding: '2em'}}>
                        <Typography
                            style={{marginRight: 10, fontWeight: 'bold', fontSize: 18}}
                        >
                            Subtotal: {subtotal.format()}
                        </Typography>
                        <br />
                        <form id='payment-form'>
                        <FormControl required style={{minWidth: '10em'}}>
                            <InputLabel id='payment-type-label'>
                                Payment Type
                            </InputLabel>
                            <Select
                                labelId='payment-type-label'
                                id='payment-type'
                                value={paymentType}
                                onChange={(e) => setPaymentType(e.target.value)}
                                required
                                native
                            >
                                <option value='card'>Card</option>
                                <option value='cash'>Cash</option>
                                <option value='check'>Check</option>
                            </Select>
                        </FormControl>
                        </form>
                        <br />
                        {paymentType === 'card' &&
                            <form id='card-form' onSubmit={handleCardSubmit}>
                            <FormLabel>
                                Credit or debit card
                        </FormLabel>
                            <CardElement
                                id='card-element'
                                options={CARD_ELEMENT_OPTIONS}
                                onChange={null}
                            />
                            <br /><br />
                            <Box>
                                <img src={stripeLogo} />
                                <Typography>
                                    {'Card information is handled securly by '}
                                    <a href='https://stripe.com' target='_blank' rel='noopener'>Stripe</a>
                                </Typography>
                            </Box>
                            <br />
                            <SubmitButton
                                submitting={cardSubmitting}
                                success={cardSuccess}
                                buttonText={'Use this Card'}
                                onClick={handleCardSubmit}
                            />
                        </form>
                        }
                        {paymentType === 'check' &&
                            <TextField
                                id='checkNumber'
                                label='Check Number'
                                variant='outlined'
                                value={checkNumber}
                                onChange={(e) => setCheckNumber(e.target.value)}
                            />
                        }
                    </Paper>
                )

            case 4:
                if (checkoutLoading) { return <Preloader /> }

        return (
            <>
                <TableContainer component={Paper}>
                    <Table size='small'>
                        <TableBody>
                            {currentCart.map((item, i) => (
                                renderCartRow(item, i, false)
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br /><br />
                <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                >
                    <Grid item style={{width: '500px'}}>
                        {paymentType === 'card' &&
                        <>
                        <img src={stripeLogo} />
                        <Typography>
                            {'Your payment is processed securly by '}
                            <a href='https://stripe.com' target='_blank' rel='noopener'>Stripe</a>
                        </Typography>
                        <br /><br />
                        </>
                        }
                        <Typography component='span' style={{fontWeight: 'bold'}}>
                            Payment Method
                        </Typography>
                        <br/>
                        <Typography component='span' style={{ marginLeft: 'auto' }}>
                            {paymentType === 'card' &&
                                chosenPaymentMethod.card_brand.charAt(0).toUpperCase() + chosenPaymentMethod.card_brand.substring(1) + ' ending with ' + chosenPaymentMethod.last_four
                            }
                            {paymentType === 'cash' &&
                                'Cash'
                            }
                            {paymentType === 'check' &&
                                'Check' 
                            }
                            {paymentType ==='check' && checkNumber &&
                                ' number ' + checkNumber
                            }
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        direction='column'
                        justify='center'
                        alignItems='flex-end'
                        style={{ width: '250px', border: 'none' }}
                    >
                            <Grid item>
                                <Typography style={{fontWeight: 'bold', fontSize: 18}}>
                                    Order Summary
                                </Typography>
                            </Grid>
                            <Grid item container>
                                <Typography component='span'>
                                    Items:
                                </Typography>
                                <Typography component='span' style={{ marginLeft: 'auto' }}>
                                    {currentCart.length}
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item container>
                                <Typography component='span'>
                                    Subtotal:
                                </Typography>
                                <Typography component='span' style={{marginLeft: 'auto'}}>
                                    {currency(totals.subtotal).format()}
                                </Typography>
                            </Grid>
                            <Grid item container>
                                <Typography component='span'>
                                    {'Fees' + (currency(totals.fees_subtotal) > currency(0) ? '*' : '') + ':'}
                                </Typography>
                                <Typography component='span' style={{ marginLeft: 'auto' }}>
                                    {currency(totals.fees_subtotal).format()}
                                </Typography>
                            </Grid>
                            <Grid item container>
                                <Typography component='span' style={{fontWeight: 'bold'}}>
                                    Total:
                                </Typography>
                            <Typography component='span' style={{ marginLeft: 'auto', fontWeight: 'bold' }}>
                                    {currency(totals.total).format()}
                                </Typography>
                            </Grid>
                            {currency(totals.fees_subtotal) > currency(0) ?
                                <Grid item>
                                    <br />
                                    <Typography component='span'>
                                        *Fees are passed onto you from the school.
                                    </Typography>
                                </Grid>
                            :
                                null
                            } 
                    </Grid>
                </Grid>
                <br />
                <Grid
                    container
                    direction="column"
                    justify="flex-end"
                    alignItems="flex-end"
                    spacing={1}
                >
                    <form>
                    {/* {anonUser &&
                    <>
                        <Grid item>
                            <Typography>
                                Customer Information
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                id='first_name'
                                value={formState.first_name}
                                onChange={basicInputOnChange}
                                label='First Name'
                                variant='outlined'
                                required
                            />
                        </Grid>
                        <br/>
                        <Grid item>
                            <TextField
                                id='last_name'
                                value={formState.last_name}
                                onChange={basicInputOnChange}
                                label='Last Name'
                                variant='outlined'
                                required
                            />
                        </Grid>
                        <br/>
                        <Grid item>
                            <TextField
                                id='email'
                                value={formState.email}
                                onChange={basicInputOnChange}
                                label='Email'
                                variant='outlined'
                                required
                            />
                        </Grid>
                        <br/><br/>
                    </>
                    } */}
                    {!success &&
                    <Grid item>
                        <SubmitButton
                            submitting={submitting}
                            success={success}
                            buttonText={'Place your order'}
                            onClick={handleCheckout}
                        />
                    </Grid>
                    }
                    {success && customerOrder &&
                        <Grid item>
                            <a href={
                                api_base + '/users/' + customerAccount.id + '/customer_orders/' + customerOrder.id + '/receipt?school_id=' + currentSchool.value
                                   
                            }
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <Button variant='contained' color='secondary'>
                                    Print Receipt
                                </Button>
                            </a>
                        </Grid>
                    }
                    </form>

                </Grid>
            </>
        )  
        }
    }

    function addToCart(item) {
        const newCart = [...currentCart, item]
        setCurrentCart(newCart)
    }


    function removeFromCart(idx) {
        const newCart = currentCart.slice()
        newCart.splice(idx, 1)
        setCurrentCart(newCart)
    }
    
    function removeAllFromCart() {
        setCurrentCart([])
    }


    function renderCartRow(item, i, showDelete) {

        const itemCategoryDisplayName = {
            'donations': 'Donations',
            'lunches': 'Lunches',
            'childcare': 'Before & After School / Childcare',
            'fees': 'Fees',
            'activities': 'Activities & Field Trips',
        }

        const itemCategoryIcon = {
            'donations': faHandHoldingUsd,
            'lunches': faUtensils,
            'childcare': faBellSchool,
            'fees': faDollarSign,
            'activities': faBusAlt,
        }

        return (
            <TableRow
                hover
                key={i}
            >
                <TableCell component='th' scope='row'>
                    <ListItemText
                        primary={item.store_item.display_name}
                        secondary={item.store_item.school.display_name}
                    />
                </TableCell>
                <TableCell>
                    <Grid
                        container
                        alignItems='center'
                        spacing={1}
                    >
                        <Grid item>
                            <FontAwesomeIcon
                                icon={itemCategoryIcon[item.store_item.category_type]}
                            />
                        </Grid>
                        <Grid item>
                            <ListItemText
                                primary={itemCategoryDisplayName[item.store_item.category_type]}
                            />
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    {item.store_item.category_type === 'lunches' || item.store_item.category_type === 'fees' &&
                        <ListItemText
                            primary={item.student.first_name + ' ' + item.student.last_name}
                            secondary={item.lunch_date_cart_view}
                        />
                    }
                    {item.store_item.category_type === 'childcare' &&
                        <ListItemText
                            primary={item.childcare_students.map((s, i) => (
                                s.first_name + (i === item.childcare_students.length - 1 ? '' : ', ')
                            ))}
                        />
                    }
                </TableCell>
                <TableCell>
                    {'$' + item.cost}
                </TableCell>
                <TableCell>
                    {showDelete &&
                        <IconButton
                            onClick={() => removeFromCart(i)}
                            color='inherit'
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </IconButton>
                    }
                </TableCell>
            </TableRow>
        )
    }


    return (
        <Paper
            className={classes.pagePaper}
        >
            <Paper
                style={{padding: '1em'}}
            >
                <Typography>
                    Customer: {customerAccount.first_name} {customerAccount.last_name}
                </Typography>
            </Paper>
            <Paper
                style={{padding: '1em'}}
            >
                <Grid
                    container
                    direction='row'
                    //justify='center'
                    alignItems='center'
                    spacing={1}
                >
                    <Grid item>
                        <FontAwesomeIcon icon={faShoppingCart} size='2x'/>
                    </Grid>
                    <Grid item>
                        <Typography style={{ fontSize: 20 }}>
                            {currentCart.length} Item{(currentCart.length > 1 || currentCart.length === 0) && 's'} in Cart
                        </Typography>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableBody>
                            {currentCart.map((item, i) => (
                                renderCartRow(item, i, true)
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {}
                    const labelProps = {}
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            {getStepContent(activeStep)}
            <br/>
            <Grid
                container
                direction='row'
                justify='flex-end'
                alignItems='center'
                spacing={1}
            >
                <Grid item>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        setActiveStep(0)
                        setCurrentCategory(null)
                        setSelectedItem(null)
                        setCustomerId(null)
                        setStudentSelectionRequired(false)
                        setCurrentCart([])
                        setSuccess(false)
                    }}
                    disabled={activeStep===0}
                >
                    Start Over
                </Button>
                </Grid>
                {currentCart.length !== 0 && activeStep > 0 &&
                <Grid item>
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                        setActiveStep(1)
                        setSelectedItem(null)
                    }}
                >
                    Add Another Item
                </Button>
                </Grid>
                }
                {currentCart.length !== 0 && activeStep < 3 &&
                <Grid item>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        setActiveStep(3)
                        setSelectedItem(null)
                    }}
                >
                    Proceed to Payment
                </Button>
                </Grid>
                }
                {activeStep === 2 && !selectedItem &&
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                        setActiveStep(1)
                        setSelectedItem(null)
                    }}
                >
                    Back to Categories
                </Button>
                }
                {activeStep === 3 && (paymentType === 'cash' || paymentType === 'check') &&
                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleMoveToCheckout}
                >
                    Proceed to Checkout
                </Button>
                }
                {activeStep === 4 && !success &&
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                        setActiveStep(3)
                    }}
                >
                    Choose Different Payment Method
                </Button>
                }
            </Grid>
        </Paper>
    )
}