import React from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import { SchoolContext } from 'components/SchoolProvider.js'
import { UserContext } from 'components/UserProvider.js'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import DayPicker, { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { Box, Grid, IconButton, Switch, FormGroup, FormControl, FormControlLabel, RadioGroup, Radio, FormLabel, TextField, InputLabel, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import Select from 'react-select'
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ControlledInputInternals } from 'components/CustomInput/CustomInput';
import { listStoreGroups, getStoreGroup } from 'api/storegroups';
import { make_filter_params } from 'api/base';
import { useSnackbar } from 'notistack';
import { useHistory, Link } from 'react-router-dom';
import { make_params_from_react_table } from 'api/base';
import ReactTablePreloader from 'components/Preloader/ReactTablePreloader';
import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { SubmitButton } from 'components/CustomButtons/Button'
import { updateStoreGroup } from 'api/storegroups';
import moment from 'moment';
import { api_base } from 'api/base';
import { MaterialNumberFormat } from 'components/CustomInput/NumberFormat';
import NumberFormat from 'react-number-format';
import  {getSchoolLunchesSettings, updateSchoolLunchesSettings } from 'api/schoollunchessettings'
import Preloader from 'components/Preloader/Preloader';
import { getSchool } from 'api/school';
import { listStoreItems } from 'api/storeitems';
import { ContactSupportOutlined } from '@material-ui/icons';
import { getCookie, expireCookie, ReportPreloader } from 'components/Preloader/ReportPreloader'
import { getLunchesFRLSummaryReport } from 'api/reports';
import { param } from 'jquery';


const useStyles = makeStyles(styles);


export default function LunchesPage(){
    const classes = useStyles();
    const [currentSchool, setCurrentSchool] = React.useContext(SchoolContext)
    //const currentUser = React.useContext(UserContext)[0]
    const [loading, setLoading] = React.useState(true)
    const [tab, setTab] = React.useState(0)
    
    const { enqueueSnackbar } = useSnackbar();
    

    //console.log(currentUser)

    React.useEffect(() => {
        setLoading(false)
    }, [])


    function TabContainer(props) {
        return (
            <Typography component="div" style={{ padding: '0.5em' }} hidden={tab !== props.index}>
                {props.children}
            </Typography>
        );
    }


    function LunchesTab(props) {
        const [selectedDays, setSelectedDays] = React.useState([])
        const [vendor, setVendor] = React.useState('')
        const [vendorList, setVendorList] = React.useState([])
        const [submitting, setSubmitting] = React.useState(false)
        const [success, setSuccess] = React.useState(false)
        //const [rangeStart, setRangeStart] = React.useState(undefined)
        //const [rangeEnd, setRangeEnd] = React.useState(undefined)


        // Repeated Dates inputs
        const [rDFormState, rDDispatch, rDBasicInputOnChange, rDOnChange] = ControlledInputInternals()
        const { frequency, unit, dayOfWeek, week, rangeStart, rangeEnd } = rDFormState


        React.useEffect(() => {
            async function initializeVendors() {
                let filters = [{field: 'category_type', op: 'eq', value: 'lunches'}]
                let params = make_filter_params(filters)
                let r = await listStoreGroups(currentSchool.value, params, enqueueSnackbar)
                const vendors = r.data.results.map(v => {
                    let vs = {}
                    vs['value'] = v.id
                    vs['label'] = v.display_name
                    return vs
                })
                
                setVendorList(vendors)
            }
            initializeVendors()
        }, [currentSchool])


        React.useEffect(() => {
            async function initializeDates() {
                const r = await getStoreGroup(currentSchool.value, vendor)
                if (r.ok) {
                    if (r.data.lunches_dates !== null) {
                        let sD = []
                        r.data.lunches_dates.map(d => (
                            sD.push((new moment(d)).toDate())
                        ))
                        setSelectedDays(sD)
                    }
                }
                else {
                    window.location = '/admin'
                    enqueueSnackbar('There was an error fetching the vendor.', {variant: 'error'})
                }
            }
            if (vendor !== '') {
                initializeDates()
            }
        }, [vendor])


        async function handleAssignDates() {
            if (vendor === '') {
                enqueueSnackbar('Please select a vendor.', {variant: 'error'})
                return
            }
            setSubmitting(true)
            const r = await updateStoreGroup(
                currentSchool.value,
                vendor,
                { lunches_dates: selectedDays }
            )
            setSubmitting(false)
            if (r.ok) {
                setSuccess(true)
                enqueueSnackbar('Dates assigned to vendor.', {variant: 'success'})
            }
            else {
                setSuccess(false)
                enqueueSnackbar('An error occurred.', { variant: 'error' })
            }
        }

        
        function handleMultiDayClick(day, modifiers) {
            var days = selectedDays.slice()
            if (modifiers.disabled) {
                return
            }
            if (modifiers.selected) {
                const selectedIndex = days.findIndex(selectedDay =>
                    DateUtils.isSameDay(selectedDay, day)
                );
                days.splice(selectedIndex, 1);
            }
            else {
                days.push(day);
            }
            setSelectedDays(days)
        }

        function handleDayTrash(day) {
            var days = selectedDays.slice()
            const selectedIndex = days.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            )
            days.splice(selectedIndex, 1)
            setSelectedDays(days)
        }


        function nthDay(date, dayOfWeek, week) {
            // Assumes date is in the correct month
            // Returns 'skip': true if "5th week" is actually 1st week of next month

            let month = date.getMonth()

            // Go to the first of the month
            date.setDate(1)
            // Go to first dayOfWeek of the month
            date.setDate(date.getDate() + (dayOfWeek + 7 - date.getDay()) % 7)

            let weekCounter = 1
            while (weekCounter < week) {
                date.setDate(date.getDate() + 7)
                weekCounter++
            }

            if (date.getMonth() !== month) {
                // the "5th week" is actually the first week of the next month
                // roll back to the previous month
                date.setMonth(date.getMonth() - 1)
                return true
            }
            return false
        }


        function selectRepeatedDates() {
            let { frequency, unit, dayOfWeek, week, rangeStart, rangeEnd } = rDFormState
            frequency = parseInt(frequency)

            if (!unit || !frequency || dayOfWeek === undefined || !rangeStart || !rangeEnd) {
                return
            }

            var newDays = []

            var date = new Date(rangeStart)

            if (unit === 'week') {
                // Set date to the next dayOfWeek
                date.setDate(date.getDate() + (dayOfWeek + 7 - date.getDay()) % 7)

                while (true) {
                    if (date > rangeEnd) {
                        break
                    }

                    newDays.push(new Date(date))
                    date.setDate(date.getDate() + frequency * 7)
                }
            }
            else if (unit === 'month') {
                if ( !week ) {
                    return
                }

                let d = new Date(date)
                d.setDate(1)
                d.setDate(d.getDate() + (dayOfWeek + 7 - d.getDay()) % 7)
                
                // First dayOfWeek of rangeStart month is before rangeStart, so skip it and increment to next month by frequency
                if (d < rangeStart) {
                    var skip = false;
                    date.setMonth(date.getMonth() + frequency)
                    skip = nthDay(date, dayOfWeek, week)
                }

                while (true) {
                    if (date > rangeEnd) {
                        break
                    }
                    if (skip) {
                        // Skipping this month because there was no 5th dayOfWeek of the month
                        // Try next month by frequency
                        date.setMonth(date.getMonth() + frequency)
                        skip = nthDay(date, dayOfWeek, week)
                        continue
                    }

                    newDays.push(new Date(date))

                    date.setMonth(date.getMonth() + frequency)
                    skip = nthDay(date, dayOfWeek, week)
                }
            }
            let currentlySelectedDays = selectedDays.slice()
            let newSelectedDays = [...currentlySelectedDays, ...newDays]
            setSelectedDays(newSelectedDays)
        }


        return (
            <>
                <Paper className={classes.pageSubPaper}>
                    <Box fontSize={20}>
                        Choose Vendor
                    </Box>
                    <br />
                    <Grid container spacing={3}>
                        <Grid item>
                            <div style={{ minWidth: '300px' }}>
                                <Select
                                    id='vendor'
                                    placeholder='Vendor'
                                    onChange={(sel) => setVendor(sel.value)}
                                    options={vendorList}
                                    isClearable={true}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
                <br />
                <Paper className={classes.pageSubPaper}>
                    <Box fontSize={20}>
                        Choose Days
                    </Box>
                    <br />
                    <Grid container spacing={3}>
                        <Grid item>
                            <Box fontSize={20}>
                                Individual Dates
                            </Box>
                            <hr />
                            <DayPicker
                                selectedDays={selectedDays}
                                onDayClick={handleMultiDayClick}
                                disabledDays={
                                    {
                                        before: new Date()
                                    }
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Grid item>
                                <Box fontSize={20}>
                                    Repeated dates
                                </Box>
                                <hr />
                                <Box fontSize={18}>
                                    Frequency
                                </Box>
                                <Grid container spacing={3}>
                                <Grid item>
                                    <div style={{minWidth: '125px'}}>
                                        <Select
                                            id='frequency'
                                            placeholder='Every...'
                                            onChange={(sel) => rDOnChange('frequency', sel.value)}
                                            options={
                                                [
                                                    { value: '1', label: 'Every one' },
                                                    { value: '2', label: 'Every two' },
                                                    { value: '3', label: 'Every three' },
                                                    { value: '4', label: 'Every four' },
                                                ]
                                            }
                                        />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div style={{minWidth: '125px'}}>
                                        <Select
                                            id='unit'
                                            placeholder='Unit...'
                                                onChange={(sel) => rDOnChange('unit', sel.value)}
                                            options={
                                                [
                                                    { value: 'week', label: 'Weeks' },
                                                    { value: 'month', label: 'Months' },
                                                ]
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            </Grid>
                            <br />
                            <Grid item style={{display: (unit === 'week') ? '' : 'none'}}>
                                <Box fontSize={18}>
                                    Day of the week
                                </Box>
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <div style={{minWidth: '200px'}}>
                                            <Select
                                                id='dayOfWeek'
                                                placeholder='Day of the week...'
                                                onChange={(sel) => rDOnChange('dayOfWeek', sel.value)}
                                                options={
                                                    [
                                                        { value: 0, label: 'Sunday' },
                                                        { value: 1, label: 'Monday' },
                                                        { value: 2, label: 'Tuesday' },
                                                        { value: 3, label: 'Wednesday' },
                                                        { value: 4, label: 'Thursday' },
                                                        { value: 5, label: 'Friday' },
                                                        { value: 6, label: 'Saturday' }
                                                    ]
                                                }
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{display: (unit === 'month') ? '' : 'none'}}>
                                <Box fontSize={18}>
                                    Day of the month
                                </Box>
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <div style={{minWidth: '150px'}}>
                                            <Select
                                                id='week'
                                                placeholder='Week...'
                                                onChange={(sel) => rDOnChange('week', sel.value)}
                                                options={
                                                    [
                                                        { value: 1, label: 'First' },
                                                        { value: 2, label: 'Second' },
                                                        { value: 3, label: 'Third' },
                                                        { value: 4, label: 'Fourth' },
                                                        { value: 5, label: 'Fifth' },
                                                    ]
                                                }
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div style={{ minWidth: '200px' }}>
                                            <Select
                                                id='dayOfWeek'
                                                placeholder='Day of the week...'
                                                onChange={(sel) => rDOnChange('dayOfWeek', sel.value)}
                                                options={
                                                    [
                                                        { value: 0, label: 'Sunday' },
                                                        { value: 1, label: 'Monday' },
                                                        { value: 2, label: 'Tuesday' },
                                                        { value: 3, label: 'Wednesday' },
                                                        { value: 4, label: 'Thursday' },
                                                        { value: 5, label: 'Friday' },
                                                        { value: 6, label: 'Saturday' }
                                                    ]
                                                }
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid item>
                                <Box fontSize={18}>
                                    Repeat duration
                                </Box>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <DayPickerInput
                                            formatDate={formatDate}
                                            parseDate={parseDate}
                                            placeholder='mm/dd/yyyy'
                                            onDayChange={(day) => rDOnChange('rangeStart', day)}
                                            dayPickerProps={{
                                                selectedDays: rangeStart,
                                                disabledDays: {
                                                    before: new Date()
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                        <FontAwesomeIcon
                                            icon={faLongArrowAltRight}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <DayPickerInput
                                            formatDate={formatDate}
                                            parseDate={parseDate}
                                            placeholder='mm/dd/yyyy'
                                            onDayChange={(day) => rDOnChange('rangeEnd', day)}
                                            dayPickerProps={{
                                                selectedDays: rangeEnd,
                                                disabledDays: {
                                                    before: new Date()
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <br/>
                            <Grid
                                container
                                direction='row'
                                justify='flex-end'
                                alignItems='center'
                            >
                                <Button
                                    variant='contained'
                                    onClick={selectRepeatedDates}
                                >
                                    Select Repeated Dates
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>  
                </Paper>
                <br />
                <Paper className={classes.pageSubPaper}>
                    <Box fontSize={20}>
                        Selected Days
                    </Box>
                    <br/>
                    <div style={{overflowY: 'auto', maxHeight: '300px'}}>
                        {selectedDays.map(day => (
                            <Box m={1}>
                                <Grid
                                    container
                                    key={day.getTime()}
                                >
                                    <Grid item>
                                        <IconButton
                                            onClick={() => handleDayTrash(day)}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                        {day.toLocaleDateString(
                                            undefined,
                                            { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}
                    </div>
                    <br />
                    <Grid
                        container
                        direction='row'
                        justify='flex-end'
                        alignItems='center'
                    >
                        <Button
                            variant='contained'
                            onClick={() => setSelectedDays([])}
                        >
                            Clear All Dates
                        </Button>
                    </Grid>
                </Paper>
                <br/>
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    <SubmitButton
                        submitting={submitting}
                        success={success}
                        buttonText={'Assign Dates'}
                        onClick={handleAssignDates}
                    />
                </Grid>
            </>
            
        )
    }


    function VendorsTab(props) {
        const [vendorList, setVendorList] = React.useState([])
        const [loading, setLoading] = React.useState(true)
        const [pages, setPages] = React.useState(0)
        const reactTable = React.createRef()
        let history = useHistory()


        React.useEffect(() => {
            async function initializeVendors() {
                let params = make_params_from_react_table(reactTable.current.state)
                const filters = [{ field: 'category_type', op: 'eq', value: 'lunches' }]
                params = { ...params, ...make_filter_params(filters) }
                const r = await listStoreGroups(currentSchool.value, params, enqueueSnackbar)
                setVendorList(r.data.results)
                setPages(r.data.pages)
                setLoading(false)
            }
            initializeVendors()
        }, [currentSchool])


        const listStoreGroupsDebounced = useConstant(() =>
            AwesomeDebouncePromise(
                listStoreGroups,
                400
            )
        )


        async function handleOnFetchData(state, instance) {
            setLoading(true)
            let params = make_params_from_react_table(state)
            const filters = [{ field: 'category_type', op: 'eq', value: 'lunches' }]
            params = { ...params, ...make_filter_params(filters) }
            const r = await listStoreGroupsDebounced(currentSchool.value, params, enqueueSnackbar)
            setVendorList(r.data.results)
            setPages(r.data.pages)
            setLoading(false)
        }


        function rowHandlers(state, rowInfo, column, instance) {
            return {
                onClick: e => {
                    history.push('/admin/lunches/edit_vendor/' + rowInfo.original.id)
                },
                style: {
                    cursor: 'pointer'
                }
            }
        }


        const colunns = [
            {
                Header: 'Name',
                accessor: 'display_name'
            }
        ]



        return (
            <>
                <Link to='/admin/lunches/new_vendor'>
                    <Button
                        variant='contained'
                        color='primary'
                    >
                        Create New Vendor
                    </Button>
                </Link>
                <br /><br />
                <ReactTable
                    ref={reactTable}
                    data={vendorList}
                    columns={colunns}
                    pages={pages}
                    defaultPageSize={10}
                    filterable={true}
                    loading={loading}
                    onFetchData={handleOnFetchData}
                    LoadingComponent={ReactTablePreloader}
                    getTrProps={rowHandlers}
                    className='-highlight'
                    manual
                />
            </>
        )
    }


    function ReportsTab(props) {
        const [selectedDays, setSelectedDays] = React.useState([])
        const [vendorList, setVendorList] = React.useState([])
        const [vendor, setVendor] = React.useState('')
        const [minGrade, setMinGrade] = React.useState(null)
        const [maxGrade, setMaxGrade] = React.useState(null)
        const [dialogOpen, setDialogOpen] = React.useState(false)
        const [generalReportHref, setGeneralReportHref] = React.useState(
            api_base + '/schools/' + currentSchool.value + '/reports/categories/lunches'
        )
        const [detailReportHref, setDetailReportHref] = React.useState(
            api_base + '/schools/' + currentSchool.value + '/reports/categories/lunches/detail'
        )
        const [startDate, setStartDate] = React.useState(null)
        const [endDate, setEndDate] = React.useState(null)

        // let dT = new Date().getTime()  // Download token
        // console.log('init dT', dT)
        const [downloadToken, setDownloadToken] = React.useState(new Date().getTime().toString())
        const [generalReportParams, setGeneralReportParams] = React.useState(new URLSearchParams())
        const [jsonReportLoading, setJsonReportLoading] = React.useState(false)
        const [jsonReport, setJsonReport] = React.useState(null)
        
        function handleReportClick() {
            setDialogOpen(true)
            const downloadTimer = window.setInterval(() => {
                const cookieToken = getCookie('downloadToken')
                if (cookieToken === downloadToken) {
                    window.clearInterval(downloadTimer)
                    expireCookie('downloadToken')
                    setDialogOpen(false)
                    setDownloadToken(new Date().getTime().toString())
                }
            }, 500)
        }


        async function handleFRLReportClick() {
            if (!vendor || !startDate || !endDate) {
                enqueueSnackbar('Please choose a date range and a vendor.', {variant: 'error'})
                return
            }
            setJsonReportLoading(true)
            const params = {
                vendor_id: vendor,
                start_date: startDate,
                end_date: endDate
            }
            const r = await getLunchesFRLSummaryReport(currentSchool.value, params)
            setJsonReportLoading(false)
            if (r.ok) {
                setJsonReport(r.data)
            }
            console.log(r)
        }


        React.useEffect(() => {
            async function initializeVendors() {
                let filters = [{field: 'category_type', op: 'eq', value: 'lunches'}]
                let params = make_filter_params(filters)
                let r = await listStoreGroups(currentSchool.value, params, enqueueSnackbar)
                const vendors = r.data.results.map(v => {
                    let vs = {}
                    vs['value'] = v.id
                    vs['label'] = v.display_name
                    return vs
                })
                
                setVendorList(vendors)
            }
            initializeVendors()
        }, [currentSchool])


        React.useEffect(() => {
            let params = new URLSearchParams(generalReportParams)

            params.set('downloadToken', downloadToken)

            if (vendor) {
                params.set('vendor_id', vendor)
            }
            else {
                params.delete('vendor_id')
            }

            if (minGrade) {
                params.set('minGrade', minGrade)
            }
            else {
                params.delete('minGrade')
            }

            if (maxGrade) {
                params.set('maxGrade', maxGrade)
            }
            else {
                params.delete('maxGrade')
            }

            setGeneralReportParams(params)
        }, [downloadToken, vendor, minGrade, maxGrade])


        React.useEffect(() => {
            let href = new URL('/schools/' + currentSchool.value + '/reports/categories/lunches', api_base)
            href.search = generalReportParams.toString()
            setGeneralReportHref(href.toString())
        }, [generalReportParams])


        React.useEffect(() => {
            let href = new URL('/schools/' + currentSchool.value + '/reports/categories/lunches/detail', api_base)
            href.search = generalReportParams.toString()
            setDetailReportHref(href.toString())
        }, [generalReportParams])


        // React.useEffect(() => {
        //     let params = new URLSearchParams(generalReportParams)
        //     if (vendor) {
        //         params.set('vendor_id', vendor)
        //     }
        //     else {
        //         params.delete('vendor_id')
        //     }
        //     setGeneralReportParams(params)         
        // }, [vendor])


        // React.useEffect(() => {
        //     let params = new URLSearchParams(generalReportParams)
        //     if (minGrade) {
        //         params.set('minGrade', minGrade)
        //     }
        //     else {
        //         params.delete('minGrade')
        //     }
        //     setGeneralReportParams(params)
        // }, [minGrade])


        // React.useEffect(() => {
        //     let params = new URLSearchParams(generalReportParams)
        //     if (maxGrade) {
        //         params.set('maxGrade', maxGrade)
        //     }
        //     else {
        //         params.delete('maxGrade')
        //     }
        //     setGeneralReportParams(params)
        // }, [maxGrade])


        function handleMultiDayClick(day, modifiers) {
            var days = selectedDays.slice()
            if (modifiers.disabled) {
                return
            }
            if (modifiers.selected) {
                const selectedIndex = days.findIndex(selectedDay =>
                    DateUtils.isSameDay(selectedDay, day)
                );
                days.splice(selectedIndex, 1);
            }
            else {
                days.push(day);
            }
            setSelectedDays(days)

            let href = new URL('/schools/' + currentSchool.value + '/reports/categories/lunches', api_base)
            let params = new URLSearchParams()

            days.map((d) => {
                params.append('date', d.toISOString())
            })

            for (const [k, v] of generalReportParams.entries()) {
                if (k === 'date') {
                    continue
                }
                params.append(k, v)
            }

            setGeneralReportParams(params)

            // href.search = params.toString();
            // setGeneralReportHref(href.toString())
        }


        function handleVendorSelect(sel) {
            if (sel) {
                setVendor(sel.value)
            }
            else {
                setVendor('')
            }
        }


        function handleMinGradeSelect(sel) {
            if (sel) {
                setMinGrade(sel.value)
            }
            else {
                setMinGrade(null)
            }
        }

        
        function handleMaxGradeSelect(sel) {
            if (sel) {
                setMaxGrade(sel.value)
            }
            else {
                setMaxGrade(null)
            }
        }


        const gradeOptions = []
        for (let i = 0; i < 13; i++) {
            gradeOptions.push({
                value: i,
                label: i
            })
        }


        return (
            <>
                <Paper className={classes.pageSubPaper}>
                    <Typography style={{fontSize: 20}}>
                        Daily Reports
                    </Typography>
                    <Typography>
                        Tip: To get menu item totals, select a vendor and a single day
                    </Typography>
                    <br />
                    <Typography>
                        Choose Date(s) (at least one required)
                    </Typography>
                    <DayPicker
                        selectedDays={selectedDays}
                        onDayClick={handleMultiDayClick}
                    />
                    <Typography>
                        Choose Vendor (optional)
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item>
                            <div style={{ minWidth: '300px' }}>
                                <Select
                                    id='vendor'
                                    placeholder='Vendor'
                                    onChange={handleVendorSelect}
                                    options={vendorList}
                                    isClearable={true}
                                    value={vendorList.filter(function (option) {
                                        return option.value === vendor;
                                    })}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <br />
                    <Typography>
                        Choose Grade Levels (optional)
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item>
                            <div style={{ minWidth: '200px' }}>
                                <Select
                                    id='minGrade'
                                    placeholder='Min Grade'
                                    onChange={handleMinGradeSelect}
                                    options={gradeOptions}
                                    isClearable={true}
                                />
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ minWidth: '200px' }}>
                                <Select
                                    id='maxGrade'
                                    placeholder='Max Grade'
                                    onChange={handleMaxGradeSelect}
                                    options={gradeOptions}
                                    isClearable={true}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item>
                        <a
                            href={generalReportHref}
                            download
                            onClick={handleReportClick}
                        >
                            <Button variant='contained' color='secondary' onClick={handleReportClick}>
                                Export CSV: Report
                            </Button>
                        </a>
                        </Grid>
                        <Grid>
                        <a
                            href={detailReportHref}
                            download
                            onClick={handleReportClick}
                        >
                            <Button variant='contained' color='secondary' onClick={handleReportClick}>
                                Export XLSX: Detail Report
                            </Button>
                        </a>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.pageSubPaper}>
                    <Typography style={{ fontSize: 20 }}>
                        Summary Reports
                    </Typography>
                    <Typography>
                        Choose Date range
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item>
                            <DayPickerInput
                                formatDate={formatDate}
                                parseDate={parseDate}
                                placeholder='mm/dd/yyyy'
                                onDayChange={(day) => setStartDate(day.toISOString())}
                                dayPickerProps={{
                                    selectedDays: startDate,
                                }}
                            />
                        </Grid>
                        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                            <FontAwesomeIcon
                                icon={faLongArrowAltRight}
                            />
                        </Grid>
                        <Grid item>
                            <DayPickerInput
                                formatDate={formatDate}
                                parseDate={parseDate}
                                placeholder='mm/dd/yyyy'
                                onDayChange={(day) => setEndDate(day.toISOString())}
                                dayPickerProps={{
                                    selectedDays: endDate,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <br/>
                    <div style={{ minWidth: '300px' }}>
                        <Select
                            id='vendor'
                            placeholder='Vendor'
                            onChange={handleVendorSelect}
                            options={vendorList}
                            isClearable={true}
                            value={vendorList.filter(function (option) {
                                return option.value === vendor;
                            })}
                        />
                    </div>
                    <br/>
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item>
                            <Button variant='contained' color='secondary' onClick={handleFRLReportClick}>
                                Show FRL Report
                            </Button>
                        </Grid>
                    </Grid>
                    <br/>
                    {jsonReportLoading && <Preloader/>}
                    {jsonReport &&
                    <>
                        <Typography>
                            Total Items Sold: {jsonReport.total_items}
                        </Typography>
                        <Typography>
                            Revenue: ${jsonReport.revenue}
                        </Typography>
                        <br />
                        <Typography>
                            Free/Reduced Items Sold:
                        </Typography>
                        {Object.entries(jsonReport.frl_count).map((e) => (
                            <>
                                <Typography>
                                    {e[0]}
                                </Typography>
                                <Typography>
                                    Free: {e[1].free}
                                </Typography>
                                <Typography>
                                    Reduced: {e[1].reduced}
                                </Typography>
                                <br />
                            </>
                        ))}
                    </>
                    }
                </Paper>
                <ReportPreloader
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                />
            </>
        )
    }


    function SettingsTab(props) {
        const [formState, dispatch, basicInputOnChange, onChange] = ControlledInputInternals();
        const [submitting, setSubmitting] = React.useState(false)
        const [success, setSuccess] = React.useState(false)
        const [loading, setLoading] = React.useState(true)
        const [donations, setDonations] = React.useState([])
        const [donation, setDonation] = React.useState({})


        React.useEffect(() => {
            async function init() {
                if (tab !== 3) {
                    return
                }

                const params = make_filter_params(
                    [{ field: 'category_type', op: 'eq', value: 'donations' }]
                )
                const r1 = await listStoreItems(currentSchool.value, params)
                let donations = r1.data.results.map(d => {
                    let vs = {}
                    vs.value = d.id
                    vs.label = d.display_name
                    return vs
                })
                setDonations(donations)

                const r = await getSchoolLunchesSettings(currentSchool.value)
                // No settings set
                if (r.status === 404) {
                    setLoading(false)
                    return
                }
                for (const [key, val] of Object.entries(r.data)) {
                    if (key === 'id' || key === 'donation') { continue }
                    dispatch({field: key, value: val})
                    console.log(key, val)
                }
                setDonation(donations.filter(d => d.value === r.data.donation_id))                
                setLoading(false)
            }
            init()
        }, [])


        async function handleUpdateSettings(e) {
            e.preventDefault()
            let form = document.querySelector('form')
            if (!form.reportValidity()) {
                return
            }
            setSubmitting(true)
            setSuccess(false)

            const r = await updateSchoolLunchesSettings(
                currentSchool.value,
                formState
            )
            setSubmitting(false)
            
            if (!r.ok) {
                enqueueSnackbar('An error occurred', { variant: 'error' })
                return
            }

            enqueueSnackbar('Settings updated', {variant: 'success'})
            setSuccess(true)

            const r1 = await getSchool(currentSchool.value)
            setCurrentSchool(r1.data)

        }

        if (loading) { return <Preloader />}
        
        return (
            <Paper className={classes.pageSubPaper}>
                    <Typography style={{fontSize: 24}}>
                        Settings
                    </Typography>
                    <br />
                    <form>
                    <Typography style={{fontSize: 20}}>
                        National School Lunch Program
                    </Typography>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formState.nslp_enabled}
                                        onChange={basicInputOnChange}
                                        id={'nslp_enabled'}
                                    />
                                }
                                label='NSLP Enabled'
                            />
                        </FormGroup>
                        <div style={{display: formState.nslp_enabled ? '' : 'none'}}>
                        <br/>
                        <FormControl component='fieldset'>
                            <FormLabel component='legend'>
                                Item Eligibility
                            </FormLabel>
                            <RadioGroup
                                value={formState.item_eligibility}
                                name='item_eligibility'
                                onChange={(e) => onChange('item_eligibility', e.target.value)}
                            >
                                <FormControlLabel
                                    value='any'
                                    control={<Radio required={formState.nslp_enabled}/>}
                                    label='Any item can be purchased at a free or reduced rate'
                                />
                                <FormControlLabel
                                    value='specific'
                                    control={<Radio required={formState.nslp_enabled}/>}
                                    label='Only specific items can be purchased at a free or reduced rate'
                                />
                            </RadioGroup>
                        </FormControl>
                        <br /><br />
                        <FormControl component='fieldset'>
                            <FormLabel component='legend'>
                                Reduced Rate Setting
                            </FormLabel>
                            <RadioGroup
                                value={formState.reduced_setting}
                                name='reduced_setting'
                                onChange={(e) => onChange('reduced_setting', e.target.value)}
                            >
                                <FormControlLabel
                                    value='flat_rate'
                                    control={<Radio required={formState.nslp_enabled}/>}
                                    label='All reduced students purchase at the same flat rate'
                                />
                                <FormControlLabel
                                    value='percentage'
                                    control={<Radio required={formState.nslp_enabled}/>}
                                    label='All reduced students purchase at the same percentage discount'
                                />
                                <FormControlLabel
                                    value='flat_rate_per_student'
                                    control={<Radio required={formState.nslp_enabled}/>}
                                    label='Reduced students purchase at a flat rate that it specific to each student'
                                />
                                <FormControlLabel
                                    value='percentage_per_student'
                                    control={<Radio required={formState.nslp_enabled}/>}
                                    label='Reduced students purchase at a percentage discount that is specific to each student'
                                />
                            </RadioGroup>
                        </FormControl>
                        <br />
                        <MaterialNumberFormat
                            id='flat_rate_all'
                            label='Flat rate for all students'
                            variant='outlined'
                            value={formState.flat_rate_all}
                            onChange={basicInputOnChange}
                            margin='normal'
                            required={formState.reduced_setting === 'flat_rate'}
                            style={{display: formState.reduced_setting === 'flat_rate'? '' : 'none'}}
                        />
                        <TextField
                            id='percentage_all'
                            label='Percent discount for all students'
                            variant='outlined'
                            value={formState.percentage_all}
                            onChange={basicInputOnChange}
                            margin='normal'
                            required={formState.reduced_setting === 'percentage'}
                            style={{display: formState.reduced_setting === 'percentage'? '' : 'none'}}
                            InputProps={{
                                inputComponent: NumberFormat
                            }}
                        />
                        </div>
                        <br />
                    <Typography style={{ fontSize: 20 }}>
                        Lunch Fund Donations
                    </Typography>
                    <Select
                        id='donation-id'
                        placeholder='None'
                        onChange={(sel) => {
                            if (sel) {
                                setDonation(donations.filter(d => d.value === sel.value))
                                onChange('donation_id', sel.value)
                            }
                            else {
                                setDonation(null)
                                onChange('donation_id', null)
                            }
                        }}
                        options={donations}
                        isClearable={true}
                        value={donation}
                    />
                        <Grid
                            container
                            direction='row'
                            justify='flex-end'
                            alignItems='center'
                        >
                            <SubmitButton
                                submitting={submitting}
                                success={success}
                                buttonText='Update Settings'
                                onClick={handleUpdateSettings}
                            />
                        </Grid>
                        
                    </form>
            </Paper>
        )

    }


    if (loading) {
        return (null)
    }
    else {
        return (
            <Paper
                className={classes.pagePaper}
            >
                <AppBar position='static'>
                    <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
                        <Tab label='Schedule' />
                        <Tab label='Vendors' />
                        <Tab label='Reports' />
                        <Tab label='Settings' />
                    </Tabs>
                </AppBar>
                <TabContainer value={tab} index={0}>
                    <LunchesTab />
                </TabContainer>
                <TabContainer value={tab} index={1}>
                    <VendorsTab />
                </TabContainer>
                <TabContainer value={tab} index={2}>
                    <ReportsTab />
                </TabContainer>
                <TabContainer value={tab} index={3}>
                    <SettingsTab />
                </TabContainer>
            </Paper>
        )
    }
}