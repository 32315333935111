import React from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import { SchoolContext } from 'components/SchoolProvider.js'
import { UserContext } from 'components/UserProvider.js'
import { getStoreItem, createStoreItem, updateStoreItem, approveStoreItem } from 'api/storeitems'
import { make_filter_params, make_params_from_react_table } from 'api/base.js'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant'
import { useHistory, Link } from "react-router-dom";
import Preloader from 'components/Preloader/Preloader.js'
import { useSnackbar } from 'notistack';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty'
import { SubmitButton } from 'components/CustomButtons/Button.js'
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js'
import { FormGroup, TextField, FormControl, InputLabel, Input, Box } from '@material-ui/core';
import tinymce from 'tinymce/tinymce'
//import 'tinymce/themes/silver'
//import 'tinymce/plugins/paste';
//import 'tinymce/plugins/link';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import ReactTablePreloader from 'components/Preloader/ReactTablePreloader';
import { listStudents } from 'api/students.js'
import remove from 'lodash/remove'
import {MaterialNumberFormat} from 'components/CustomInput/NumberFormat.js'
import TinyMCE from 'components/CustomInput/TinyMCE.js'
import StudentSelection from 'components/StudentSelection/StudentSelection'
import StoreItemApproverSelect from 'components/CustomInput/StoreItemApproverSelect'
import UserFormSelect from 'components/CustomInput/UserFormSelect'
import StaffSelect from 'components/CustomInput/StaffSelect'
import { getStaff } from 'api/staff';
import * as moment from 'moment'
import some from 'lodash/some';
import StoreItemPayoutMethodSelect from 'components/CustomInput/StoreItemPayoutMethodSelect';


const useStyles = makeStyles(styles);


export default function EditFee(props) {
    const classes = useStyles();
    const currentSchool = React.useContext(SchoolContext)[0]
    const currentUser = React.useContext(UserContext)[0]
    const [loading, setLoading] = React.useState(true);
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [editor, setEditor] = React.useState(null);
    const [selectedStudents, setSelectedStudents] = React.useState([])
    const [focusedInput, setFocusedInput] = React.useState(null);
    const [staffCanApprove, setStaffCanApprove] = React.useState(false)
    const [fee, setFee] = React.useState({})
    const reactTable = React.createRef();
    let history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    // Determine if we are creating or editing
    var editing = false;
    var feeId = '';
    if (props.location.pathname.split('/')[4] === 'edit') {
        editing = true;
        feeId = props.location.pathname.split('/')[3];
    }

    const [formState, dispatch, basicInputOnChange, onChange] = ControlledInputInternals();

    // Inputs
    const inputs = ['display_name', 'description', 'open_time', 'close_time',
        'cost', 'approver_id', 'form_id', 'owner_id', 'category_type', 'open_signup', 'payout_method_id']

    const { display_name, description, open_time, close_time,
        cost, approver_id, form_id, owner_id, category_type, open_signup, payout_method_id } = formState;


    // // Initialize TinyMCE
    // tinymce.init({
    //     selector: '#description',
    //     plugins: ['paste', 'link'],
    //     skin_url: '/tinymce-skins/ui/oxide',
    //     width: '100%',
    //     setup: (editor) => {
    //         editor.on('change', () => { props.onChange(description, editor.getContent()) })
    //     }
    // })

    // Initialize page
    React.useEffect(() => {
        async function initializePage(schoolId, feeId) {
            if (editing) {
                let r = await getStoreItem(schoolId, feeId);
                if (r.ok) {
                    setFee(r.data)
                    // Set initial form values using dispatch function from useReducer in ControlledInputInternals
                    inputs.map(key=> {
                        // Cast date string to moment for datepicker
                        if (['open_time', 'close_time'].includes(key)) {
                            r.data[key] = (r.data[key] !== null ? moment(r.data[key]) : null)
                        }
                        dispatch({field: key, value: r.data[key]})
                    })
                    setSelectedStudents(r.data.students)
                    setLoading(false)
                }
                else {
                    window.location = '/admin'
                }
            }
            else {
                setFee({students: []})
                setLoading(false)
            }
        }
        initializePage(currentSchool.value, feeId)
    }, []) // Never refire


    // Check approval permissions for current user
    React.useEffect(() => {
        getStaff(currentSchool.value, currentUser.id)
            .then(r => {
                // Handle case where user is not staff for currentSchool
                const pem = (isEmpty(r.data))
                    ? false : r.data.school_staff_grants_mapping.approve_store_item_requests
                setStaffCanApprove(pem)
            })
    }, [currentSchool, currentUser])


    async function handleApproveFee() {
        setSubmitting(true)
        const r = await approveStoreItem(
            currentSchool.value,
            feeId
        )
        if (!r.ok) {
            enqueueSnackbar('An error occurred', { variant: 'error' })
            setSubmitting(false)
            return
        }
        setFee(r.data)
        setSubmitting(false)
        enqueueSnackbar('Fee Approved', {variant: 'success'})
    }


    async function handleUpdateFee(e) {
        e.preventDefault();
        let form = document.querySelector('form')
        if (!form.reportValidity()) {
            return
        }
        setSubmitting(true)
        setSuccess(false)

        let currentStudents = fee.students
        let newStudents = {add: [], rm: []}

        selectedStudents.map(s => {
            let sIn = some(currentStudents, {id: s.id})
            // Add Student
            if (!sIn) {
                newStudents.add.push(s.id)
            }
        })

        currentStudents.map(s => {
            let sIn = some(selectedStudents, {id: s.id})
            // Remove student
            if (!sIn) {
                newStudents.rm.push(s.id)
            }
        })

        var r = null
        var snackbarText = ''
        if (editing) {
            r = await updateStoreItem(
                currentSchool.value,feeId,
                {store_item: formState, students: newStudents}
                )
            snackbarText = 'updated'
        }
        else {
            // Set storeitem category_type
            // Setting category_id is handled on server
            r = await createStoreItem(
                currentSchool.value,
                {store_item: {...formState, ...{category_type: 'fees'}}, students: newStudents}
                )
            snackbarText = 'created'
        }
        setSubmitting(false);
        if (r.ok) {
            enqueueSnackbar('Fee ' + snackbarText, {variant: 'success'})
            setFee(r.data)
            setSuccess(true)
            if (!editing) {
                history.push('/admin/fees/' + r.data.id + '/edit')
            }
        }
        else {
            enqueueSnackbar('An error occurred', { variant: 'error' })
        }

    }


    if (loading) {
        return (null)
    }
    else {
        return (
            <Paper
                className={classes.pagePaper}
            >
                <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                >
                    {(fee.approval_status === 'pending' && (staffCanApprove || currentUser.super_admin_grants.length === 1)) &&
                        <SubmitButton
                            submitting={submitting}
                            success={null}
                            buttonText={'Approve Fee'}
                            onClick={handleApproveFee}
                            noSubmitType={true}
                            color='default'
                        />
                    }
                    <Link to={'/admin/fees/' + feeId + '/report_by_student'}>
                        <Button variant='contained' color='secondary'>
                            Reports
                        </Button>
                    </Link>
                </Grid>
                <form>
                    <Paper
                        className={classes.pageSubPaper}
                    >
                        <Typography>
                            <Box fontSize={20}>
                                Basic Information
                            </Box>
                        </Typography>
                        <TextField
                            id='display_name'
                            label='Name'
                            variant='outlined'
                            value={display_name}
                            onChange={basicInputOnChange}
                            margin='normal'
                            required
                        />
                        <br /><br />
                        { !(currentUser.super_admin_grants.length === 1) ? null : (
                            <>
                                <Typography>Select a staff member to own this fee</Typography>
                                <StaffSelect
                                    id='owner_id'
                                    value={owner_id}
                                    onChange={onChange}
                                />
                                <br /> <br />
                            </>
                        )}
                        <TinyMCE
                            id='description'
                            value={description}
                            label='Description'
                            onChange={onChange}
                            loading={loading}
                        />
                        <br/><br/>
                        <MaterialNumberFormat
                            id='cost'
                            value={cost}
                            onChange={basicInputOnChange}
                            label='Cost Per Student'
                            required
                            variant='outlined'
                        />
                        <br /><br />
                        <Typography>Make the fee available for a limited time (optional)</Typography>
                        <br />
                        <DateRangePicker
                            startDate={open_time}
                            startDateId='open_time'
                            endDate={close_time}
                            endDateId='close_time'
                            onDatesChange={({ startDate, endDate }) => {
                                onChange('open_time', startDate);
                                onChange('close_time', endDate);
                            }}
                            focusedInput={focusedInput}
                            onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                            isOutsideRange={() => false}
                        />
                        {(staffCanApprove || currentUser.super_admin_grants.length === 1) ? null : (
                            <>
                            <br /> <br />
                            <Typography>Select an approver</Typography>
                            <br />
                            <StoreItemApproverSelect
                                id='approver_id'
                                value={approver_id}
                                onChange={onChange}
                            />
                            </>
                        )}
                    </Paper>
                    <br /><br />
                    <UserFormSelect
                        id='form_id'
                        label='Custom Form'
                        switchText='Use a custom form to request information from parents/customers.'
                        value={form_id}
                        onChange={onChange}
                    />
                    <br /><br />
                    <StudentSelection
                        label='Students'
                        selectedStudents={selectedStudents}
                        setSelectedStudents={setSelectedStudents}
                        open_signup={open_signup}
                        basicInputOnChange={basicInputOnChange}
                    />
                    {/* <Typography>Select Students</Typography>
                    <br />
                    <ReactTable
                        ref={reactTable}
                        data={studentList}
                        columns={columns}
                        filterable={true}
                        loading={tableLoading}
                        LoadingComponent={ReactTablePreloader}
                        onFetchData={handleOnFetchData}
                        getTrProps={rowHandlers}
                        className='-highlight'
                        showPagination={false}
                        pageSize={(studentList.length > 10) ? studentList.length : 10}
                        style={{
                            height: '400px'
                        }}
                        manual'submit'
                    /> */}
                    {(staffCanApprove || currentUser.super_admin_grants.length === 1) && (
                        <>
                            <br /><br />
                            <StoreItemPayoutMethodSelect
                                id='payout_method_id'
                                value={payout_method_id}
                                onChange={onChange}
                                storeItemId={feeId}
                            />
                        </>
                    )}
                    <Grid
                        container
                        direction='row'
                        justify='flex-end'
                        alignItems='center'
                    >
                        <SubmitButton
                            submitting={submitting}
                            success={success}
                            buttonText={editing ? 'Update Fee' : 'Create Fee'}
                            onClick={handleUpdateFee}
                        />
                    </Grid>
                </form>
            </Paper>
        )
    }

}