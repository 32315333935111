import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Link } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles(styles);

export default function NeedHelp() {
    const classes = useStyles();
    return (
        <Paper
            className={classes.pagePaper}
        >
            <h4>
                <Link href='https://cspsoftware.helpdocs.com/' target='_blank' rel='noopener'>
                    View Help Docs
                </Link> 
            </h4>
            <h4>
                Contact Us
            </h4>
            <p>
                <Link href='mailto:support@chartersuccesspartners.com'>
                    support@chartersuccesspartners.com
                </Link>
                <br/>
                <Link href='tel:888-485-4643'>
                    888-485-4643
                </Link>
            </p>
        </Paper>
    );
}