import React from 'react'
//import ReactTable from 'react-table-6'
import ReactTable from 'components/Table'
import 'react-table-6/react-table.css'
import ReactTablePreloader from 'components/Preloader/ReactTablePreloader';
import { SchoolContext } from 'components/SchoolProvider.js'
import { UserContext } from 'components/UserProvider.js'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { listStudents } from 'api/students.js'
import { listStaffCourseSections } from 'api/staff.js'
import { listCourseSections } from 'api/coursesections'
import { listCourses } from 'api/courses'
import { make_filter_params, make_params_from_react_table } from 'api/base.js'
import useConstant from 'use-constant'
import remove from 'lodash/remove'
import Select from 'react-select'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from '@material-ui/core';
import {Box} from '@material-ui/core'
import { param } from 'jquery';
import Preloader from 'components/Preloader/Preloader';
import { FilterStateProvider } from 'components/FilterStateContext'
import { filter } from 'lodash';



const useStyles = makeStyles(styles);


export default function StudentSelection(props) {
    const classes = useStyles();
    const currentSchool = React.useContext(SchoolContext)[0]
    const currentUser = React.useContext(UserContext)[0]
    const [tableLoading, setTableLoading] = React.useState(true);
    const [studentList, setStudentList] = React.useState([])
    const [sections, setSections] = React.useState([])
    const [sectionsIsLoading, setSectionsIsLoading] = React.useState(true)
    const [courses, setCourses] = React.useState([])
    const [coursesIsLoading, setCoursesIsLoading] = React.useState(true)
    const [currentSection, setCurrentSection] = React.useState(null)
    const [currentCourse, setCurrentCourse] = React.useState(null)
    const [currentTerm, setCurrentTerm] = React.useState(null)
    const [terms, setTerms] = React.useState([])
    const [termsIsLoading, setTermsIsLoading] = React.useState(true)
    const reactTable = React.createRef();
    const [newChecked, setNewChecked] = React.useState({})
    const [currentChecked, setCurrentChecked] = React.useState({})
    const [refetch, setRefetch] = React.useState(false)
    const [clearChecked, setClearChecked] = React.useState(false)


    // React.useEffect(() => {
    //     //console.log('new', newChecked)
    // }, [newChecked])


    React.useEffect(() => {
        setRefetch(!refetch)     
    }, [currentCourse, currentSection])



    async function listSections(term=null) {
        let params = []

        if (term) {
            console.log(term)
            params = make_filter_params(
                [
                    { field: 'term', op: 'eq', value: term}
                ]
            )
        }

        if (
            currentUser.super_admin_grants.length === 1 ||
            (currentUser.hasOwnProperty('school_staff_grants_mapping') &&
                currentUser.school_staff_grants_mapping.approve_store_item_requests
            )
        ) {
            var r = await listCourseSections(currentSchool.value, params)
        }

        else {
            var r = await listStaffCourseSections(currentSchool.value, currentUser.id, params)
        }

        var apiSections = r.data.results.map(s => {
            var rs = {};
            rs['value'] = s.id
            rs['label'] = s.course.course_name + ' - ' + s.period
            return rs;
        })
        setSections(apiSections)
        setSectionsIsLoading(false)

        let termList = []
        r.data.results.map(s => {
            if (!termList.includes(s.term)) {
                termList.push(s.term)
            }
        })

        var apiTerms = termList.map(t => {
            var rs = {}
            rs['value'] = t
            rs['label'] = t
            return rs
        })
        setTerms(apiTerms)
        setTermsIsLoading(false)
    }


    async function doListCourses(term=null) {

        let params = []

        if (term) {
            params = make_filter_params(
                { field: 'course_sections.term', op: 'eq', value: term }
            )
        }

        if (
            currentUser.super_admin_grants.length === 1 ||
            (currentUser.hasOwnProperty('school_staff_grants_mapping') &&
                currentUser.school_staff_grants_mapping.approve_store_item_requests
            )
        ) {
            const r = await listCourses(currentSchool.value)

            var apiCourses = r.data.results.map(c => {
                var rs = {}
                rs['value'] = c.id
                rs['label'] = c.course_name
                return rs
            })
            setCourses(apiCourses)
        }
        setCoursesIsLoading(false)
    }


    React.useEffect(() => {   
        
        async function stuInit() {
            setTableLoading(true)
            const r = await listStudents(currentSchool.value)
            let sL = r.data.results.slice()
            props.selectedStudents.map(selS => {
                remove(sL, s => s.id === selS.id)
            })
            setStudentList(sL)
            setTableLoading(false)
        }
        
        stuInit()
        listSections()
        doListCourses()

    }, [currentSchool]) // Never refire



    async function handleSectionChange(val) {
        //setTableLoading(true)
        setCurrentCourse(null)
        setCurrentSection(val)
        // var params = make_params_from_react_table(reactTable.current.state)

        // // Don't add filters if course filter is blank
        // if (val !== null) {
        //     // Add filters
        //     var filter = []
        //     filter.push(
        //         { field: 'course_sections.id', op: 'eq', value: val.value }
        //     )
        //     const filterParams = make_filter_params(filter)

        //     params = { ...params, ...filterParams }
        // }

        // // We don't want to paginate
        // delete params.page
        // delete params.per_page
        // const r = await listStudents(currentSchool.value, params)
        // var sL = r.data.results.slice()
        // props.selectedStudents.map(selS => {
        //     remove(sL, s => s.id === selS.id)
        // })
        // setStudentList(sL)
        // setTableLoading(false)
    }


    async function handleCourseChange(val) {
        // setTableLoading(true)
        setCurrentSection(null)
        setCurrentCourse(val)
        // let params = make_params_from_react_table(reactTable.current.state)

        // if (val !== null) {
        //     let filter = []
        //     filter.push(
        //         {field: 'courses.id', op: 'eq', value: val.value}
        //     )
        //     const filterParams = make_filter_params(filter)

        //     params = { ...params, ...filterParams}
        // }

        // delete params.page
        // delete params.per_page
        // const r = await listStudents(currentSchool.value, params)
        // let sL = r.data.results.slice()
        // props.selectedStudents.map(selS => {
        //     remove(sL, s => s.id === selS.id)
        // })
        // setStudentList(sL)
        // setTableLoading(false)
    }


    async function handleTermChange(val) {
        setCurrentTerm(val)
        if (val !== null) {
            listSections(val.value)
            //doListCourses(val.value)
        }
        else {
            listSections()
        }
    }


    // Debounce listStudents API call so it doesn't fire on every filter keystroke
    const listStudentsDebounced = useConstant(() =>
        AwesomeDebouncePromise(
            listStudents,
            400
        )
    );


    // async function handleOnFetchData(state, instance) {
    //     if (!tableLoading) {
    //         setTableLoading(true)
    //         const params = make_params_from_react_table(state)
    //         // We don't want to paginate
    //         delete params.page
    //         delete params.per_page
    //         const r = await listStudents(currentSchool.value, params)
    //         var sL = r.data.results.slice()
    //         props.selectedStudents.map(selS => {
    //             remove(sL, s => s.id === selS.id)
    //         })
    //         setStudentList(sL)
    //         //setPages(r.data.pages)
    //         //setCurrentPage()
    //         setTableLoading(false)
    //     }
    // }


    async function doFetchStudents(params={}) {
        if (currentCourse) {
            const filter = [
                { field: 'courses.id', op: 'eq', value: currentCourse.value}
            ]
            const filterParams = make_filter_params(filter)
            params = { ...params, ...filterParams}
        }
        if (currentSection) {
            const filter = [
                { field: 'course_sections.id', op: 'eq', value: currentSection.value }
            ]
            const filterParams = make_filter_params(filter)
            params = { ...params, ...filterParams }
        }
        let r = await listStudents(currentSchool.value, params)
        console.log('doFetch', props.selectedStudents)
        props.selectedStudents.map(sS => {
            const idx = r.data.results.findIndex(s => s.id === sS.id)
            if (idx > -1) {
                r.data.results.splice(idx, 1)
            }
        })

        return r
    }


    const fetchFunction = React.useCallback(async (params={}) => {
        // return await listStudents(currentSchool.value, params)
        return await doFetchStudents(params)
    }, [props.selectedStudents, currentCourse, currentSection])


    React.useEffect(() => {
        //console.log(props.selectedStudents)
        setRefetch(!refetch)
        setClearChecked(!clearChecked)
    }, [props.selectedStudents])


    function selectStudent(student) {
        var sL = studentList.slice()
        remove(sL, s => s.id === student.id)
        setStudentList(sL)
        var sS = props.selectedStudents.slice()
        sS.push(student)
        props.setSelectedStudents(sS)
    }


    function rowHandlers(state, rowInfo, column, instance) {
        return {
            onClick: e => {
                selectStudent(rowInfo.original)
                // var sL = studentList.slice()
                // remove(sL, s => s.id === rowInfo.original.id)
                // setStudentList(sL)
                // var sS = props.selectedStudents.slice()
                // sS.push(rowInfo.original)
                // props.setSelectedStudents(sS)
            },
            style: {
                cursor: 'pointer'
            }
        }
    }

    
    function deselectStudent(student) {
        var sS = props.selectedStudents.slice()
        remove(sS, s=> s.id === student.id)
        props.setSelectedStudents(sS)
        var sL = studentList.slice()
        sL.push(student)
        setStudentList(sL)
    }

    function deselectAllStudents() {
        const sS = props.selectedStudents.slice()

        var newSS = props.selectedStudents.slice()
        sS.map(stu => {
            remove(newSS, s=> s.id === stu.id)
        })
        props.setSelectedStudents(newSS)

        var newSL = studentList.slice()
        setStudentList(newSL)
    }

    function handleSelectStudents() {
        let sS = props.selectedStudents.slice()
        for (const [k, v] of Object.entries(newChecked)) {
            sS.push(v)
        }
        props.setSelectedStudents(sS)
        //setNewChecked({})
        
        setRefetch(!refetch)
        setClearChecked(!clearChecked)


        // const sL = studentList.slice()

        // var newSL = studentList.slice()
        // sL.map(stu => {
        //     remove(newSL, s => s.id === stu.id)
        // })
        // setStudentList(newSL)

        // var newSS = props.selectedStudents.slice()
        // sL.map(stu => {
        //     newSS.push(stu)
        // })
        // props.setSelectedStudents(newSS)        
    }


    const columns = React.useMemo(() => [
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'Grade Level',
            accessor: 'grade_level',
            type: 'number'
        },
    ], [])


    return (
        <Paper
            className={classes.studentSelectionPaper}
        >
            <Typography>
                <Box fontSize={20}>
                    {props.label}
                </Box>
            </Typography>
            <hr />
            <FormGroup>
                <FormControlLabel
                control={
                    <Checkbox
                        checked={props.open_signup}
                        onChange={props.basicInputOnChange}
                        value='open_signup'
                        id='open_signup'
                        color='primary'
                    />
                }
                label='Allow any guardian at the school to find and pay/sign up.'
                />
            </FormGroup>
            <hr />
            <TableContainer component={Paper} className={[classes.studentSelectionSubPaper, classes.selectedStudentPaper].join(' ')}>
                <Typography>Currently selected Students</Typography>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Grade Level</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.selectedStudents.map(s => (
                            <TableRow
                                className={classes.selectedStudentTableRow}
                                key={s.id}
                                onClick={() => deselectStudent(s)}
                            >
                                <TableCell>
                                    <Checkbox
                                        checked
                                        color='primary'
                                    />
                                </TableCell>
                                <TableCell>{s.first_name}</TableCell>
                                <TableCell>{s.last_name}</TableCell>
                                <TableCell>{s.grade_level}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <br />
                <Button
                    variant='contained'
                    style={{display: props.selectedStudents.length === 0 ? 'none' : ''}}
                    onClick={deselectAllStudents}
                >
                    Clear all selected students
                </Button>
            </TableContainer>
            <br /><br />
            <Paper
                className={classes.studentSelectionSubPaper}
            >
                <Typography>Select Students</Typography>
                <br /><br />
                <Typography>Filter Sections by Term SIS ID</Typography>
                <Select
                    className={classes.itemLink}
                    options={terms}
                    isClearable={true}
                    value={currentTerm}
                    isLoading={termsIsLoading}
                    onChange={handleTermChange}
                    defaultValue={null}
                />
                <br />
                <Typography>Filter by Section (Period)</Typography>
                <Select
                    className={classes.itemLink}
                    options = {sections}
                    isClearable={true}
                    value={currentSection}
                    isLoading={sectionsIsLoading}
                    onChange={handleSectionChange}
                    defaultValue={null}
                />
                <br />
                {(currentUser.super_admin_grants.length === 1 ||
                    (currentUser.hasOwnProperty('school_staff_grants_mapping') &&
                        currentUser.school_staff_grants_mapping.approve_store_item_requests
                    )) &&
                <>
                <Typography>Filter by Course</Typography>
                <Select
                    className={classes.itemLink}
                    options = {courses}
                    isClearable={true}
                    value={currentCourse}
                    isLoading={coursesIsLoading}
                    onChange={handleCourseChange}
                    defaultValue={null}
                />
                </>
                }
                <br/>
                <Typography>You can also filter by name and grade here.</Typography>
                <br />
                <Typography>Select an individual student by clicking on the row.</Typography>
                {/* <ReactTable
                    ref={reactTable}
                    data={studentList}
                    columns={columns}
                    filterable={true}
                    loading={tableLoading}
                    LoadingComponent={ReactTablePreloader}
                    onFetchData={handleOnFetchData}
                    getTrProps={rowHandlers}
                    className='-highlight'
                    showPagination={false}
                    pageSize={(studentList.length > 10) ? studentList.length : 10}
                    style={{
                        height: '400px'
                    }}
                    manual
                /> */}
                <FilterStateProvider>
                    <ReactTable
                        columns={columns}
                        fetchFunction={fetchFunction}
                        informObjChecked={setNewChecked}
                        refetch={refetch}
                        clearChecked={clearChecked}
                    />
                </FilterStateProvider>
                <br />
                <Button
                    variant='contained'
                    style={{ display: studentList.length === 0 ? 'none' : '' }}
                    disabled={Object.keys(newChecked).length === 0}
                    onClick={handleSelectStudents}
                >
                    Add all {Object.keys(newChecked).length} selected students
                </Button>
            </Paper>
        </Paper>
    )

}