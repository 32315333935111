import React from 'react'
import { SchoolContext } from 'components/SchoolProvider.js'
import Select from 'react-select'
import { listPayoutMethods } from 'api/payoutmethods'
import { getCategory } from 'api/category'
import { make_filter_params } from 'api/base'
import { Typography } from '@material-ui/core'
import { listOrderItemsByStoreItem } from 'api/storeitems'


export default function StoreItemPayoutMethodSelect(props) {
    const [currentSchool, setcurrentSchool] = React.useContext(SchoolContext)
    const [payoutMethods, setPayoutMethods] = React.useState([])
    const [category, setCategory] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [disabled, setDisabled] = React.useState(false)


    React.useEffect(() => {
        async function init() {
            setLoading(true)

            let filters = []
            filters.push({ field: 'stripe_verification_complete', op: 'eq', value: true})
            const params = make_filter_params(filters)

            const r = await listPayoutMethods(currentSchool.value, params)
            let selectItems = r.data.results.map(m => {
                let mS = {}
                mS.value= m.id
                mS.label = m.bank_name + ' ending in ' + m.last_four
                return mS
            })
            setPayoutMethods(selectItems)

            if (props.storeItemId) {
                const r1 = await listOrderItemsByStoreItem(currentSchool.value, props.storeItemId)
                if (r1.data.results.length > 0) {
                    setDisabled(true)
                }
            }
            

            setLoading(false)
        }

        init()
    }, [currentSchool])


    function selectValue() {
        return payoutMethods.filter(m => {
            return m.value === props.value
        })
    }


    function onChange(selected) {
        if (!selected) {
            props.onChange(props.id, null)
            return
        }
        props.onChange(props.id, selected.value)
    }


    return (
        !loading &&
        <>
            <Typography>Use a specific bank account for payments (optional):</Typography>
            <br />
            {disabled &&
            <>
                <Typography>Bank account cannot be changed after purchases have been made.</Typography>
            </>
            }
            <Select
                id={props.id}
                options={payoutMethods}
                isClearable={true}
                value={selectValue()}
                isLoading={loading}
                onChange={(selected) => onChange(selected)}
                isDisabled={disabled}
            />
        </>
        
    )
}