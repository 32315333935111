import React from 'react'
import { getStoreItem } from 'api/storeitems'
import { useSnackbar } from 'notistack';
import { make_filter_params } from 'api/base';
import Preloader from 'components/Preloader/Preloader';
import { SchoolContext } from 'components/SchoolProvider.js'
import { UserContext } from 'components/UserProvider.js'
import { useHistory, Link } from "react-router-dom";
import { Typography, TableContainer, Table, TableBody, Paper, Button, Select, FormControl, InputLabel, MenuItem, TextField, Grid, Menu, Switch } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { ControlledInputInternals } from 'components/CustomInput/CustomInput';
import { MaterialNumberFormat } from 'components/CustomInput/NumberFormat.js'
import UserFormSelect from 'components/CustomInput/UserFormSelect'
import { SubmitButton } from 'components/CustomButtons/Button.js'
import { updateStoreItem } from 'api/storeitems';
import { createStoreItem } from 'api/storeitems';
import { listStoreGroups } from 'api/storegroups';
import { getSchoolChildcareSettings } from 'api/schoolchildcaresettings';



const useStyles = makeStyles(styles)


export default function EditBeforeAfterCare(props) {
    const classes = useStyles()
    const currentSchool = React.useContext(SchoolContext)[0]
    const currentUser = React.useContext(UserContext)[0]
    const [loading, setLoading] = React.useState(true);
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [plan, setPlan] = React.useState({})
    const [groupList, setGroupList] = React.useState({})
    const [defaultRegistrationFeeAmount, setDefaultRegistrationFeeAmount] = React.useState(null)
    const [defaultRegistrationFormId, setDefaultRegistrationFormId] = React.useState(null)
    let history = useHistory()

    const { enqueueSnackbar } = useSnackbar();

    // Determine if we are creating or editing
    var editing = false;
    var planId = '';
    if (props.location.pathname.split('/')[4] === 'edit') {
        editing = true;
        planId = props.location.pathname.split('/')[3];
    }
    
    const [formState, dispatch, basicInputOnChange, onChange] = ControlledInputInternals()
    // Inputs
    const inputs = ['display_name', 'cost', 'group_id']
    // Childcare fields
    const [fFormState, fDispatch, fBasicInputOnChange, fOnChange] = ControlledInputInternals()
    // Inputs
    const fInputs = ['plan_type', 'sibling_cost', 'sibling_cost_2nd', 'registration_form_id',
                    'require_registration_fee', 'registration_fee_amount',
                    'use_default_registration_settings', 'registration_open'
                    ]


    React.useEffect(() => {
        async function init() {

            let filters1 = []
            filters1.push({ field: 'category_type', op: 'eq', value: 'childcare' })
            filters1.push({ field: 'childcare_group_fields.group_plan_type', op: 'eq', value: 'monthly' })
            const params1 = make_filter_params(filters1)
            const r1 = await listStoreGroups(currentSchool.value, params1)
            if (!r1.ok) {
                enqueueSnackbar('An error occurred.', { variant: 'error' })
                setLoading(false)
                return
            }
            setGroupList(r1.data.results)
            const r2  = await getSchoolChildcareSettings(currentSchool.value)
            if (!r2.ok) {
                enqueueSnackbar('An error occurred.', { variant: 'error' })
                setLoading(false)
                return
            }
            setDefaultRegistrationFeeAmount(r2.data.default_registration_fee_amount)
            setDefaultRegistrationFormId(r2.data.default_registration_form_id)


            if (editing) {
                const r = await getStoreItem(currentSchool.value, planId)
                if (!r.ok) {
                    window.location = '/admin'
                }
                setPlan(r.data)
                inputs.map(key => {
                    onChange(key, r.data[key])
                })
                if (r.data.childcare_fields !== null) {
                    fInputs.map(key => {
                        fOnChange(key, r.data.childcare_fields[key])
                    })
                }

            }
            else {
                fOnChange('use_default_registration_settings', true)
                fOnChange('registration_open', true)
                fOnChange('registration_fee_amount', r2.data.default_registration_fee_amount)
                if (r2.data.default_registration_fee_amount) {
                    fOnChange('require_registration_fee', true)
                }
                if (r2.data.default_registration_form_id) {
                    fOnChange('registration_form_id', r2.data.default_registration_form_id)
                }
            }
            setLoading(false)
        }

        init()
    }, [])


    React.useEffect(() => {
        if (fFormState.use_default_registration_settings) {
            if (defaultRegistrationFeeAmount) {
                fOnChange('registration_fee_amount', defaultRegistrationFeeAmount)
                fOnChange('require_registration_fee', true)
            }
            if (defaultRegistrationFormId){
                fOnChange('registration_form_id', defaultRegistrationFormId)
            }
        }
    }, [fFormState.use_default_registration_settings])


    async function handleUpdatePlan(e) {
        e.preventDefault()
        let form = document.querySelector('form')
        if (!form.reportValidity()) {
            return
        }
        if (fFormState.plan_type === null || fFormState.plan_type === undefined) {
            return
        }
        if (fFormState.plan_type === 'monthly') {
            if (formState.group_id === null || formState.group_id === undefined) {
                return
            }
        }
        if (fFormState.plan_type === 'weekly') {
            delete formState.group_id
        }
        
        setSubmitting(true)
        setSuccess(false)

        let r = null
        let snackbarText = ''
        if (editing) {
            r = await updateStoreItem(
                currentSchool.value,
                planId,
                {store_item:
                    {...formState, childcare_fields: fFormState}
                }
            )
            snackbarText = 'updated'
        }
        else {
            r = await createStoreItem(
                currentSchool.value,
                {store_item:
                    {
                        ...formState,
                        category_type: 'childcare',
                        childcare_fields: fFormState
                    }
                }
            )
            snackbarText = 'created'
        }
        setSubmitting(false)
        if (!r.ok) {
            enqueueSnackbar('An error occurred', { variant: 'error' })
            return
        }
        enqueueSnackbar('Plan ' + snackbarText, { variant: 'success' })
        setPlan(r.data)
        setSuccess(true)
        if(!editing) {
            history.push('/admin/before_after_care/' + r.data.id + '/edit')
        }
    }


    if (loading) { return <Preloader/>}

    return (
        <Paper
            className={classes.pagePaper}
        >
            {fInputs.plan_type !== 'punch_card' && editing &&
                <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                >
                        <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => history.push('/admin/before_after_care/' + plan.id + '/manage_roster')}
                        >
                            Manage Roster
                        </Button>
                </Grid>
            }
            <form>
                <FormControl
                    style={{minWidth: '250px'}}
                    required
                >
                    <InputLabel id='plan-type-label'>Plan Type</InputLabel>
                    <Select
                        labelId='plan-type-label'
                        id='plan_type'
                        value={fFormState.plan_type}
                        onChange={(e) => fOnChange('plan_type', e.target.value)}
                    >
                        <MenuItem value={'monthly'}>Monthly</MenuItem>
                        <MenuItem value={'weekly'}>Weekly</MenuItem>
                        <MenuItem value={'punch_card'}>Punch Card</MenuItem>
                    </Select>
                </FormControl>
                <br /><br />
                <TextField
                    id='display_name'
                    label='Plan Name'
                    variant='outlined'
                    value={formState.display_name}
                    onChange={basicInputOnChange}
                    required
                />
                <br /><br />
                <MaterialNumberFormat
                    id='cost'
                    value={formState.cost}
                    onChange={(e) => {
                        basicInputOnChange(e)
                        fDispatch({field: 'sibling_cost', value: e.target.value})
                        fDispatch({field: 'sibling_cost_2nd', value: e.target.value})
                    }}
                    label='Cost'
                    required
                    variant='outlined'
                />
                <br /><br />
                <MaterialNumberFormat
                    id='sibling_cost'
                    value={fFormState.sibling_cost}
                    onChange={(e) => {
                        fBasicInputOnChange(e)
                        fDispatch({field: 'sibling_cost_2nd', value: e.target.value})
                    }}
                    label='1st Sibling Cost'
                    required
                    variant='outlined'
                />
                <br /><br />
                <MaterialNumberFormat
                    id='sibling_cost_2nd'
                    value={fFormState.sibling_cost_2nd}
                    onChange={fBasicInputOnChange}
                    label='2nd Sibling Cost'
                    required
                    variant='outlined'
                />
                <br /><br />
                <Switch
                    id={'registration_open'}
                    checked={fFormState.registration_open}
                    onChange={fBasicInputOnChange}
                    color='primary'
                />
                <label>Registration Open</label>
                <br /><br />
                
                <Switch
                    id={'use_default_registration_settings'}
                    checked={fFormState.use_default_registration_settings}
                    onChange={fBasicInputOnChange}
                    color='primary'
                />
                <label>Use Default Registation Settings</label>
                <br />
                <Switch
                    id={'require_registration_fee'}
                    checked={fFormState.require_registration_fee}
                    onChange={fBasicInputOnChange}
                    color='secondary'
                    disabled={fFormState.use_default_registration_settings}
                />
                <label>Require Registration Fee</label>
                {fFormState.require_registration_fee &&
                    <>
                    <br/><br/>
                    <MaterialNumberFormat
                        id='registration_fee_amount'
                        value={fFormState.registration_fee_amount}
                        onChange={fBasicInputOnChange}
                        label='Registration Fee'
                        required
                        variant='outlined'
                        disabled={fFormState.use_default_registration_settings}
                    />
                    </>
                }
                <br/><br/>
                <UserFormSelect
                    id='registration_form_id'
                    label='Registration Form'
                    value={fFormState.registration_form_id}
                    onChange={fOnChange}
                    disabled={fFormState.use_default_registration_settings}
                />
                {fFormState.plan_type === 'monthly' &&
                    <>
                    <br /><br />
                    <FormControl
                        style={{ minWidth: '250px' }}
                        required
                    >
                        <InputLabel id='group-id-label'>Plan Group</InputLabel>
                        <Select
                            labelId='group-id-label'
                            id='group_id'
                            value={formState.group_id}
                            onChange={(e) => onChange('group_id', e.target.value)}
                        >
                            {groupList.map(g => (
                                <MenuItem value={g.id}>{g.display_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    </>

                }
                
                <br />
                <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                >
                    <SubmitButton
                        submitting={submitting}
                        success={success}
                        buttonText={editing ? 'Update Plan' : 'Create Plan'}
                        onClick={handleUpdatePlan}
                    />
                </Grid>
            </form>
        </Paper>
    )
}