import { api_call, make_response_object } from 'api/base'


export async function listCustomerOrders(userId, params) {
    const r = await api_call('/users/' + userId + '/customer_orders', 'GET', {}, params)
    return await make_response_object(r)
}


export async function getCustomerOrder(userId, orderId) {
    const r = await api_call('/users/' + userId + '/customer_orders/' + orderId, 'GET')
    return await make_response_object(r)
}


export async function listUnpaidItems(userId, params) {
    const r = await api_call('/users/' + userId + '/unpaid_items', 'GET', {}, params)
    return await make_response_object(r)
}