import { api_call, make_response_object } from 'api/base'
import { make_filter_params } from './base'


export async function listOrderItems(userId, params) {
    const r = await api_call('/users/' + userId + '/order_items', 'GET', {}, params)
    return await make_response_object(r)
}


export async function getOrderItem(userId, orderItemId) {
    const r = await api_call('/users/' + userId + '/order_items/' + orderItemId, 'GET')
    return await make_response_object(r)
}


export async function refundOrderItems(userId, body) {
    const r = await api_call('/users/' + userId + '/order_items/refund', 'POST', body)
    return await make_response_object(r)
}