import React from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import { SchoolContext } from 'components/SchoolProvider.js';
import { useSnackbar } from 'notistack';
import { getGuardian, updateGuardian } from 'api/guardians.js';
import { listStudents } from 'api/students.js';
import { make_params_from_react_table } from 'api/base.js'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant'
import remove from 'lodash/remove';
import without from 'lodash/without';
import some from 'lodash/some';
import { SubmitButton } from 'components/CustomButtons/Button.js'
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js'
import { useHistory, Link } from "react-router-dom";
import { createGuardian } from 'api/guardians';
import { getByStudentReport} from 'api/reports'
import Preloader from 'components/Preloader/Preloader';
import { Typography, Box, TableBody, Table, TableRow, TableCell } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faTimesSquare } from '@fortawesome/pro-solid-svg-icons'
import { api_base } from 'api/base'


const useStyles = makeStyles(styles);


export default function ReportByStudent(props) {
    const classes = useStyles();
    const currentSchool = React.useContext(SchoolContext)[0]
    //const currentUser = React.useContext(UserContext)[0]
    const [loading, setLoading] = React.useState(true);
    const [report, setReport] = React.useState({})
    const [showChaperones, setShowChaperones] = React.useState(false)
    const [showDrivers, setShowDrivers] = React.useState(false)

    const storeItemId = props.location.pathname.split('/')[3]
    const { enqueueSnackbar } = useSnackbar();


    React.useEffect(() => {
        async function init() {
            const r = await getByStudentReport(currentSchool.value, storeItemId)
            if (!r.ok) {
                enqueueSnackbar('An error occurred', {variant: 'error'})
                window.location('/admin')
                return
            }
            setReport(r.data)
            setShowChaperones(r.data.hasOwnProperty('chaperone_count'))
            setShowDrivers(r.data.hasOwnProperty('parent_driver_count'))
            setLoading(false)
        }

        init()
    }, [])


    if (loading) {
        return <Preloader />
    }
    
    return (
        <Paper className={classes.pagePaper}>
            <Grid
                container
                direction='row'
                justify='flex-end'
                alignItems='center'
                spacing={1}
            >
                <Grid item>
                    <a href={api_base + '/schools/' + currentSchool.value + '/store_items/' + storeItemId + '/reports/by_student'} download>
                        <Button variant='contained' color='secondary'>
                            Export CSV: Student Report
                        </Button>
                    </a>
                </Grid>
                <Grid item>
                    <a href={api_base + '/schools/' + currentSchool.value + '/store_items/' + storeItemId + '/reports/purchases'} download>
                        <Button variant='contained' color='secondary'>
                            Export CSV: Purchases Report
                        </Button>
                    </a>
                </Grid>
            </Grid>
            <Typography style={{fontSize: 26}}>Student Report</Typography>
            {(showChaperones || showDrivers) &&
                <Box>
                    <Grid container spacing={2}>
                        {showChaperones &&
                            <Grid item>
                                <Typography>
                                    Chaperone Count: {report.chaperone_count}
                                </Typography>
                            </Grid>
                        }
                        {showDrivers &&
                            <Grid item>
                                <Typography>
                                    Driver Count: {report.parent_driver_count}
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </Box>
            }
            {(report.assigned.paid.length > 0 || report.assigned.unpaid.length > 0) &&
                <>
                <br />
                <Box>
                    <Typography style={{fontSize: 22}}>
                        Assigned Students
                    </Typography>
                </Box>
                <Table>
                    <TableBody>
                        {report.assigned.paid.map(s => (
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <Grid
                                        container
                                        alignItems='center'
                                        spacing={1}
                                    >
                                        <Grid item>
                                            <FontAwesomeIcon
                                                icon={faCheckSquare}
                                                size='2x'
                                                color='green'
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography>
                                                {s['Student Last Name']}, {s['Student First Name']}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                {showChaperones &&
                                    <TableCell>
                                        <Grid
                                            container
                                            alignItems='center'
                                            spacing={1}
                                        >
                                            <Grid item>
                                                {s['Chaperone?']  ?
                                                    <FontAwesomeIcon
                                                        icon={faCheckSquare}
                                                        color='green'
                                                    />
                                                    :
                                                    <FontAwesomeIcon
                                                        icon={faTimesSquare}
                                                        color='red'
                                                    />
                                                }
                                            </Grid>
                                            <Grid item>
                                                <Typography>
                                                    Chaperone
                                                </Typography>
                                            </Grid>                                            
                                        </Grid>
                                    </TableCell>
                                }
                                {showDrivers && 
                                    <TableCell>
                                        <Grid
                                            container
                                            alignItems='center'
                                            spacing={1}
                                        >
                                            <Grid item>
                                                {s['Parent Driver?'] ?
                                                    <FontAwesomeIcon
                                                        icon={faCheckSquare}
                                                        color='green'
                                                    />
                                                    :
                                                    <FontAwesomeIcon
                                                        icon={faTimesSquare}
                                                        color='red'
                                                    />
                                                }
                                            </Grid>
                                            <Grid item>
                                                <Typography>
                                                    Driver
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                }
                            </TableRow>
                        ))}
                        {report.assigned.unpaid.map(s => (
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <Grid
                                        container
                                        alignItems='center'
                                        spacing={1}
                                    >
                                        <Grid item>
                                            <FontAwesomeIcon
                                                icon={faTimesSquare}
                                                size='2x'
                                                color='red'
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography>
                                                {s['Student Last Name']}, {s['Student First Name']}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <br/>
                </>
            }
            {report.not_assigned.length > 0 &&
            <>
            <br />
            <Box>
                <Typography style={{ fontSize: 22 }}>
                    Non-Assigned Students
                </Typography>
            </Box>
            <Table>
                <TableBody>
                    {report.not_assigned.map(s => (
                        <TableRow>
                            <TableCell component='th' scope='row'>
                                <Grid
                                    container
                                    alignItems='center'
                                    spacing={1}
                                >
                                    <Grid item>
                                        <FontAwesomeIcon
                                            icon={faCheckSquare}
                                            size='2x'
                                            color='green'
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            {s['Student Last Name']}, {s['Student First Name']}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </TableCell>
                            {showChaperones &&
                                <TableCell>
                                    <Grid
                                        container
                                        alignItems='center'
                                        spacing={1}
                                    >
                                        <Grid item>
                                            {s['Chaperone?'] ?
                                                <FontAwesomeIcon
                                                    icon={faCheckSquare}
                                                    color='green'
                                                />
                                                :
                                                <FontAwesomeIcon
                                                    icon={faTimesSquare}
                                                    color='red'
                                                />
                                            }
                                        </Grid>
                                        <Grid item>
                                            <Typography>
                                                Chaperone
                                                </Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            }
                            {showDrivers &&
                                <TableCell>
                                    <Grid
                                        container
                                        alignItems='center'
                                        spacing={1}
                                    >
                                        <Grid item>
                                            {s['Parent Driver?'] ?
                                                <FontAwesomeIcon
                                                    icon={faCheckSquare}
                                                    color='green'
                                                />
                                                :
                                                <FontAwesomeIcon
                                                    icon={faTimesSquare}
                                                    color='red'
                                                />
                                            }
                                        </Grid>
                                        <Grid item>
                                            <Typography>
                                                Driver
                                                </Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            </>
            }
            {report.staff.length > 0 &&
            <>
            <br />
            <Box>
                <Typography style={{ fontSize: 22}}>
                    Staff
                </Typography>
                {report.staff.map(s => (
                    <TableRow>
                        <TableCell component='th' scope='row'>
                            <Grid
                                container
                                alignItems='center'
                                spacing={1}
                            >
                                <Grid item>
                                    <FontAwesomeIcon
                                        icon={faCheckSquare}
                                        size='2x'
                                        color='green'
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        {s['Staff Last Name']}, {s['Staff First Name']}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </TableCell>
                        {showChaperones &&
                            <TableCell>
                                <Grid
                                    container
                                    alignItems='center'
                                    spacing={1}
                                >
                                    <Grid item>
                                        {s['Chaperone?'] ?
                                            <FontAwesomeIcon
                                                icon={faCheckSquare}
                                                color='green'
                                            />
                                            :
                                            <FontAwesomeIcon
                                                icon={faTimesSquare}
                                                color='red'
                                            />
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            Chaperone
                                                </Typography>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        }
                        {showDrivers &&
                            <TableCell>
                                <Grid
                                    container
                                    alignItems='center'
                                    spacing={1}
                                >
                                    <Grid item>
                                        {s['Parent Driver?'] ?
                                            <FontAwesomeIcon
                                                icon={faCheckSquare}
                                                color='green'
                                            />
                                            :
                                            <FontAwesomeIcon
                                                icon={faTimesSquare}
                                                color='red'
                                            />
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            Driver
                                                </Typography>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        }
                    </TableRow>
                ))}
            </Box>
            </>
            }
        </Paper>
    )
}