import { api_call, make_response_object, api_base } from 'api/base'


export async function listSISImports(schoolId, params) {
    const r = await api_call('/schools/' + schoolId + '/sis_imports', 'GET', {}, params)
    return await make_response_object(r)
}


export async function createSISImport(schoolId, body, params) {
    var url = new URL('/schools/' + schoolId + '/sis_imports', api_base)
    url.search = new URLSearchParams(params).toString();

    const r = await fetch(
        url.toString(),
        {
            method: 'POST',
            body: body,
            credentials: 'include'
        }
    )
    return await make_response_object(r)
}


export async function sendSISImportInvites(schoolId, importId) {
    const r = await api_call('/schools/' + schoolId + '/sis_imports/' + importId + '/invites', 'POST',)
    return await make_response_object(r)
}