import { api_call, make_response_object } from 'api/base'


export async function listGuardians(schoolId, params) {
    const r = await api_call('/schools/' + schoolId + '/guardians', 'GET', {}, params)
    return await make_response_object(r)
}


export async function getGuardian(schoolId, guardianId) {
    const r = await api_call('/schools/' + schoolId + '/guardians/' + guardianId, 'GET')
    return await make_response_object(r)
}


export async function createGuardian(schoolId, body) {
    const r = await api_call(
        'schools/' + schoolId + '/guardians',
        'POST',
        body
    )
    return await make_response_object(r)
}


export async function updateGuardian(schoolId, staffId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/guardians/' + staffId,
        'PUT',
        body
    )
    return await make_response_object(r)
}


export async function listOrderItemsByGuardianStudents(schoolId, guardianId, params) {
    const r = await api_call(
        '/schools/' + schoolId + '/guardians/' + guardianId + '/student_order_items',
        'GET',
        {},
        params
    )
    return await make_response_object(r)
}
