import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getUserFromRegistrationToken, register } from 'api/auth'
import { SubmitButton } from 'components/CustomButtons/Button.js'
import { useSnackbar } from 'notistack';
import { UserContext } from 'components/UserProvider.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js'
import PasswordStrengthField from 'components/CustomInput/PasswordStrengthField'
import { Link } from "react-router-dom";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <a href='https://www.chartersuccesspartners.com/'>
                Charter Success Partners
            </a>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://admin.charterpay.org/logo.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '50%',
        backgroundPosition: 'center',
        backgroundColor: 'rgba(0, 26, 51,1)'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));



export default function Register(props) {
    const classes = useStyles();
    const [user, setUser] = React.useState({})
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = React.useState(false)
    const token = props.location.pathname.split('/')[2]
    const [error, setError] = React.useState(null)
    const [passwordError, setPasswordError] = React.useState(null)

    const [formState, dispatch, onChange] = ControlledInputInternals();


    React.useEffect(() => {
        async function initPage() {
            const r = await getUserFromRegistrationToken({token: token})
            if (!r.ok) {
                enqueueSnackbar(r.data.text, {variant: 'error'})
                console.log(r.data)
                setError(r.data.text)
                setLoading(false)
                return
            }
            setUser(r.data)
            setLoading(false)
        }

        initPage()
    }, [])


    async function handleRegister(event) {
        event.preventDefault();
        let form = document.querySelector('form')
        if (!form.reportValidity()) {
            return
        }
        
        if (passwordError) {
            enqueueSnackbar(passwordError, {variant: 'error'})
            return
        }

        setSubmitting(true)
        setSuccess(false)

        const r = await register({
            token: token,
            user: {
                first_name: formState.first_name,
                last_name: formState.last_name,
                password: formState.password
            }
        })
        if (!r.ok) {
            enqueueSnackbar(r.data.text, { variant: 'error' })
            console.log(r.data)
            setError(r.data.text)
            setLoading(false)
            return
        }
        
        setSubmitting(false)
        setSuccess(true)        
    }


    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <FontAwesomeIcon icon={faUser}/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Register
                    </Typography>
                    {(!error && !success) &&
                    <form className={classes.form} noValidate onSubmit={handleRegister.bind(props)}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            disabled
                            value={user.email || ''}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="first_name"
                            label="First Name"
                            name="first_name"
                            autoComplete="first_name"
                            autoFocus
                            value={formState.first_name}
                            onChange={onChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="last_name"
                            label="Last Name"
                            name="Last Name"
                            autoComplete="last_name"
                            value={formState.last_name}
                            onChange={onChange}
                        />
                        <PasswordStrengthField
                            name='password'
                            label='Password'
                            id='password'
                            value={formState.password}
                            onChange={onChange}
                            userInputs={[user.email, formState.first_name, formState.last_name]}
                            confirmName='password2'
                            confirmLabel='Confirm Password'
                            confirmId='password2'
                            confirmValue={formState.password2}
                            confirmOnChange={onChange}
                            error={passwordError}
                            setError={setPasswordError}
                        />
                        {/*<FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />*/}
                        <SubmitButton
                            submitting={submitting}
                            success={success}
                            buttonText='Register'
                            fullWidth
                        />
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </form>
                    }
                    {error &&
                        <>
                        <br /><br />
                        <Typography>
                            {error}
                        </Typography>
                        <Typography>
                            <a href='mailto:support@chartersuccesspartners.com'>support@chartersuccesspartners.com</a>
                        </Typography>
                        </>
                    }
                    {success &&
                        <>
                        <br /><br />
                        <Typography>
                            Registration Successful! Please click below to log in using your new password.
                        </Typography>
                        <Link to='/login'>
                            <Button
                                variant='contained'
                            >
                                Login
                            </Button>
                        </Link>
                        </>
                    }
                </div>
            </Grid>
        </Grid>
    );
}