/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// Fontawesome
import {icon} from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/free-solid-svg-icons'
import '@fortawesome/free-regular-svg-icons'
import '@fortawesome/free-brands-svg-icons'
import '@fortawesome/fontawesome-pro'
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import TableList from "views/TableList/TableList.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import SchoolStaffPage from 'views/SchoolStaff/SchoolStaff.js'
import EditSchoolStaff from 'views/SchoolStaff/EditSchoolStaff.js'
import GuardiansPage from 'views/Guardians/Guardians.js'
import EditGuardian from 'views/Guardians/EditGuardian.js';
import StudentsPage from 'views/Students/Students.js'
import EditStudent from 'views/Students/EditStudent.js'
import SchoolsPage from 'views/Schools/Schools.js'
import EditSchool from 'views/Schools/EditSchool.js'
import GlobalAccountsPage from 'views/GlobalAccounts/GlobalAccounts.js'
import NeedHelp from 'views/NeedHelp/NeedHelp.js'
import FeesPage from 'views/Fees/Fees.js'
import EditFee from "views/Fees/EditFee.js";
import UserFormsPage from 'views/Forms/Forms.js'
import EditForm from 'views/Forms/EditForm.js'
import { faUtensils, faSchool, faUserShield, faColumns, faUsers, faUserGraduate, faDollarSign, faClipboard, faQuestion, faHandHoldingUsd, faBusAlt, faFileImport, faCashRegister, faCog, faCogs } from "@fortawesome/free-solid-svg-icons";
import { faBellSchool, faFileChartPie, faHandsUsd } from '@fortawesome/pro-solid-svg-icons'
import LunchesPage from "views/Lunches/Lunches.js";
import EditLunchVendor from "views/Lunches/EditVendor";
import DonationsPage from 'views/Donations/Donations'
import EditDonation from "views/Donations/EditDonation";
import ActivitiesPage from 'views/Activities/Activities'
import EditActivity from 'views/Activities/EditActivity'
import BeforeAfterCarePage from 'views/BeforeAfterCare/BeforeAfterCare'
import EditBeforeAfterCare from 'views/BeforeAfterCare/EditBeforeAfterCare'
import EditBeforeAfterCarePlanGroup from 'views/BeforeAfterCare/EditPlanGroup'
import ReportByStudent from 'views/ReportByStudent/ReportByStudent'
import ReportsView from 'views/Reports/Reports'
import SISImportsView from 'views/SISImport/SISImport'
import OnSiteTransaction from 'views/OnSiteTransaction/OnSiteTransaction'
import ManageRoster from 'views/BeforeAfterCare/ManageRoster'
import Refunds from 'views/Refunds/Refunds'
import MultiPaidReport from 'components/Reports/MultiPaid'
import ManageSchoolPage from 'views/ManageSchool/ManageSchool'


const dashboardRoutes = [
  {
    path: '/schools',
    name: 'Schools',
    icon: faSchool,
    component: SchoolsPage,
    layout: '/admin',
    superAdminOnly: true,
  },
  {
    path: '/global_accounts',
    name: 'Global Accounts',
    icon: faUserShield,
    component: GlobalAccountsPage,
    layout: '/admin',
    superAdminOnly: true,
  },
  {
    path: 'none',
    name: 'SchoolSelector',
    type: 'select'
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: faColumns,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: '/school_staff',
    name: 'School Staff',
    icon: faUsers,
    component: SchoolStaffPage,
    permission: 'manage_school_staff',
    layout: '/admin'
  },
  {
    path: '/guardians',
    name: 'Guardians',
    icon: faUsers,
    component: GuardiansPage,
    permission: 'manage_guardians',
    layout: '/admin'
  },
  {
    path: '/students',
    name: 'Students',
    icon: faUserGraduate,
    component: StudentsPage,
    permission: 'manage_students',
    layout: '/admin'
  },
  {
    path: '/refunds',
    name: 'Refunds',
    icon: faHandsUsd,
    component: Refunds,
    permission: 'issue_refunds',
    layout: '/admin'
  },
  {
    path: '/on_site_transaction',
    name: 'On Site Transaction',
    icon: faCashRegister,
    component: OnSiteTransaction,
    permission: 'process_on_site_transactions',
    layout: '/admin'
  },
  {
    path: '/donations',
    name: 'Donations',
    icon: faHandHoldingUsd,
    component: DonationsPage,
    permission: 'create_store_item_requests',
    layout: '/admin',
    category: 'donations'
  },
  {
    path: '/fees',
    name: 'Fees',
    icon: faDollarSign,
    component: FeesPage,
    permission: 'create_store_item_requests',
    layout: '/admin',
    category: 'fees'
  },
  {
    path: '/activities',
    name: 'Activities and Field Trips',
    icon: faBusAlt,
    component: ActivitiesPage,
    permission: 'create_store_item_requests',
    layout: '/admin',
    category: 'activities'
  },
  {
    path: '/lunches',
    name: 'Lunches',
    icon: faUtensils,
    component: LunchesPage,
    permission: 'manage_lunches',
    layout: '/admin',
    category: 'lunches'
  },
  {
    path: '/before_after_care',
    name: 'Before & After Care',
    icon: faBellSchool,
    component: BeforeAfterCarePage,
    permission: 'manage_childcare',
    layout: '/admin',
    category: 'childcare'
  },
  {
    path: '/forms',
    name: 'Forms',
    icon: faClipboard,
    component: UserFormsPage,
    layout: '/admin'
  },
  {
    path: '/reports',
    name: 'Reports',
    icon: faFileChartPie,
    component: ReportsView,
    permission: 'create_share_reports',
    layout: '/admin'
  },
  {
    path: '/sis_imports',
    name: 'SIS Imports',
    icon: faFileImport,
    component: SISImportsView,
    layout: '/admin',
    permission: 'manage_sis_imports'
  },
  {
    path: '/manage_school',
    name: 'Manage School',
    icon: faCogs,
    component: ManageSchoolPage,
    layout: '/admin',
    permission: 'manage_school'
  },
  {
    path: "/help",
    name: "Need Help?",
    icon: faQuestion,
    component: NeedHelp,
    layout: '/admin',
  },
  {
    path: '/school_staff/*/edit',
    name: 'Edit Staff Member',
    component: EditSchoolStaff,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/guardians/*/edit',
    name: 'Edit Guardian',
    component: EditGuardian,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/students/*/edit',
    name: 'Edit Student',
    component: EditStudent,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/schools/*/edit',
    name: 'Edit School',
    component: EditSchool,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/fees/*/edit',
    name: 'Edit Student Fee',
    component: EditFee,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/forms/*/edit',
    name: 'Edit Form',
    component: EditForm,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/lunches/edit_vendor/*',
    name: 'Edit Lunch Vendor',
    component: EditLunchVendor,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/donations/*/edit',
    name: 'Edit Donation',
    component: EditDonation,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/activities/*/edit',
    name: 'Edit Activity',
    component: EditActivity,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/before_after_care/plan_groups/*/edit',
    name: 'Edit Before & After Care Plan Group',
    component: EditBeforeAfterCarePlanGroup,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/before_after_care/*/edit',
    name: 'Edit Before & After Care Plan',
    component: EditBeforeAfterCare,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/before_after_care/*/manage_roster',
    name: 'Manage Roster',
    component: ManageRoster,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/school_staff/new',
    name: 'Create Staff Member',
    component: EditSchoolStaff,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/guardians/new',
    name: 'Create Guardian',
    component: EditGuardian,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/students/new',
    name: 'Create Student',
    component: EditStudent,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/schools/new',
    name: 'Create School',
    component: EditSchool,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/fees/new',
    name: 'Create Student Fee',
    component: EditFee,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/forms/new',
    name: 'Create Form',
    component: EditForm,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/lunches/new_vendor',
    name: 'Create Lunch Vendor',
    component: EditLunchVendor,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/donations/new',
    name: 'Create Donation',
    component: EditDonation,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/activities/new',
    name: 'Create Activity',
    component: EditActivity,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/before_after_care/new',
    name: 'New Before & After Care Plan',
    component: EditBeforeAfterCare,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/before_after_care/plan_groups/new',
    name: 'New Before & After Care Plan Group',
    component: EditBeforeAfterCarePlanGroup,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/activities/*/report_by_student',
    name: 'Report by Student',
    component: ReportByStudent,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/fees/*/report_by_student',
    name: 'Report by Student',
    component: ReportByStudent,
    layout: '/admin',
    showInSidebar: false
  },
  {
    path: '/reports/multi-activity-fee',
    name: 'Multiple Activity/Fee Student Report',
    component: MultiPaidReport,
    layout: '/admin',
    showInSidebar: false
  }
];

export default dashboardRoutes;
