import React from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import { SchoolContext } from 'components/SchoolProvider.js'
import { UserContext } from 'components/UserProvider.js'
import { UserSettingsContext } from 'components/UserSettingsProvider.js'
import { getStaff } from 'api/staff.js'
import { listStoreItems } from 'api/storeitems'
import { make_filter_params, make_params_from_react_table } from 'api/base.js'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant'
import { useHistory, Link, Switch } from "react-router-dom";
import ReactTablePreloader from 'components/Preloader/ReactTablePreloader.js';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty'
import { useSnackbar } from 'notistack';
import { api_base } from 'api/base';
import { FormControlLabel, Grid } from '@material-ui/core';
import MUISwitch from '@material-ui/core/Switch';
import { getSchoolSettings } from 'api/schoolsettings';



const useStyles = makeStyles(styles)



function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}


export default function StoreItemTabs(props) {
    const classes = useStyles();
    const currentSchool = React.useContext(SchoolContext)[0]
    const currentUser = React.useContext(UserContext)[0]
    const [userSettings, updateUserSetting] = React.useContext(UserSettingsContext)
    const [staffCanCreate, setStaffCanCreate] = React.useState(false)
    const [staffCanApprove, setStaffCanApprove] = React.useState(false)
    const [staffCanReport, setStaffCanReport] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const [storeItemList, setStoreItemList] = React.useState([])
    const [tableLoading, setTableLoading] = React.useState(true);
    const [tab, setTab] = React.useState(0)
    const [defaultTabLoading, setDefaultTabLoading] = React.useState(true);
    const [pages, setPages] = React.useState(0)
    const reactTable = React.createRef();
    const [schoolSettings, setSchoolSettings] = React.useState({})
    let history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [unpaidReportHref, setUnpaidReportHref] = React.useState(
        api_base + '/schools/' + currentSchool.value + '/reports/categories/' + props.catEnum + '/unpaid'
    )



    function makeParams(state, tabNum) {
        const params = make_params_from_react_table(state)

        // Add filters based on tab and store item category
        var tabFilters = [{ field: 'category_type', op: 'eq', value: props.catEnum },]
        // My Fees
        if (tabNum === 0) {
            tabFilters.push({ field: 'owner_id', op: 'eq', value: currentUser.id })
        }
        // Needs Approval
        else if (tabNum === 1) {
            tabFilters.push({ field: 'approver_id', op: 'eq', value: currentUser.id })
            tabFilters.push({ field: 'approval_status', op: 'eq', value: 'pending' })
        }
        // Approved
        else if (tabNum === 2) {
            tabFilters.push({ field: 'approver_id', op: 'eq', value: currentUser.id })
            tabFilters.push({ field: 'approval_status', op: 'eq', value: 'approved' })
        }
        const tabFilterParams = make_filter_params(tabFilters)

        const otherFilters = []
        if ((props.catEnum === 'fees' && !userSettings.showPastFees) || (props.catEnum === 'donations' && !userSettings.showPastDonations)) {
            otherFilters.push({ field: 'open_items_only', op: 'eq', value: true })
            otherFilters.push({ field: 'use_last_day', op: 'eq', value: true })

        }
        else if (props.catEnum === 'activities' && !userSettings.showPastActivities) {
            otherFilters.push({ field: 'open_activities_only', op: 'eq', value: true })
            otherFilters.push({ field: 'use_last_day', op: 'eq', value: true })
        }
        const otherFilterParams = make_filter_params(otherFilters)

        return { ...params, ...tabFilterParams, ...otherFilterParams }
    }


    // React.useEffect(() => {
    //     async function init() {
    //         setLoading(true)
    //         const r = await getSchoolSettings(currentSchool.value)
    //         // Ignore 404 as settings are not created for each school automatically
    //         if (!r.ok) {
    //             // Ignore 404 as settings are not created for each school automatically
    //             if (r.status != 404) {
    //                 enqueueSnackbar('An error occurred.', {variant: 'error'})
    //                 setLoading(false)
    //                 return
    //             }             
    //         }
    //         setSchoolSettings(r.data)
    //         console.log(r.data)
    //     }
    //     if (props.catEnum === 'fees' || props.catEnum === 'activities') {
    //         init()
    //     }
    // }, [currentSchool])


    React.useEffect(() => {
        let href = new URL('/schools/' + currentSchool.value + '/reports/categories/' + props.catEnum + '/unpaid', api_base)
        if (userSettings.reportSYFilter) {
            let params = new URLSearchParams()
            params.set('current_sy_only', true)
            href.search = params.toString()
        }
        setUnpaidReportHref(href.toString())       
        
        
    }, [userSettings])


    React.useEffect(() => {
        // Handle case where currentUser is not staff for currentSchool
        const pem = !currentUser.isSchoolStaff
            ? false : currentUser.school_staff_grants_mapping.approve_store_item_requests
        setStaffCanApprove(pem)

        // Handle case where currentUser is not staff for currentSchool
        const pem1 = !currentUser.isSchoolStaff
            ? false : currentUser.school_staff_grants_mapping.create_store_item_requests
        setStaffCanCreate(pem1)

        const pem2 = !currentUser.isSchoolStaff
            ? false: currentUser.school_staff_grants_mapping.create_share_reports
        setStaffCanReport(pem2)

    }, [currentUser])


    React.useEffect(() => {
        setDefaultTabLoading(true)
        setTab(getFirstEnabledTab())
        setDefaultTabLoading(false)
    }, [staffCanCreate, staffCanApprove])
    

    React.useEffect(() => {
        async function initTable() {
            setLoading(true)
            setTableLoading(true)
            
            const params = makeParams(reactTable.current.state, tab)
            const r = await listStoreItems(currentSchool.value, params)
            setStoreItemList(r.data.results)
            setPages(r.data.pages)
            setTableLoading(false)
            setLoading(false)
        }
        if (!defaultTabLoading && reactTable.current !== null) {
            initTable()
        }
    }, [currentSchool, tab])


    function getFirstEnabledTab() {
        if (staffCanCreate) { return 0 }
        else if (staffCanApprove) { return 1 }
        else if (currentUser.super_admin_grants.length === 1) { return 3 }
    }


    // Debounce listStoreItems API call so it doesn't fire on every filter keystroke
    const listStoreItemsDecounced = useConstant(() =>
        AwesomeDebouncePromise(
            listStoreItems,
            400
        )
    );


    async function handleOnFetchData(state, instance) {
        if (!loading) {
            setTableLoading(true)
            const params = makeParams(state, instance.props.tabNum)
            const r = await listStoreItemsDecounced(currentSchool.value, params)
            setStoreItemList(r.data.results)
            setPages(r.data.pages)
            //setCurrentPage()
            setTableLoading(false)
        }
    }


    function handleSettingToggle(e) {
        if (props.catEnum === 'donations') {
            updateUserSetting('showPastDonations', e.target.checked)
        }
        else if (props.catEnum === 'fees') {
            updateUserSetting('showPastFees', e.target.checked)
        }
        else if (props.catEnum === 'activities') {
            updateUserSetting('showPastActivities', e.target.checked)
        }
        reactTable.current.fireFetchData()
    }


    function handleReportSettingToggle(e) {
        updateUserSetting('reportSYFilter', e.target.checked)
    }


    function rowHandlers(state, rowInfo, column, instance) {
        return {
            onClick: e => {
                history.push('/admin/' + props.catUrlSlug +  '/' + rowInfo.original.id + '/edit')
            },
            style: {
                cursor: 'pointer'
            }
        }
    }


    function storeItemsTab(tabNum) {

        const columns = [
            {
                Header: 'Name',
                accessor: 'display_name',
                minWidth: 250,
            },
            {
                Header: 'Date Created',
                accessor: 'create_time'
            },
        ]

        // My Fees and All Fees
        if (tabNum === 0 || tabNum === 3) {
            columns.push(
                {
                    Header: 'Approval Status',
                    accessor: 'approval_status'
                }
            )
        }
        // Needs Approval and Approved and All Fees
        if (tabNum === 1 || tabNum === 2 || tabNum === 3) {
            columns.push(
                {
                    Header: "Requester's First Name",
                    accessor: 'owner.first_name'
                },
                {
                    Header: "Requester's Last Name",
                    accessor: 'owner.last_name'
                },
            )
        }

        return (
            <TabContainer>
                <ReactTable
                    ref={reactTable}
                    data={storeItemList}
                    columns={columns}
                    pages={pages}
                    defaultPageSize={10}
                    filterable={true}
                    loading={tableLoading}
                    LoadingComponent={ReactTablePreloader}
                    onFetchData={handleOnFetchData}
                    getTrProps={rowHandlers}
                    className='-highlight'
                    manual
                    tabNum={tabNum}
                />
            </TabContainer>
        )
    }

    const switchFormLabelStyle = {
        color: 'white',
        paddingLeft: '1em',
        paddingTop: '.5em'
    }


    return (
        <Paper
            className={classes.pagePaper}
        >
            <Paper className={classes.pageSubPaper}>
            {(staffCanCreate || currentUser.isSuperAdmin) &&
                <div>
                    <Link to={'/admin/' + props.catUrlSlug + '/new'}>
                        <Button
                            variant='contained'
                            color='primary'
                        >
                            {'Create New ' + props.catSingular}
                        </Button>
                    </Link>
                    <br /><br />
                </div>}
            <AppBar position='static'>
                {props.catEnum === 'donations' &&
                    <FormControlLabel
                        control={
                            <MUISwitch
                                checked={userSettings.showPastDonations}
                                onChange={handleSettingToggle}
                            />
                        }
                        label='Show Past/Closed Donations'
                        style={switchFormLabelStyle}
                    />
                }
                {props.catEnum === 'fees' &&
                    <FormControlLabel
                        control={
                            <MUISwitch
                                checked={userSettings.showPastFees}
                                onChange={handleSettingToggle}
                            />
                        }
                        label='Show Past/Closed Fees'
                        style={switchFormLabelStyle}
                    />
                }
                {props.catEnum === 'activities' &&
                    <FormControlLabel
                        control={
                            <MUISwitch
                                checked={userSettings.showPastActivities}
                                onChange={handleSettingToggle}
                            />
                        }
                        label='Show Past/Closed Activities'
                        style={switchFormLabelStyle}
                    />
                }
                <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
                    <Tab label={'My ' + props.catPlural} style={{ display: staffCanCreate ? '' : 'none' }} />
                    <Tab label='Needs Approval' style={{ display: staffCanApprove ? '' : 'none' }} />
                    <Tab label='Approved' style={{ display: staffCanApprove ? '' : 'none' }} />
                    <Tab
                        label={'All ' + props.catPlural}
                        style={{ display: (currentUser.super_admin_grants.length === 1 || staffCanReport) ? '' : 'none' }}
                    />
                </Tabs>
            </AppBar>
            {tab === 0 && storeItemsTab(0)}
            {tab === 1 && storeItemsTab(1)}
            {tab === 2 && storeItemsTab(2)}
            {tab === 3 && storeItemsTab(3)}
            </Paper>
            {(props.catEnum === 'activities' || props.catEnum === 'fees') &&
                <Paper
                    className={classes.pageSubPaper}
                >
                    <Typography>
                        Report: Unpaid {props.catPlural}
                    </Typography>
                    <br />
                    <FormControlLabel
                        control={
                            <MUISwitch
                                checked={userSettings.reportSYFilter}
                                onChange={handleReportSettingToggle}
                            />
                        }
                        label={props.catPlural + ' created this school year only'}
                    />
                    <br />
                    <a href={unpaidReportHref} download>
                        <Button variant='contained' color='secondary'>
                            Export CSV: Unpaid {props.catPlural}
                        </Button>
                    </a>
                </Paper>
            }
        </Paper>
    )
}