import React from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import { SchoolContext } from 'components/SchoolProvider.js'
import { UserContext } from 'components/UserProvider.js'
import { getStoreItem, createStoreItem, updateStoreItem } from 'api/storeitems'
import { make_filter_params, make_params_from_react_table } from 'api/base.js'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant'
import { useHistory, Link } from "react-router-dom";
import Preloader from 'components/Preloader/Preloader.js'
import { useSnackbar } from 'notistack';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty'
import { SubmitButton } from 'components/CustomButtons/Button.js'
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js'
import { FormGroup, TextField, FormControl, InputLabel, Input, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import tinymce from 'tinymce/tinymce'
//import 'tinymce/themes/silver'
//import 'tinymce/plugins/paste';
//import 'tinymce/plugins/link';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import ReactTablePreloader from 'components/Preloader/ReactTablePreloader';
import { listStudents } from 'api/students.js'
import remove from 'lodash/remove'
import { MaterialNumberFormat } from 'components/CustomInput/NumberFormat.js'
import TinyMCE from 'components/CustomInput/TinyMCE.js'
import StudentSelection from 'components/StudentSelection/StudentSelection'
import StoreItemApproverSelect from 'components/CustomInput/StoreItemApproverSelect'
import UserFormSelect from 'components/CustomInput/UserFormSelect'
import StaffSelect from 'components/CustomInput/StaffSelect'
import { getStaff } from 'api/staff';
import * as moment from 'moment'
import some from 'lodash/some';
import { api_base } from 'api/base'
import { approveStoreItem } from 'api/storeitems';
import StoreItemPayoutMethodSelect from 'components/CustomInput/StoreItemPayoutMethodSelect';


const useStyles = makeStyles(styles)


export default function EditDonation(props) {
    const classes = useStyles()
    const currentSchool = React.useContext(SchoolContext)[0]
    const currentUser = React.useContext(UserContext)[0]
    const [loading, setLoading] = React.useState(true);
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [donation, setDonation] = React.useState({});
    const [staffCanApprove, setStaffCanApprove] = React.useState(false)
    const [focusedInput, setFocusedInput] = React.useState(null);
    let history = useHistory();

    const { enqueueSnackbar } = useSnackbar();


    // Determine if we are creating or editing
    var editing = false;
    var donationId = '';
    if (props.location.pathname.split('/')[4] === 'edit') {
        editing = true;
        donationId = props.location.pathname.split('/')[3];
    }


    const [formState, dispatch, basicInputOnChange, onChange] = ControlledInputInternals();

    // Inputs
    const { display_name, description, open_time, close_time,
        cost, approver_id, form_id, owner_id, category_type, allow_more, is_public, payout_method_id } = formState;
    
    const inputs = ['display_name', 'description', 'open_time', 'close_time',
        'cost', 'approver_id', 'form_id', 'owner_id', 'category_type', 'allow_more', 'is_public', 'payout_method_id']


    // Initialize page
    React.useEffect(() => {
        async function initPage() {
            if (editing) {
                let r = await getStoreItem(currentSchool.value, donationId)
                if (r.ok) {
                    setDonation(r.data)
                    inputs.map(key => {
                        if (['open_time', 'close_time'].includes(key)) {
                            r.data[key] = (r.data[key] !== null ? moment(r.data[key]) : null)
                        }
                        dispatch({field: key, value: r.data[key]})
                    })
                    for (const key in r.data.category_fields) {
                        dispatch({ field: key, value: r.data.category_fields[key] })
                    }
                    setLoading(false)
                }
                else {
                    window.location = '/admin'
                }
            }
            else {
                setLoading(false)
            }
        }
        initPage()
    }, [])


    React.useEffect(() => {
        // Handle case where currentUser is not staff for currentSchool
        const pem = !currentUser.isSchoolStaff
            ? false : currentUser.school_staff_grants_mapping.approve_store_item_requests
        setStaffCanApprove(pem)
    }, [currentUser])


    async function handleApproveDonation() {
        setSubmitting(true)
        const r = await approveStoreItem(
            currentSchool.value,
            donationId
        )
        if (!r.ok) {
            enqueueSnackbar('An error occurred', { variant: 'error' })
            setSubmitting(false)
            return
        }
        setDonation(r.data)
        setSubmitting(false)
        enqueueSnackbar('Donation Approved', { variant: 'success' })
    }


    async function handleUpdateDonation(e) {
        e.preventDefault()
        let form = document.querySelector('form')
        if (!form.reportValidity()) {
            return
        }
        setSubmitting(true)
        setSuccess(false)

        let r = null
        let snackbarText = ''
        if (editing) {
            delete formState.allow_more
            r = await updateStoreItem(
                currentSchool.value,
                donationId,
                {store_item:
                    {...formState, category_fields: {allow_more: allow_more}}
                }
            )
            snackbarText = 'updated'
        }
        else {
            delete formState.allow_more
            r = await createStoreItem(
                currentSchool.value,
                {store_item:
                    {...formState,
                        category_type: 'donations',
                        category_fields: {allow_more: allow_more}}
                }
            )
            snackbarText = 'created'
        }
        setSubmitting(false)
        if (r.ok) {
            enqueueSnackbar('Donation ' + snackbarText, {variant: 'success'})
            setDonation(r.data)
            setSuccess(true)
            if(!editing) {
                history.push('/admin/donations/' + r.data.id + '/edit')
            }
        }
        else {
            enqueueSnackbar('An error occurred', { variant: 'error' })
        }
    }


    if (loading) {
        return (null)
    }

    return (
        <Paper
            className={classes.pagePaper}
        >
            <Grid
                container
                direction='row'
                justify='flex-end'
                alignItems='center'
            >
                {(donation.approval_status === 'pending' && (staffCanApprove || currentUser.super_admin_grants.length === 1)) &&
                    <SubmitButton
                        submitting={submitting}
                        success={null}
                        buttonText={'Approve Donation'}
                        onClick={handleApproveDonation}
                        noSubmitType={true}
                        color='default'
                    />
                }
                <a href={api_base + '/schools/' + currentSchool.value + '/store_items/' + donationId + '/reports/purchases'} download>
                    <Button variant='contained' color='secondary'>
                        Export CSV: Purchases Report
                        </Button>
                </a>
            </Grid>
            <form>
                <TextField
                    id='display_name'
                    label='Name'
                    variant='outlined'
                    value={display_name}
                    onChange={basicInputOnChange}
                    margin='normal'
                    required
                />
                <br /><br />
                {!(currentUser.super_admin_grants.length === 1) ? null : (
                    <>
                        <Typography>Select a staff member to own this fee</Typography>
                        <StaffSelect
                            id='owner_id'
                            value={owner_id}
                            onChange={onChange}
                        />
                        <br /> <br />
                    </>
                )}
                <TinyMCE
                    id='description'
                    value={description}
                    label='Description'
                    onChange={onChange}
                    loading={loading}
                />
                <br /><br />
                <Typography>Make the donation available for a limited time (optional)</Typography>
                <br />
                <DateRangePicker
                    startDate={open_time}
                    startDateId='open_time'
                    endDate={close_time}
                    endDateId='close_time'
                    onDatesChange={({ startDate, endDate }) => {
                        onChange('open_time', startDate);
                        onChange('close_time', endDate);
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                />
                <br /><br />
                <MaterialNumberFormat
                    id='cost'
                    value={cost}
                    onChange={basicInputOnChange}
                    label='Amount'
                    required
                    variant='outlined'
                />
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={allow_more}
                                onChange={basicInputOnChange}
                                value='allow_more'
                                id='allow_more'
                                color='primary'
                            />
                        }
                        label='Allow more than the listed price'
                    />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={is_public}
                                onChange={basicInputOnChange}
                                value='is_public'
                                id='is_public'
                                color='primary'
                            />
                        }
                        label='Make this donation available to the general public.'
                    />
                </FormGroup>
                <br />
                <UserFormSelect
                    id='form_id'
                    label='Custom Form'
                    value={form_id}
                    onChange={onChange}
                />
                {(staffCanApprove || currentUser.super_admin_grants.length === 1) ? null : (
                    <>
                        <br /> <br />
                        <Typography>Select an approver</Typography>
                        <br />
                        <StoreItemApproverSelect
                            id='approver_id'
                            value={approver_id}
                            onChange={onChange}
                        />
                    </>
                )}
                {(staffCanApprove || currentUser.super_admin_grants.length === 1) && (
                    <>
                        <br /><br />
                        <StoreItemPayoutMethodSelect
                            id='payout_method_id'
                            value={payout_method_id}
                            onChange={onChange}
                            storeItemId={donationId}
                        />
                    </>
                )}
                <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                >
                    <SubmitButton
                        submitting={submitting}
                        success={success}
                        buttonText={editing ? 'Update Donation' : 'Create Donation'}
                        onClick={handleUpdateDonation}
                    />
                </Grid>
            </form>
        </Paper>
    )
}