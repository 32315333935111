import React from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import { SchoolContext } from 'components/SchoolProvider.js'
import { UserContext } from 'components/UserProvider.js'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import DayPicker, { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { Box, Grid, IconButton, Switch, FormGroup, FormControl, FormControlLabel, RadioGroup, Radio, FormLabel, TextField, InputLabel, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import Select from 'react-select'
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ControlledInputInternals } from 'components/CustomInput/CustomInput';
import { listStoreGroups, getStoreGroup } from 'api/storegroups';
import { make_filter_params } from 'api/base';
import { useSnackbar } from 'notistack';
import { useHistory, Link } from 'react-router-dom';
import { make_params_from_react_table } from 'api/base';
import ReactTablePreloader from 'components/Preloader/ReactTablePreloader';
import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { SubmitButton } from 'components/CustomButtons/Button'
import { updateStoreGroup } from 'api/storegroups';
import moment from 'moment';
import { api_base } from 'api/base';
import { MaterialNumberFormat } from 'components/CustomInput/NumberFormat';
import NumberFormat from 'react-number-format';
import  {getSchoolLunchesSettings, updateSchoolLunchesSettings } from 'api/schoollunchessettings'
import Preloader from 'components/Preloader/Preloader';
import { getSchool } from 'api/school';
import { listStoreItems } from 'api/storeitems';
import { ContactSupportOutlined } from '@material-ui/icons';
import { getSchoolSettings, updateSchoolSettings } from 'api/schoolsettings'


const useStyles = makeStyles(styles);


export default function ManageSchoolPage() {
    const classes = useStyles()
    const [currentSchool, setCurrentSchool] = React.useContext(SchoolContext)
    const [schoolSettings, setSchoolSettings] = React.useState({})
    const [loading, setLoading] = React.useState(true)
    const [submitting, setSubmitting] = React.useState(false)
    const [success, setSuccess] = React.useState(false)


    const [formState, dispatch, basicInputOnChange, onChange] = ControlledInputInternals()


    const { enqueueSnackbar } = useSnackbar();

    const inputs = ['school_year_start', 'school_year_end', 'term_id']

    
    React.useEffect(() => {
        async function init() {
            const r = await getSchoolSettings(currentSchool.value)
            if (!r.ok) {
                // Ignore 404 as settings are not created for each school automatically
                if (r.status != 404) {
                    enqueueSnackbar('An error occurred.', {variant: 'error'})
                    setLoading(false)
                    return
                }             
            }
            setSchoolSettings(r.data)
            inputs.map(key => {
                // if (['school_year_start', 'school_year_end'].includes(key)) {
                //     r.data[key] = (r.data[key] !== null ? moment(r.data[key]) : null)
                // }
                console.log(key, r.data[key])
                dispatch({field: key, value: r.data[key]})
            })
            setLoading(false)
        }
        init()
    }, [currentSchool])


    async function handleUpdateSettings (e) {
        e.preventDefault()
        const form = document.querySelector('form')
        if (!form.reportValidity()) {
            return
        }
        setSubmitting(true)
        setSuccess(false)
        const r = await updateSchoolSettings(
            currentSchool.value,
            formState
        )
        setSubmitting(false)
        if (r.ok) {
            enqueueSnackbar('Settings Updated', {variant: 'success'})
            setSchoolSettings(r.data)
            setSuccess(true)
        }
        else {
            enqueueSnackbar('An error occurred', { variant: 'error' })
        }
    }

    
    if (loading) { return <Preloader />}

    return (
        <Paper className={classes.pagePaper}>
            <form>
            <Paper className={classes.pageSubPaper}>
                <Typography
                    style={{fontSize: 20, paddingBottom: '1em'}}
                >
                    Current School Year
                </Typography>
                <Typography>Start and End Date</Typography>
                <Grid container spacing={1}>
                    <Grid item>
                        <DayPickerInput
                            //component={props => <TextField variant='outlined' {...props}/>}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            placeholder='mm/dd/yyy'
                            onDayChange={(day) => onChange('school_year_start', day)}
                            dayPickerProps={{
                                selectedDays: formState.school_year_start
                            }}
                            value={formState.school_year_start ? moment(formState.school_year_start).format('MM/DD/YYYY') : null}
                        />
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon
                            icon={faLongArrowAltRight}
                        />
                    </Grid>
                    <Grid item>
                        <DayPickerInput
                            //component={props => <TextField variant='outlined' {...props}/>}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            placeholder='mm/dd/yyy'
                            onDayChange={(day) => onChange('school_year_end', day)}
                            dayPickerProps={{
                                selectedDays: formState.school_year_end
                            }}
                            value={formState.school_year_end ? moment(formState.school_year_end).format('MM/DD/YYYY') : null}

                        />
                    </Grid>
                </Grid>
                <br /><br />
                {/* <TextField
                    id='term_id'
                    label='SIS Term ID'
                    value={formState.term_id}
                    onChange={basicInputOnChange}
                    variant={'outlined'}
                /> */}
            </Paper>
            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
            >
                <SubmitButton
                    submitting={submitting}
                    success={success}
                    buttonText={'Update Settings'}
                    onClick={handleUpdateSettings}
                />
            </Grid>
            </form>

        </Paper>
    )
}