import { api_call, make_response_object } from 'api/base'


export async function listSchools(params) {
    const r = await api_call('/schools', 'GET', {}, params)
    return await make_response_object(r)
}


export async function getSchool(schoolId) {
    const r = await api_call('/schools/' + schoolId, 'GET')
    return await make_response_object(r)
}


export async function createSchool(body) {
    const r = await api_call(
        '/schools',
        'POST',
        body
    )
    return await make_response_object(r)
}


export async function updateSchool(schoolId, body) {
    const r = await api_call(
        '/schools/' + schoolId,
        'PUT',
        body
    )
    return await make_response_object(r)
}


export async function verifySchool(schoolId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/verify',
        'PATCH',
        body
    )
    return await make_response_object(r)
}


export async function listStoreItemApprovers(schoolId, params) {
    const r = await api_call('/schools/' + schoolId + '/store_item_approvers', 'GET', {}, params)
    return await make_response_object(r)
}