import { api_call, make_response_object } from 'api/base'


export async function listStaff(schoolId, params) {
    const r = await api_call('/schools/' + schoolId + '/staff', 'GET', {}, params)
    return await make_response_object(r)
}


export async function getStaff(schoolId, staffId) {
    const r = await api_call('/schools/' + schoolId + '/staff/' + staffId, 'GET')
    return await make_response_object(r)
}


export async function createStaff(schoolId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/staff',
        'POST',
        body
        )
    return await make_response_object(r)
}


export async function updateStaff(schoolId, staffId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/staff/' + staffId,
        'PUT',
        body
        )
    return await make_response_object(r)
}


export async function deleteStaff(schoolId, staffId) {
    const r = await api_call(
        '/schools/' + schoolId + '/staff/' + staffId,
        'DELETE',
    )
    return await make_response_object(r)
}


export async function listStaffCourseSections(schoolId, staffId, params) {
    const r = await api_call(
        '/schools/' + schoolId + '/staff/' + staffId + '/course_sections',
        'GET',
        {},
        params
        )
    return await make_response_object(r)
}