import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { login, recover, getUserFromPasswordResetToken, resetPassword } from 'api/auth'
import { SubmitButton } from 'components/CustomButtons/Button.js'
import { useSnackbar } from 'notistack';
import { UserContext } from 'components/UserProvider.js'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import PasswordStrengthField from 'components/CustomInput/PasswordStrengthField'
import { ControlledInputInternals } from 'components/CustomInput/CustomInput';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <a href='https://www.chartersuccesspartners.com/'>
        Charter Success Partners
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://admin.charterpay.org/logo.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50%',
    backgroundPosition: 'center',
    backgroundColor: 'rgba(0, 26, 51,1)'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



export default function Login(props) {
  const classes = useStyles();
  const [user, setUser] = React.useContext(UserContext)
  const [submitting, setSubmitting] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(null)
  const [pwResetUser, setPWResetUser] = React.useState({})
  const [loading, setLoading] = React.useState(false)

  const { enqueueSnackbar } = useSnackbar();

  const [formState, dispatch, onChange] = ControlledInputInternals();

  let view = null
  if (props.location.pathname.split('/').length === 2) {
    view = 'login'
  }
  else if (props.location.pathname.split('/').length === 3) {
    view = 'recovery'
  }
  else {
    view = 'reset'
  }


  React.useEffect(() => {
    setSuccess(false)

    if (view === 'reset') {
      async function initPage() {
        setLoading(true)

        const r = await getUserFromPasswordResetToken({
          token: props.location.pathname.split('/')[3]
        })
        if (!r.ok) {
          enqueueSnackbar(r.data.text, { variant: 'error' })
          console.log(r.data)
          setLoading(false)
          return
        }
        setPWResetUser(r.data)
        setLoading(false)

      }
      initPage()
    }

  }, [view])


  async function handleLogin(event) {
    event.preventDefault();
    let form = document.querySelector('form')
    if (!form.reportValidity()) {
      return
    }
    setSubmitting(true)
    setSuccess(false)
    var formData = new FormData(document.querySelector('form'))
    var body = JSON.stringify(Object.fromEntries(formData));
    var that = this;
    var r = await login(body);
    if (r.status === 200) {
      localStorage.setItem('appUser', JSON.stringify(r.data))
      setUser(r.data)
      that.history.push('/admin/dashboard')
      setSubmitting(false)
      return
    }
    setSubmitting(false)
    enqueueSnackbar('Login failed.', {variant: 'error'})
  }


  async function handleRecover(e) {
    e.preventDefault()
    let form = document.querySelector('form')
    if (!form.reportValidity()) {
      return
    }
    setSubmitting(true)
    setSuccess(false)
    const r = await recover({
      email: document.getElementById('email').value,
      portal: (window.location.hostname.includes('admin') ? 'admin' : 'storefront')
    })
    if (!r.ok) {
      enqueueSnackbar(r.data.text, { variant: 'error' })
      setSubmitting(false)
      console.log(r.data)
      return
    }
    setSubmitting(false)
    setSuccess(true)
  }


  async function handleReset(e) {
    e.preventDefault()
    let form = document.querySelector('form')
    if (!form.reportValidity()) {
      return
    }
    if (passwordError) {
      enqueueSnackbar(passwordError, { variant: 'error' })
      return
    }

    setSubmitting(true)
    setSuccess(false)

    const r = await resetPassword({
      token: props.location.pathname.split('/')[3],
      password: formState.password
    })
    if (!r.ok) {
      enqueueSnackbar(r.data.text, { variant: 'error' })
      console.log(r.data)
      setLoading(false)
      return
    }

    setSubmitting(false)
    setSuccess(true)
  }


  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          {view === 'login' &&
          <>
          <Avatar className={classes.avatar}>
            <FontAwesomeIcon icon={faLock} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleLogin.bind(props)}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/*<FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />*/}
            <SubmitButton
              submitting={submitting}
              success={success}
              buttonText='Sign In'
              fullWidth
            />
            <Grid container>
              <Grid item xs>
                <Link to='/login/recovery' variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
          </>
          }
          {view === 'recovery' &&
            <>
            <Avatar className={classes.avatar}>
              <FontAwesomeIcon icon={faUnlock} />
            </Avatar>
            <Typography component="h1" variant="h5">
              Recover Account
            </Typography>
            {success === false &&
            <form className={classes.form} onSubmit={handleRecover}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <SubmitButton
                submitting={submitting}
                success={success}
                buttonText='Send me a password reset email'
                fullWidth
              />
            </form>
            }
            {success === true &&
              <>
              <br/>
              <Typography>
                A password reset email has been sent. Please check your email and follow the instructions in the message.
              </Typography>
              <br/>
              <Link to='/login'>
                <Button
                  variant='contained'
                >
                  Back to Login Page
                </Button>
              </Link>
              </>
            }
            </>
          }
          {view === 'reset' &&
            <>
            <Avatar className={classes.avatar}>
              <FontAwesomeIcon icon={faUnlock} />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Your Password
            </Typography>
            {success === false &&
            <form className={classes.form} onSubmit={handleReset}>
              <PasswordStrengthField
                name='password'
                label='Password'
                id='password'
                value={formState.password}
                onChange={onChange}
                userInputs={[pwResetUser.email, pwResetUser.first_name, pwResetUser.last_name]}
                confirmName='password2'
                confirmLabel='Confirm Password'
                confirmId='password2'
                confirmValue={formState.password2}
                confirmOnChange={onChange}
                error={passwordError}
                setError={setPasswordError}
              />
              <SubmitButton
                submitting={submitting}
                success={success}
                buttonText='Reset Your Password'
                fullWidth
              />
            </form>
            }
            {success === true &&
              <>
                <br />
                <Typography>
                  Your password has been successfully reset.
              </Typography>
                <br />
                <Link to='/login'>
                  <Button
                    variant='contained'
                  >
                    Back to Login Page
                </Button>
                </Link>
              </>
            }
            </>
          }
        </div>
      </Grid>
    </Grid>
  );
}