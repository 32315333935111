import React from 'react'
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js'



export const FilterStateContext = React.createContext()
export function FilterStateProvider(props) {
  const [ filterState, dispatch, basicInputOnChange, onChange ] = ControlledInputInternals();
  

  return (
    <FilterStateContext.Provider value={[filterState, basicInputOnChange, onChange]}>
      {props.children}
    </FilterStateContext.Provider>
  )
}