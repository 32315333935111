import { api_call, make_response_object } from 'api/base'


export async function getSchoolLunchesSettings(schoolId) {
    const r = await api_call(
        '/schools/' + schoolId + '/school_lunches_settings',
        'GET'
    )
    return await make_response_object(r)
}


export async function updateSchoolLunchesSettings(schoolId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/school_lunches_settings',
        'PUT',
        body
    )
    return await make_response_object(r)
}