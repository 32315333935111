import React from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import { SchoolContext } from 'components/SchoolProvider.js'
import { listStudents } from 'api/students.js'
import { make_params_from_react_table } from 'api/base.js'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant'
import { useHistory, Link } from "react-router-dom";
import ReactTablePreloader from 'components/Preloader/ReactTablePreloader.js';


const useStyles = makeStyles(styles);


export default function StudentsPage() {
    const classes = useStyles();
    const [currentSchool, setCurrentSchool] = React.useContext(SchoolContext)
    const [studentList, setStudentList] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [pages, setPages] = React.useState(0)
    const reactTable = React.createRef();
    let history = useHistory();

    React.useEffect(() => {
        const params = make_params_from_react_table(reactTable.current.state)
        listStudents(currentSchool.value, params)
            .then(r => {
                setStudentList(r.data.results)
                setPages(r.data.pages)
                setLoading(false)
            })
    }, [currentSchool]) // Only fire when currentSchool changes

    // Debounce listStudents API call so it doesn't fire on every filter keystroke
    const listStudentsDebounced = useConstant(() =>
        AwesomeDebouncePromise(
            listStudents,
            400
        )
    );

    async function handleOnFetchData(state, instance) {
        // Don't fire on initial page load. Intial data load is handled in useEffect
        if (!loading) {
            setLoading(true)
            const params = make_params_from_react_table(state)
            const r = await listStudents(currentSchool.value, params)
            setStudentList(r.data.results)
            setPages(r.data.pages)
            //setCurrentPage()
            setLoading(false)
        }
    }

    function rowHandlers(state, rowInfo, column, instance) {
        return {
            onClick: e => {
                history.push('/admin/students/' + rowInfo.original.id + '/edit')
            },
            style: {
                cursor: 'pointer'
            }
        }
    }

    const columns = [
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'Grade Level',
            accessor: 'grade_level',
        },
    ]

    return (
        <Paper
            className={classes.pagePaper}
        >
            <Link to='/admin/students/new'>
                <Button
                    variant='contained'
                    color='primary'
                >
                    Create New Student
                </Button>
            </Link>
            <br /><br />
            <ReactTable
                ref={reactTable}
                data={studentList}
                columns={columns}
                pages={pages}
                //page={currentPage}
                defaultPageSize={10}
                filterable={true}
                loading={loading}
                LoadingComponent={ReactTablePreloader}
                onFetchData={handleOnFetchData}
                getTrProps={rowHandlers}
                className='-highlight'
                manual
            />
        </Paper>
    )
}