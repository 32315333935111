import React from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import { SchoolContext } from 'components/SchoolProvider.js'
import { UserContext } from 'components/UserProvider.js'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import DayPicker, { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { Box, Grid, IconButton } from '@material-ui/core'
import Select from 'react-select'
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ControlledInputInternals } from 'components/CustomInput/CustomInput';
import { listStoreGroups, getStoreGroup } from 'api/storegroups';
import { make_filter_params } from 'api/base';
import { useSnackbar } from 'notistack';
import { useHistory, Link } from 'react-router-dom';
import { make_params_from_react_table } from 'api/base';
import ReactTablePreloader from 'components/Preloader/ReactTablePreloader';
import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { SubmitButton } from 'components/CustomButtons/Button'
import { updateStoreGroup } from 'api/storegroups';
//import moment from 'moment';
import moment from 'moment-timezone'
import Preloader from 'components/Preloader/Preloader';
import { getStaff } from 'api/staff';
import { listPayouts } from 'api/payouts'
import isEmpty from 'lodash/isEmpty';
import { api_base } from 'api/base'
import { DateRangePicker } from 'react-dates';
import { isInclusivelyBeforeDay } from 'react-dates'
import MultiPaidReport from '../../components/Reports/MultiPaid';
import { getCookie, expireCookie, ReportPreloader } from 'components/Preloader/ReportPreloader'
import { listAccountingReports } from 'api/accountingsreport';


const useStyles = makeStyles(styles);

export default function ReportsPage() {
    const classes = useStyles();
    const currentSchool = React.useContext(SchoolContext)[0]
    const currentUser = React.useContext(UserContext)[0]
    const [loading, setLoading] = React.useState(true)
    const [staffCanViewReports, setStaffCanViewReports] = React.useState(false)
    const [payouts, setPayouts] = React.useState([])
    const [pagesPayouts, setPagesPayouts] = React.useState(0)
    const [acctReports, setAcctReports] = React.useState([])
    const [pagesAcct, setPagesAcct] = React.useState(0)
    const reactTablePayout = React.createRef();
    const reactTableAcct = React.createRef()
    const [refundsStart, setRefundsStart] = React.useState(null)
    const [refundsEnd, setRefundsEnd] = React.useState(null)
    const [focusedInput, setFocusedInput] = React.useState(null);
    const [refundReportUrl, setRefundReportUrl] = React.useState('')
    const [tableLoadingPayouts, setTableLoadingPayouts] = React.useState(true);
    const [tableLoadingAcct, setTableLoadingAcct] = React.useState(true)
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [downloadToken, setDownloadToken] = React.useState(new Date().getTime().toString())



    const { enqueueSnackbar } = useSnackbar();


    React.useEffect(() => {
        async function init() {
            setLoading(true)
            setTableLoadingPayouts(true)
            if (staffCanViewReports || currentUser.super_admin_grants.length === 1) {
                const params = {page: 1, per_page: 10}
                const r = await listPayouts(currentSchool.value, params)
                if (!r.ok) {
                    enqueueSnackbar('An error occurred loading payouts.', {variant: 'error'})
                }
                else {
                    setPayouts(r.data.results)
                    setPagesPayouts(r.data.pages)
                }
                const r1 = await listAccountingReports(currentSchool.value, params)
                if (!r1.ok) {
                    enqueueSnackbar('An error occurred accounting reports.', {variant: 'error'}) 
                }
                else {
                    setAcctReports(r1.data.results)
                    setPagesAcct(r1.data.pages)
                }
            }
            setLoading(false)
            setTableLoadingPayouts(false)
            setTableLoadingAcct(false)
        }
        init()
    }, [currentSchool, staffCanViewReports])


    React.useEffect(() => {
        async function checkPem() {
            const r = await getStaff(currentSchool.value, currentUser.id)
            const pem = (isEmpty(r.data))
                ? false : r.data.school_staff_grants_mapping.create_share_reports
            setStaffCanViewReports(pem)
        }
        checkPem()
    }, [currentUser, currentSchool])


    React.useEffect(() => {
        let url = new URL('/schools/' + currentSchool.value + '/reports/refunds', api_base)
        let params = {}

        if (refundsStart) {
            params['refund_initiated_time[get]'] = refundsStart.toISOString()
        }
        if (refundsEnd) {
            params['refund_initiated_time[let]'] = refundsEnd.toISOString()
        }
        url.search = new URLSearchParams(params).toString()
        
        console.log(url.toString())
        setRefundReportUrl(url.toString())
        
    },[refundsStart, refundsEnd])


    function handleReportClick() {
        setDialogOpen(true)
        const downloadTimer = window.setInterval(() => {
            const cookieToken = getCookie('downloadToken')
            if (cookieToken === downloadToken) {
                window.clearInterval(downloadTimer)
                expireCookie('downloadToken')
                setDialogOpen(false)
                setDownloadToken(new Date().getTime().toString())
            }
        }, 500)
    }


    async function handleOnFetchDataPayouts(state) {
        // Don't fire on initial page load. Intial data load is handled in useEffect
        if (!loading) {
            setTableLoadingPayouts(true)
            const params = make_params_from_react_table(state)
            const r = await listPayouts(currentSchool.value, params)
            setPayouts(r.data.results)
            setPagesPayouts(r.data.pages)
            setTableLoadingPayouts(false)
        }
    }


    async function handleOnFetchDataAcct(state) {
        if (!loading) {
            setTableLoadingAcct(true)
            const params = make_params_from_react_table(state)
            const r = await listAccountingReports(currentSchool.value, params)
            setAcctReports(r.data.results)
            setPagesAcct(r.data.pages)
            setTableLoadingAcct(false)
        }
    }

    const columnsPayouts = [
        {
            Header: 'Date',
            accessor: 'create_time',
            Cell: ({row})=>(<a href={api_base + '/schools/' + currentSchool.value + '/payouts/' + row._original.id + '/reports/purchases' + '?downloadToken=' + downloadToken} onClick={handleReportClick}>{moment(row.create_time).format('MM/DD/YYYY')}</a>)
        },
        {
            Header: 'Status',
            accessor: 'status'
        },
        {
            Header: 'Bank Name',
            accessor: 'payout_method.bank_name'
        },
        {
            Header: 'Account Last Four',
            accessor: 'payout_method.last_four'
        }
    ]

    const columnsAcct = [
        {
            Header: 'Date',
            accessor: 'create_time',
            Cell: ({row})=>(<a href={api_base + '/schools/' + currentSchool.value + '/reports/accounting_report/' +  row._original.id + '?downloadToken=' + downloadToken} onClick={handleReportClick}>{moment(row.create_time).format('MM/DD/YYYY')}</a>)
        },
        {
            Header: 'Bank Name',
            accessor: 'payout_method.bank_name'
        },
        {
            Header: 'Account Last Four',
            accessor: 'payout_method.last_four'
        }

    ]
    


    if (loading) { return <Preloader />}

    return (
        <Paper
            className={classes.pagePaper}
        >
            {(staffCanViewReports || currentUser.super_admin_grants.length === 1) &&
                <>
                <Paper
                    className={classes.pageSubPaper}
                >
                    <Typography style={{fontSize: 20}}>
                        Accounting Reports
                    </Typography>
                    <br/>
                    <ReactTable
                        ref={reactTableAcct}
                        data={acctReports}
                        columns={columnsAcct}
                        pages={pagesAcct}
                        defaultPageSize={10}
                        filterable={false}
                        loading={tableLoadingAcct}
                        LoadingComponent={ReactTablePreloader}
                        onFetchData={handleOnFetchDataAcct}
                        manual
                    />
                    <br/>
                    <Typography style={{fontSize: 20}}>
                        Payout Reports
                    </Typography>
                    <br/>
                    <ReactTable
                        ref={reactTablePayout}
                        data={payouts}
                        columns={columnsPayouts}
                        pages={pagesPayouts}
                        defaultPageSize={10}
                        filterable={false}
                        loading={tableLoadingPayouts}
                        LoadingComponent={ReactTablePreloader}
                        onFetchData={handleOnFetchDataPayouts}
                        manual
                    />
                </Paper>
                <br /><br />
                </>
            }
            <Paper
                className={classes.pageSubPaper}
            >
                <Typography style={{ fontSize: 20 }}>
                    Refunds
                </Typography>
                <DateRangePicker
                    startDate={refundsStart}
                    endDate={refundsEnd}
                    onDatesChange={({ startDate, endDate}) => {
                        setRefundsStart(startDate)
                        setRefundsEnd(endDate)
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                    isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                    numberOfMonths={1}
                />
                <a href={refundReportUrl} download>
                    <Button variant='contained' color='primary'>
                        Export CSV: Refunds Report
                    </Button>
                </a>
            </Paper>
            <br /><br />
            <Paper
                className={classes.pageSubPaper}
            >
                <Typography style={{fontSize: 20}}>
                    Store Items
                </Typography>
                <Typography>
                    Store Item reports can be found on the Store Item pages
                </Typography>
                <br />
                <Link to={'/admin/reports/multi-activity-fee'}>
                <Button
                    variant='contained'
                    color='primary'
                >
                    Multiple Activity/Fee Student Report
                </Button>
                </Link>
            </Paper>
            <ReportPreloader
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
            />
        </Paper>
        
    )
}