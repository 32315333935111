/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import Select from 'react-select'
// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {listSchools} from 'api/school'

import { SchoolContext } from 'components/SchoolProvider.js'
import { UserContext } from 'components/UserProvider.js'
import { getStaff } from 'api/staff.js'
import isEmpty from "lodash/isEmpty";
import { getSchool } from "api/school";
import { listCategories } from "api/category";
import { make_filter_params } from "api/base";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  const [schools, setSchools] = React.useState([])
  //const [currentSchool, setcurrentSchool] = React.useState({})
  const [schoolsIsLoading, setschoolsIsLoading] = React.useState(true)
  const [categoriesAvail, setCategoriesAvail] = React.useState([])
  // const [stripeVerComplete, setStripeVerComplete] = React.useState(false)
  const [currentSchool, setcurrentSchool] = React.useContext(SchoolContext)
  const [user, setUser] = React.useContext(UserContext)
  const userIsSuperAdmin = (user.super_admin_grants.length == 1) ? true : false
  const schoolStaffGrantsMapping = user.school_staff_grants_mapping || {}


  React.useEffect(() => {
    const params = {per_page: 1000}
    listSchools(params)
      .then(r => {
        var apiSchools = r.data.results.map(s => {
          var rs = {};
          rs['value'] = s.id
          rs['label'] = s.display_name
          if (s.lunches_settings) {
            rs['lunches_settings'] = s.lunches_settings
          }
          else {
            rs['lunches_settings'] = { nslp_enabled: false }
          }
          return rs;
        })
        setSchools(apiSchools);
        // var cSchool = localStorage.getItem('currentSchool')
        // if (cSchool == null){
        //   cSchool = apiSchools[0];
        //   localStorage.setItem('currentSchool', JSON.stringify(cSchool));
        // } else {
        //   cSchool = JSON.parse(cSchool)
        // }
        // setcurrentSchool(cSchool);
        if (isEmpty(currentSchool)) {
          var school = r.data.results[0]
          setcurrentSchool(school)
          // setStripeVerComplete(r.data.results[0].stripe_verification_complete)
        }
        else {
          var school = r.data.results.filter(s => s.id == currentSchool.value)[0]
          // setStripeVerComplete(school.stripe_verification_complete)
          setcurrentSchool(school)
        }
        getStaffUser(school.id)
        setschoolsIsLoading(false);
      })
    }, []);


  React.useEffect(() => {
    async function handleSchoolChange() {
      if (currentSchool.hasOwnProperty('value')) {
        const filterParams = make_filter_params(
          [{ field: 'default_payout_method.stripe_verification_complete', op: 'eq', value: true}]
        )
        const categories = await listCategories(currentSchool.value, filterParams)
        let cA = []
        categories.data.results.forEach(c => {
          cA.push(c.category_type)
        })
        setCategoriesAvail(cA)
      }
  
    }

    handleSchoolChange()
  }, [currentSchool])

  async function getStaffUser(schoolId) {
    const r = await getStaff(schoolId, user.id)
    const isSchoolStaff = !isEmpty(r.data)
    var staffUser = user
    staffUser.isSchoolStaff = isSchoolStaff
    staffUser.isSuperAdmin = user.super_admin_grants.length === 1
    if (isSchoolStaff) {
      staffUser.school_staff_grants_mapping = r.data.school_staff_grants_mapping
    }
    localStorage.setItem('appUser', JSON.stringify(staffUser))
    setUser(staffUser)
  }

  async function handleSchoolChange(val) {
    const school = await getSchool(val.value)
    // setStripeVerComplete(school.data.stripe_verification_complete)
    setcurrentSchool(school.data);
    await getStaffUser(val.value)
    
  }

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, logoText, routes } = props;
  
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
        if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true
          });
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path)
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
        });
        if (prop.showInSidebar === false) {
          return(null)
        }
        if (prop.type === 'select') {
          return (
            <Select
              className={classes.itemLink + listItemClasses}
              options = {schools}
              isClearable={false}
              defaultValue={schools[0]}
              value={currentSchool}
              isLoading={schoolsIsLoading}
              onChange={handleSchoolChange}
            />
          )
        } else {
        
          if ((prop.superAdminOnly && userIsSuperAdmin) || (!prop.superAdminOnly)) {
            if ((prop.category && categoriesAvail.includes(prop.category)) || (!prop.category)) {
              if ((userIsSuperAdmin || (prop.permission && schoolStaffGrantsMapping[prop.permission])) || !prop.permission) {
            return (
              <NavLink
                to={prop.layout + prop.path}
                className={activePro + classes.item}
                activeClassName="active"
                key={key}
              >
                <ListItem button className={classes.itemLink + listItemClasses}>
                  <FontAwesomeIcon
                    icon={prop.icon}
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive
                    })}
                  />

                  
                  {// Was for use with Material Icons
                  /* {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(classes.itemIcon, whiteFontClasses, {
                        [classes.itemIconRTL]: props.rtlActive
                      })}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(classes.itemIcon, whiteFontClasses, {
                        [classes.itemIconRTL]: props.rtlActive
                      })}
                    />
                  )} */}


                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    className={classNames(classes.itemText, whiteFontClasses, {
                      [classes.itemTextRTL]: props.rtlActive
                    })}
                    disableTypography={true}
                  />
                </ListItem>
              </NavLink>
            );
            }
            }
            }
          }
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <NavLink
      to={'/admin/dashboard'}
      className={classNames(classes.logoLink, {
        [classes.logoLinkRTL]: props.rtlActive
      })}
      >
        
      
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </NavLink>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
