import React from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import { SchoolContext } from 'components/SchoolProvider.js'
import { UserContext } from 'components/UserProvider.js'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import DayPicker, { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { Box, Grid, IconButton, Input, FormControl, FormControlLabel, InputLabel, Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import Select from 'react-select'
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ControlledInputInternals } from 'components/CustomInput/CustomInput';
import { listSISImports, createSISImport, sendSISImportInvites } from 'api/sisimports'
import { make_filter_params } from 'api/base';
import { useSnackbar } from 'notistack';
import { useHistory, Link, Switch } from 'react-router-dom';
import { make_params_from_react_table } from 'api/base';
import ReactTablePreloader from 'components/Preloader/ReactTablePreloader';
import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { SubmitButton } from 'components/CustomButtons/Button'
import { updateStoreGroup } from 'api/storegroups';
import moment from 'moment';
import Preloader from 'components/Preloader/Preloader';
import MuiSwitch from '@material-ui/core/Switch';


export default function SISImportPage() {
    const currentSchool = React.useContext(SchoolContext)[0]
    const currentUser = React.useContext(UserContext)[0]
    const [loading, setLoading] = React.useState(true)
    const [importList, setImportList] = React.useState([])
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [invitesSubmitting, setInvitesSubmitting] = React.useState({})
    const [invitesSuccess, setInvitesSuccess] = React.useState({})
    const [importOptions, setImportOptions] = React.useState({})

    const { enqueueSnackbar } = useSnackbar();

    const sisOptions = [{ 'value': 'powerschool', 'label': 'Powerschool' },]
    const [sisType, setSisType] = React.useState(sisOptions[0])


    const importStatusMap = {
        'in_progress': 'In Progress',
        'succeeded': 'Succeeded',
        'failed': 'Failed',
    }


    React.useEffect(() => {
        async function initPage() {
            const r = await listSISImports(currentSchool.value)
            if (!r.ok) {
                enqueueSnackbar('An error occurred loading SIS imports.', {variant: 'error'})
                setLoading(false)
                return
            }

            setImportList(r.data.results)
            setLoading(false)
        }

        initPage()
    }, [currentSchool])


    async function handleCreateImport(e) {
        e.preventDefault()
        setSubmitting(false)
        setSuccess(false)
        
        const data = new FormData();

        if (sisType.value == 'powerschool') {
            const form = document.getElementById('powerschool-form')
            if (!form.reportValidity()) {
                return
            }

            const studentsFile = document.getElementById('students-file')
            const teachersFile = document.getElementById('teachers-file')
            const coursesFile = document.getElementById('courses-file')
            const sectionsFile = document.getElementById('sections-file')
            const enrollmentsFile = document.getElementById('enrollments-file')

            data.append('students', studentsFile.files[0], 'students')

            if (teachersFile.files.length === 1) {
                data.append('teachers', teachersFile.files[0], 'teachers')
            }
            if (coursesFile.files.length === 1) {
                data.append('courses', coursesFile.files[0], 'courses')
            }
            if (sectionsFile.files.length === 1) {
                data.append('sections', sectionsFile.files[0], 'sections')
            }
            if (enrollmentsFile.files.length === 1) {
                data.append('enrollments', enrollmentsFile.files[0], 'enrollments')
            }
        }

        const r = await createSISImport(currentSchool.value, data, {...importOptions, 'sis_type': sisType.value})
        if (!r.ok) {
            enqueueSnackbar('There was an error creating the import', {variant: 'error'})
            setSubmitting(false)
            setSuccess(false)
            return
        }
        enqueueSnackbar('Import queued.', { variant: 'success' })
        setSubmitting(false)
        setSuccess(true)
        return
    }


    async function handleSendInvites(sisImportId) {
        let iSub = {...invitesSubmitting}
        iSub[sisImportId] = true
        setInvitesSubmitting(iSub)

        let iSuc = {...invitesSuccess}
        iSuc[sisImportId] = false
        setInvitesSuccess(iSuc)

        const r = await sendSISImportInvites(currentSchool.value, sisImportId)
        if (!r.ok) {
            enqueueSnackbar('There was an error sending invites', { variant: 'error' })
            iSub = { ...invitesSubmitting }
            iSub[sisImportId] = false
            setInvitesSubmitting(iSub)
            return
        }
        enqueueSnackbar('Invites queued.', { variant: 'success' })

        iSub = { ...invitesSubmitting }
        iSub[sisImportId] = false
        setInvitesSubmitting(iSub)

        iSuc = { ...invitesSuccess }
        iSuc[sisImportId] = true
        setInvitesSuccess(iSuc)
    }


    function handleOptionsChange(e) {
        setImportOptions(
            {...importOptions, [e.target.name]: e.target.checked}
        )
    }


    if (loading) {
        return (<Preloader />)
    }

    return (
        <>
        <Typography style={{fontSize: 22}}>
            New Import
        </Typography>
        <br/>
        <Typography>
            Options
        </Typography>
        <Select
            id='sis_type'
            options={sisOptions}
            isClearable={false}
            value={sisType}
            onChange={(s) => setSisType(s)}
            placeholder='Select a SIS type...'
        />
      <FormControlLabel
        control={
          <MuiSwitch
            checked={importOptions.deleteStudents}
            onChange={handleOptionsChange}
            name="deleteStudents"
            color="primary"
          />
        }
        style={{marginBottom: '0px'}}
        label='Archive all students that do not appear in the import.'
      />
      <Typography style={{marginLeft: '2em'}}>
          Students are matched based on SIS ID. Manually added students with non-official SIS IDs will be archived if you use this option.
      </Typography>
        <br /><br />
        {sisType.value == 'powerschool' &&
            <>
                <form id='powerschool-form'>
                    <Typography>
                        Students File
                    </Typography>
                    <Input
                        id='students-file'
                        type='file'
                        required
                    />
                    <br /><br />
                    <Typography>
                        Teachers File
                    </Typography>
                    <Input
                        id='teachers-file'
                        type='file'
                    />
                    <br /><br />
                    <Typography>
                        Courses File
                    </Typography>
                    <Input
                        id='courses-file'
                        type='file'
                    />
                    <br /><br />
                    <Typography>
                        Course Sections File
                    </Typography>
                    <Input
                        id='sections-file'
                        type='file'
                    />
                    <br /><br />
                    <Typography>
                        Enrollments File
                    </Typography>
                    <Input
                        id='enrollments-file'
                        type='file'
                    />
                    <br /><br />
                    <Grid
                        container
                        direction='row'
                        justify='flex-end'
                        alignItems='center'
                    >
                        <SubmitButton
                            submitting={submitting}
                            success={success}
                            buttonText={'Upload'}
                            onClick={handleCreateImport}
                        />
                    </Grid>
                </form>
            </>
        }
        <Typography style={{fontSize: 22}}>
            Imports
        </Typography>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Time Started</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Account Invites</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {importList.map((i) => (
                        <TableRow key={i.id}>
                            <TableCell component='th' scope='row'>
                                {moment(i.create_time).format('LLL')}
                            </TableCell>
                            <TableCell>
                                {importStatusMap[i.status]}
                            </TableCell>
                            <TableCell>
                                {i.status === 'succeeded' &&
                                <SubmitButton
                                    submitting={invitesSubmitting[i.id]}
                                    success={invitesSuccess[i.id]}
                                    buttonText={'Send Account Invites'}
                                    onClick={() => handleSendInvites(i.id)}
                                />
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </>
    )
}