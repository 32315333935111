import React from 'react';
import StoreItemTabs from 'components/StoreItemTabs/StoreItemTabs'


export default function DonationsPage() {


    return (
        <StoreItemTabs
            catUrlSlug='donations'
            catSingular='Donation'
            catPlural='Donations'
            catEnum='donations'
        />
    )

}