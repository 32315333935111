import { api_call, make_response_object } from 'api/base'


export async function listUsers(params) {
    const r = await api_call('/users', 'GET', {}, params)
    return await make_response_object(r)
}


export async function listSuperAdmins(params) {
    const r = await api_call('/super_admins', 'GET', {}, params)
    return await make_response_object(r)
}

