import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        id={id}
        {...inputProps}
      />
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool
};


export const useInput = initialValue => {
  const [value, setValue] = React.useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: event => {
        setValue(event.target.value);
      }
    }
  };
};


// ControlledInputInternals returns components for creating controlled inputs using useReducer
export function ControlledInputInternals() {
  function reducer(state, { field, value }) {
    return {
        ...state,
        [field]: value
    }
  }
  
  const [formState, dispatch] = React.useReducer(reducer, {});
  
  const onChange = (field, value) => {
    dispatch({ field: field, value: value })
  }

  //   // For React Date (Airbnb published)
  //   // We expect:
  //   // e is startDate, f is startDateId
  //   // g is endDate, h is endDateId
  //   if (e.hasOwnProperty('_isAMomentObject')) {
  //     if (e._isAMomentObject){
  //       dispatch({ field: f, value: e })
  //       dispatch({ field: h, value: g })
  //     }
  //   }

  //   // For Checkboxes
  //   else if (e.target.hasOwnProperty('checked')) {
  //     dispatch({ field: e.target.id, value: e.target.checked })
  //   }
  //   // For other input types
  //   else {
  //     dispatch({ field: e.target.id, value: e.target.value })
  //   }

  // Expects an event
  const basicInputOnChange = (e) => {
    if (e.target.hasOwnProperty('checked')) {
      onChange(e.target.id, e.target.checked)
    }
    else {
      onChange(e.target.id, e.target.value)
    }
    
  }
  

  return [formState, dispatch, basicInputOnChange, onChange]
}