import { api_call, make_response_object } from 'api/base'


export async function listPayouts(schoolId, params) {
    const r = await api_call('/schools/' + schoolId + '/payouts', 'GET', {}, params)
    return await make_response_object(r)
}


export async function getPayout(schoolId, payoutId) {
    const r = await api_call('/schools/' + schoolId + '/payouts/' + payoutId, 'GET')
    return await make_response_object(r)
}