import React from 'react'
import loader from 'assets/img/loader-metronome-0.8-speed.gif'

export default function ReactTablePreloader(props) {
    let styles = {display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: '-10em'}
    return (
        props.loading ?
            <div className='-loading -active'>
                <div className='-loading-inner'>
                    <img src={loader} style={styles}/>
                </div>
            </div>
            :
            null
    )
}