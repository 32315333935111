import React, { useRef } from 'react';
import 'assets/css/password-strength-meter.css'
import { TextField, Typography, InputAdornment, IconButton, Input } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';


export default function PasswordStrengthField(props) {

    const zxcvbn = require('zxcvbn')

    const meterRef = useRef()
    const strengthTextRef = useRef()
    const warningTextRef = useRef()
    const suggestionTextRef = useRef()
    const [showPW, setShowPW] = React.useState(false)
    //const [showConfirmPW, setShowConfirmPW] = React.useState(false)

    const strength = {
        0: "Worst",
        1: "Bad",
        2: "Weak",
        3: "Good",
        4: "Strong"
    }


    React.useEffect(() => {
        if (!props.value) { return }

        const result = zxcvbn(
            props.value,
            props.userInputs
        )
        if (result.score < 3) {
            props.setError('Password strength must be at least 3.')
        }
        else if (props.value !== props.confirmValue) {
            props.setError('Passwords do not match.')
        }
        else {
            props.setError(null)
        }
        
    }, [props.value, props.confirmValue])


    function handleChange(e) {
        // Update password field text value
        props.onChange(e)

        const result = zxcvbn(
            e.target.value,
            props.userInputs
        )

        meterRef.current.value = result.score

        if (e.target.value !== '') {
            strengthTextRef.current.innerHTML = 'Strength: ' + strength[result.score] + ' (' + result.score + ')'
            if (result.score < 3) {
                strengthTextRef.current.innerHTML += '. Strength must be at least 3.'
            }
            warningTextRef.current.innerHTML = result.feedback.warning
            suggestionTextRef.current.innerHTML = result.feedback.suggestions
        }
        else {
            strengthTextRef.current.innerHTML = ''
            warningTextRef.current.innerHTML = ''
            suggestionTextRef.current.innerHTML = ''
        }
    }


    function getConfirmAdornment() {
        if (!props.value || !props.confirmValue) { return null }
        if (props.value === props.confirmValue) {
            return (
                <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: 'green' }}
                />
            )
        }
        else {
            return (
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ color: 'red' }}
                />
            )
        }
    }


    return (
        <>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name={props.name}
                label={props.label}
                type={showPW ? 'text' : 'password'}
                id={props.id}
                value={props.value}
                onChange={(e) => handleChange(e)}
                style={{marginBottom: 0, paddingBottom: 0}}
                InputProps={{
                    endAdornment:
                        <InputAdornment position='end'>
                            <IconButton
                                onClick={() => setShowPW(!showPW)}
                                onMouseDown={(e) => e.preventDefault()}
                            >
                                {showPW ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                }}
            />
            <meter
                max='4'
                id='password-strength-meter'
                ref={meterRef}
            />
            <Typography
                id='password-strength-text'
                ref={strengthTextRef}
            ></Typography>
            <Typography
                id='warning-text'
                ref={warningTextRef}
            ></Typography>
            <Typography
                id='suggestion-text'
                ref={suggestionTextRef}
            ></Typography>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name={props.confirmName}
                label={props.confirmLabel}
                type={showPW ? 'text' : 'password'}
                id={props.confirmId}
                value={props.confirmValue}
                onChange={props.confirmOnChange}
                InputProps={{
                    endAdornment:
                        <InputAdornment position='end'>
                            <IconButton
                                onClick={() => setShowPW(!showPW)}
                                onMouseDown={(e) => e.preventDefault()}
                            >
                                {showPW ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ,
                    startAdornment:
                        <InputAdornment position='start'>
                            {getConfirmAdornment()}
                        </InputAdornment>
                }}
            />
        </>
    )

}