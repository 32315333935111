import { api_call, make_response_object } from 'api/base'


export async function listCategories(schoolId, params) {
    const r = await api_call('/schools/' + schoolId + '/categories', 'GET', {}, params)
    return await make_response_object(r)
}


export async function getCategory(schoolId, categoryType) {
    const r = await api_call(
        '/schools/' + schoolId + '/categories/' + categoryType,
        'GET'
    )
    return await make_response_object(r)
}


export async function createUpdateCategory(schoolId, categoryType, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/categories/' + categoryType,
        'POST',
        body
    )
    return await make_response_object(r)
}


export async function deleteCategory(schoolId, categoryType) {
    const r = await api_call(
        '/schools/' + schoolId + '/categories/' + categoryType,
        'DELETE'
    )
    return await make_response_object(r)
}