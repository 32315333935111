import React from 'react';
import { faBusAlt, faDollarSign, faHandHoldingUsd, faShoppingCart, faTrashAlt, faUtensils, faBullseye } from '@fortawesome/free-solid-svg-icons';
import { faBellSchool } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, TableBody, TableContainer, List, ListItem, ListItemIcon, Button, Box, TextField, FormControl, InputLabel, MenuItem, FormLabel, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from "@material-ui/core/styles";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { SchoolContext } from 'components/SchoolProvider.js';
import { UserContext } from 'components/UserProvider.js';
import { useSnackbar } from 'notistack';
import useConstant from 'use-constant'
import currency from 'currency.js';
import stripeLogo from 'assets/img/powered_by_stripe.png';
import { calculateTotals, checkout } from 'api/cart';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-table-6/react-table.css';
import { make_filter_params } from 'api/base';
import { listStoreItems } from 'api/storeitems';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { listCategories } from 'api/category';
import { getGuardian } from 'api/guardians'
import GuardiansPage from 'views/Guardians/Guardians'
import ItemView from 'views/OnSiteTransaction/ItemView'
import Select from '@material-ui/core/Select';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import 'assets/css/stripeStyle.css';
import { SubmitButton } from 'components/CustomButtons/Button';
import Preloader from 'components/Preloader/Preloader';
import { api_base } from 'api/base';
import { FilterStateProvider } from 'components/FilterStateContext';
import ReactTable from 'components/Table';
import { filter } from 'lodash';


const useStyles = makeStyles(styles)


export default function MultiPaidReport(props) {
    const classes = useStyles()
    const currentSchool = React.useContext(SchoolContext)[0]
    const [loading, setLoading] = React.useState(true)
    const [storeItems, setStoreItems] = React.useState([])
    const [selectedStoreItems, setSelectedStoreItems] = React.useState([])
    const [reportUrl, setReportUrl] = React.useState('')
    const [fetching, setFetching] = React.useState(false)


    function handleCheckChange(e) {
        let sSI = selectedStoreItems.slice()
        if (e.target.checked) {
            sSI.push(e.target.value)
        }
        else {
            sSI = selectedStoreItems.filter(v => v !== e.target.value)
        }
        setSelectedStoreItems(sSI)
    }
    

    async function doListStoreItems(display_name_search_value) {
        setStoreItems([])
        setFetching(true)
        window.setTimeout(() => console.log('hi'), 5000)
        let filters = []
        filters.push({ field: 'category_type', op: 'in', value: "('activities', 'fees')"})
        if (display_name_search_value !== '') {
            filters.push({ field: 'display_name', op: 'contains', value: display_name_search_value })
        }
        const params = make_filter_params(filters)
        const r = await listStoreItems(currentSchool.value, params)
        setStoreItems(r.data.results)
        setFetching(false)
    }


    React.useEffect(() => {
        async function initPage() {
            await doListStoreItems('')
            setLoading(false)
        }
        initPage()
    }, [])


    React.useEffect(() => {
        let url = new URL('/schools/' + currentSchool.value + '/reports/multi-fee-activity', api_base)
        let filters = []
        let s = "("
        selectedStoreItems.map(id => {
            s = s + "'" + id + "'" + ","
        })
        s = s + ")"
        filters.push({ field: 'id', op: 'in', value: s})
        const params = make_filter_params(filters)
        url.search = new URLSearchParams(params).toString()

        setReportUrl(url.toString())

    },[selectedStoreItems])


    async function handleSearch(value) {
        doListStoreItems(value)
    }

    const handleSearchDebounced = useConstant(() => 
        AwesomeDebouncePromise(
            handleSearch,
            400
        )
    )

    if (loading) {
        return <Preloader />
    }


    return (
        <>
        <TextField label='Search' onChange={e => handleSearchDebounced(e.target.value)}/>
        <br /><br />
        <a href={reportUrl} download>
            <Button
                variant='contained'
                color='primary'
            >
                Export Report
            </Button>
        </a>
        <br /><br />
        {fetching && <Preloader />}
        {storeItems.length === 0 && !fetching ? 'No items found' : 
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        {storeItems.map(item => (
                            <TableRow
                                hover
                                key={item.id}
                            >
                                <TableCell component='th' scope='row'>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedStoreItems.includes(item.id)}
                                                    onChange={handleCheckChange}
                                                    value={item.id}
                                                />
                                            }
                                            label={item.display_name}
                                        />
                                    </FormGroup>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        }
        </>
    )

}