import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import { createSchool, getSchool, updateSchool } from 'api/school';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { SubmitButton } from 'components/CustomButtons/Button.js';
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js';
import Preloader from 'components/Preloader/Preloader.js';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from "react-router-dom";
import 'react-table-6/react-table.css';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText'
import { listPayoutMethods, createPayoutMethod } from 'api/payoutmethods'
import { FormControl, InputLabel, Select, MenuItem, Switch } from '@material-ui/core';
import { useStripe } from '@stripe/react-stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { listCategories, createUpdateCategory, deleteCategory } from 'api/category'



const useStyles = makeStyles(styles);

export default function EditSchool(props) {
    const classes = useStyles();
    const [school, setSchool] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [tab, setTab] = React.useState(0)
    const [payoutAccounts, setPayoutAccounts] = React.useState([])
    const [categories, setCategories] = React.useState([])
    const [account_external_to_school, set_account_external_to_school] = React.useState(false)
    let history = useHistory();
    const stripe = useStripe();


    const { enqueueSnackbar } = useSnackbar();

    // Determine if we are creating or editing
    var editing = false;
    var schoolId = '';
    if( props.location.pathname.split('/')[4] === 'edit') {
        editing = true;
        schoolId = props.location.pathname.split('/')[3];
    }
    const [ schoolFormState, schoolDispatch, schoolOnChange ] = ControlledInputInternals();
    const [ repFormState, repDispatch, repOnChange ] = ControlledInputInternals();
    const [extRepFormState, extRepDispatch, extRepOnChange] = ControlledInputInternals();
    const [accountFormState, accountDispatch, accountOnChange] = ControlledInputInternals()
    const [categoriesFormState, categoriesDispatch, categoriesBIOnChange, categoriesOnChange] = ControlledInputInternals()

    // Inputs
    const schoolFields = ['display_name', 'sis_id', 'tax_id', 'school_pays_fees', 'fee_amount', 'url_slug', 'custom_fee']
    const { display_name, sis_id, tax_id, school_pays_fees, fee_amount, url_slug, custom_fee } = schoolFormState
    const { first_name, last_name, email } = repFormState

    //Initialize Page
    React.useEffect(() => {
        async function initializePage(schoolId) {
            if (editing) {
                let r = await getSchool(schoolId);
                if (r.ok) {
                    setSchool(r.data)
                    // Set initial form values using dispatch function from useReducer in ControlledInputInternals
                    schoolFields.forEach(key => {
                        schoolDispatch({ field: key, value: r.data[key]})
                    })
                    for (var key in r.data.representative) {
                        repDispatch({ field: key, value: r.data.representative[key] })
                    }
                    setLoading(false)
                }
                else {
                    window.location = '/admin'
                }
            }
            else {
                setLoading(false)
            }
        }
        initializePage(schoolId);
    }, []) // Never refire


    React.useEffect(() => {
        async function initPayoutAccountsTab() {
            const r = await listPayoutMethods(school.id)
            if (!r.ok) {
                enqueueSnackbar('An error occurred fetching Payout Methods', { variant: 'error' })
                return
            }
            setPayoutAccounts(r.data.results)
            setSuccess(false)
        }


        async function initCategoriesTab() {
            setLoading(true)
            const r = await listCategories(school.id)
            if (!r.ok) {
                enqueueSnackbar('An error occurred fetching Categories', {variant: 'error'})
                setLoading(false)
                return
            }
            setCategories(r.data.results)
            r.data.results.map(c => {
                categoriesOnChange(c.category_type, c.default_payout_method_id)
            })
            setSuccess(false)
            setLoading(false)
        }

        if (tab === 1 || tab == 2) {
            initPayoutAccountsTab()
        }
        if (tab == 2) {
            initCategoriesTab()
        }

    }, [tab])


    async function handleUpdateSchool(e) {
        e.preventDefault();
        let form = document.querySelector('form')
        if (!form.reportValidity()) {
            return
        }
        setSubmitting(true);
        setSuccess(false);
        var r = null;
        var snackbarText = '';
        if (editing) {
            r = await updateSchool(schoolId, {'school': schoolFormState, 'rep': repFormState})
            snackbarText = 'updated'
        }
        else {
            r = await createSchool({'school': schoolFormState, 'rep': repFormState})
            snackbarText = 'created'
        }
        setSubmitting(false);
        if (r.ok) {
            enqueueSnackbar('School ' + snackbarText, {variant: 'success'});
            setSuccess(true);
            if (!editing) {
                history.push('/admin/schools/' + r.data.id + '/edit')
            }
        }
        else {
            enqueueSnackbar('An error occurred', {variant: 'error'});
        }
    }


    async function handleCreatePayoutAccount(e) {
        e.preventDefault()
        let form = document.querySelector('form')
        if (!form.reportValidity()) {
            return
        }
        setSubmitting(true)
        setSuccess(false)

        const fS = accountFormState
        let r = null
        
        if (!account_external_to_school) {
            const stripeR = await stripe.createToken('bank_account', {
                country: 'US',
                currency: 'usd',
                routing_number: fS.routing_number,
                account_number: fS.account_number,
                account_holder_name: fS.account_holder_name,
                account_holder_type: fS.account_holder_type
            })
            if (stripeR.error) {
                enqueueSnackbar(stripeR.error.message, { variant: 'error' })
                setSubmitting(false)
                return
            }
            r = await createPayoutMethod(school.id, { 'bank_account_token': stripeR.token.id, 'account_external_to_school': account_external_to_school })
        }
        else {
            r = await createPayoutMethod(school.id, { 'account_external_to_school': account_external_to_school, 'notify_owner': true, 'account_owner': extRepFormState })
        }

        if (!r.ok) {
            enqueueSnackbar('An error occurred', { variant: 'error' })
            setSubmitting(false)
            return
        }
        let nPA = payoutAccounts.slice()
        nPA.push(r.data)
        setPayoutAccounts(nPA)
        enqueueSnackbar('Payout account added', {variant: 'success'})
        setSuccess(true)
        setSubmitting(false)   
        Object.keys(accountFormState).forEach(key => {
            accountDispatch({ field: key, value: '' })
        })     
    }

    
    async function handleUpdateCategory(category) {
        setSubmitting(true)
        const value = categoriesFormState[category]
        let r = null
        if (value == 'none' || value == undefined) {
            r = await deleteCategory(school.id, category)
        }
        else {
            r = await createUpdateCategory(school.id, category, {
                'category_type': category,
                'default_payout_method_id': value,
            })
        }

        if (!r.ok) {
            enqueueSnackbar('An error occurred', {variant: 'error'})
        }
        else {
            enqueueSnackbar('Successfully updated category', {variant: 'success'})
        }
        setSubmitting(false)
    }


    if (loading) {
        return(<Preloader/>)
    }
    else {
        return (
            <>
            <AppBar position='static'>
                <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                    <Tab label='General' />
                    <Tab label='Payout Accounts' />
                    <Tab label='Category Setup' />
                </Tabs>
            </AppBar>
            <Paper
                className={classes.pagePaper}
            >
                {tab === 0 &&
                <>
                <strong>School Information</strong>
                <br/>
                <form>
                    <FormGroup>
                        <TextField
                            required
                            id='display_name'
                            label='Name'
                            variant='outlined'
                            value={display_name}
                            onChange={schoolOnChange}
                            margin='normal'
                        />
                        <TextField
                            required
                            id='sis_id'
                            label='SIS ID'
                            variant='outlined'
                            value={sis_id}
                            onChange={schoolOnChange}
                            margin='normal'
                        />
                        <TextField
                            required
                            id='url_slug'
                            label='Store URL ID'
                            variant='outlined'
                            value={url_slug}
                            onChange={schoolOnChange}
                            margin='normal'
                        />
                        <TextField
                            id='tax_id'
                            label='Tax ID (EIN)'
                            variant='outlined'
                            value={tax_id}
                            onChange={schoolOnChange}
                            margin='normal'
                            required
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={'custom_fee'}
                                    checked={custom_fee}
                                    onChange={schoolOnChange}
                                />
                            }
                            label='Custom Fee'
                        />
                        <TextField
                            id='fee_amount'
                            label='Fee Amount (%)'
                            variant='outlined'
                            value={fee_amount}
                            onChange={schoolOnChange}
                            margin='normal'
                            disabled={!custom_fee}
                            required={custom_fee}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={'school_pays_fees'}
                                    checked={school_pays_fees}
                                    onChange={schoolOnChange}
                                />
                            }
                            label='School Pays Fees'
                        />
                    </FormGroup>
                    <br/><br/>
                    <strong>Representative Information</strong>
                    <br/>
                    <FormGroup>
                        <TextField
                            required
                            disabled={editing}
                            id='first_name'
                            label='First Name'
                            variant='outlined'
                            value={first_name}
                            onChange={repOnChange}
                            margin='normal'
                        />
                        <TextField
                            required
                            disabled={editing}
                            id='last_name'
                            label='Last Name'
                            variant='outlined'
                            value={last_name}
                            onChange={repOnChange}
                            margin='normal'
                        />
                        <TextField
                            required
                            disabled={editing}
                            id='email'
                            label='Email'
                            variant='outlined'
                            value={email}
                            onChange={repOnChange}
                            margin='normal'
                        />
                    </FormGroup>
                    <Grid
                        container
                        direction='row'
                        justify='flex-end'
                        alignItems='center'
                    >
                        <SubmitButton
                            submitting={submitting}
                            success={success}
                            buttonText={editing ? 'Update School' : 'Create School'}
                            onClick={handleUpdateSchool}
                        />
                            </Grid>
                </form>
                </>
                }
                {tab === 1 &&
                <>
                <Typography style={{fontSize: 20}}>
                    Payout Accounts
                </Typography>
                <List>
                    {payoutAccounts.map(a => (
                        <ListItem>
                            <ListItemText
                                primary={a.bank_name + ' ending in ' + a.last_four}
                            />
                        </ListItem>
                    ))}
                </List>
                <br/>
                <Typography style={{ fontSize: 20 }}>
                    Create New Payout Account
                </Typography>
                <form>
                <Box>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={account_external_to_school}
                                    onChange={(e) => set_account_external_to_school(e.target.checked)}
                                    id='account_external_to_school'
                                    name='account_external_to_school'
                                />
                            }
                            label='Account is owned by a party other than the school'
                        />
                    </FormGroup>
                </Box>
                {!account_external_to_school &&
                <>
                <Grid container spacing={3}>
                    <Grid item>
                        <TextField
                            required
                            id='account_number'
                            label='Account Number'
                            type='number'
                            value={accountFormState.account_number}
                            onChange={accountOnChange}
                            style={{ minWidth: 300 }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            required
                            id='routing_number'
                            label='Routing Number'
                            type='number'
                            value={accountFormState.routing_number}
                            onChange={accountOnChange}
                            style={{ minWidth: 300 }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item>
                        <TextField
                            required
                            id='account_holder_name'
                            label="Account Holder's Name"
                            value={accountFormState.account_holder_name}
                            onChange={accountOnChange}
                            style={{ minWidth: 300 }}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl required>
                            <InputLabel id='account-type-label'>Account Holder Type</InputLabel>
                            <Select
                                labelId='account-type-label'
                                id='account_type'
                                value={accountFormState.account_type}
                                onChange={accountOnChange}
                                style={{ minWidth: 300 }}
                            >
                                <MenuItem value='individual'>Individual</MenuItem>
                                <MenuItem value='company'>Company</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                </>
                }
                {account_external_to_school &&
                <>
                    <strong>Account Representative Information</strong>
                    <br />
                    <FormGroup>
                        <TextField
                            required
                            id='first_name'
                            label='First Name'
                            variant='outlined'
                            value={extRepFormState.account_external_to_school}
                            onChange={extRepOnChange}
                            margin='normal'
                        />
                        <TextField
                            required
                            id='last_name'
                            label='Last Name'
                            variant='outlined'
                            value={extRepFormState.last_name}
                            onChange={extRepOnChange}
                            margin='normal'
                        />
                        <TextField
                            required
                            id='email'
                            label='Email'
                            variant='outlined'
                            value={extRepFormState.email}
                            onChange={extRepOnChange}
                            margin='normal'
                        />
                    </FormGroup>
                </>
                }
                <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                >
                    <SubmitButton
                        submitting={submitting}
                        success={success}
                        buttonText={'Add Account'}
                        onClick={handleCreatePayoutAccount}
                    />
                </Grid>
                </form>
                </>
                }
                {tab === 2 &&
                <>
                <Typography style={{ fontSize: 20 }}>
                    Categories
                </Typography>
                <Typography>
                    To enable a category, assign a default payout account to it.
                </Typography>
                <br/>
                <Grid
                    container
                    alignItems='center'
                    spacing={1}
                    style={{marginBottom: '1em'}}
                >
                    <Grid item>
                <FormControl>
                    <InputLabel id='donations-label' style={{marginLeft: '1em', marginTop: '2px'}}>Donations</InputLabel>
                    <Select
                        labelId='donations-label'
                        id='donations'
                        value={categoriesFormState.donations}
                        onChange={(e) => categoriesOnChange('donations', e.target.value)}
                        style={{minWidth: 300}}
                        variant='outlined'
                    >
                        <MenuItem value='none'>
                            No Account. Donations disabled.
                        </MenuItem>
                        {payoutAccounts.map(a => (
                            <MenuItem value={a.id}>{a.bank_name + ' ending in ' + a.last_four}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                </Grid>
                <Grid item>
                    <SubmitButton
                        onClick={() => handleUpdateCategory('donations')}
                        iconButton
                        icon={faSave}
                        submitting={submitting}
                    />
                </Grid>
                </Grid>

                <Grid
                    container
                    alignItems='center'
                    spacing={1}
                    style={{marginBottom: '1em'}}
                >
                    <Grid item>
                        <FormControl>
                                    <InputLabel id='fees-label' style={{ marginLeft: '1em', marginTop: '2px' }}>Fees</InputLabel>
                            <Select
                                labelId='fees-label'
                                id='fees'
                                value={categoriesFormState.fees}
                                onChange={(e) => categoriesOnChange('fees', e.target.value)}
                                style={{ minWidth: 300 }}
                                variant='outlined'
                            >
                                <MenuItem value='none'>
                                    No Account. Fees disabled.
                        </MenuItem>
                                {payoutAccounts.map(a => (
                                    <MenuItem value={a.id}>{a.bank_name + ' ending in ' + a.last_four}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                                <SubmitButton
                                    onClick={() => handleUpdateCategory('fees')}
                                    iconButton
                                    icon={faSave}
                                    submitting={submitting}
                                />
                    </Grid>   
                </Grid>    
                <Grid
                    container
                    alignItems='center'
                    spacing={1}
                    style={{marginBottom: '1em'}}
                >
                    <Grid item>                  
                        <FormControl>
                                    <InputLabel id='activities-label' style={{ marginLeft: '1em', marginTop: '2px' }}>Activities & Field Trips</InputLabel>
                            <Select
                                labelId='activities-label'
                                id='activities'
                                value={categoriesFormState.activities}
                                onChange={(e) => categoriesOnChange('activities', e.target.value)}
                                style={{minWidth: 300}}
                                variant='outlined'
                            >
                                <MenuItem value='none'>
                                    No Account. Activities & Field Trips disabled.
                                </MenuItem>
                                {payoutAccounts.map(a => (
                                    <MenuItem value={a.id}>{a.bank_name + ' ending in ' + a.last_four}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                                <SubmitButton
                                    onClick={() => handleUpdateCategory('activities')}
                                    iconButton
                                    icon={faSave}
                                    submitting={submitting}
                                />
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems='center'
                    spacing={1}
                    style={{marginBottom: '1em'}}
                >
                    <Grid item>
                        <FormControl>
                            <InputLabel id='lunches-label' style={{ marginLeft: '1em', marginTop: '2px' }}>Lunches</InputLabel>
                            <Select
                                labelId='lunches-label'
                                id='lunches'
                                value={categoriesFormState.lunches}
                                onChange={(e) => categoriesOnChange('lunches', e.target.value)}
                                style={{minWidth: 300}}
                                variant='outlined'
                            >
                                <MenuItem value='none'>
                                    No Account. Lunches disabled.
                                </MenuItem>
                                {payoutAccounts.map(a => (
                                    <MenuItem value={a.id}>{a.bank_name + ' ending in ' + a.last_four}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                                <SubmitButton
                                    onClick={() => handleUpdateCategory('lunches')}
                                    iconButton
                                    icon={faSave}
                                    submitting={submitting}
                                />
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems='center'
                    spacing={1}
                    style={{ marginBottom: '1em' }}
                >
                    <Grid item>
                        <FormControl>
                            <InputLabel id='childcare-label' style={{ marginLeft: '1em', marginTop: '2px' }}>Before & After Care</InputLabel>
                            <Select
                                labelId='childcare-label'
                                id='childcare'
                                value={categoriesFormState.childcare}
                                onChange={(e) => categoriesOnChange('childcare', e.target.value)}
                                style={{minWidth: 300}}
                                variant='outlined'
                            >
                                <MenuItem value='none'>
                                    No Account. Childcare disabled.
                                </MenuItem>
                                {payoutAccounts.map(a => (
                                    <MenuItem value={a.id}>{a.bank_name + ' ending in ' + a.last_four}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Grid>
                    <Grid item>
                                <SubmitButton
                                    onClick={() => handleUpdateCategory('childcare')}
                                    iconButton
                                    icon={faSave}
                                    submitting={submitting}
                                />     
                    </Grid>
                </Grid>
                </>
                }
            </Paper>
            </>
        )
    }
}