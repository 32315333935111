import React from 'react';
import StoreItemTabs from 'components/StoreItemTabs/StoreItemTabs'


export default function FeesPage() {

    
    return (
        <StoreItemTabs
            catUrlSlug='fees'
            catSingular='Fee'
            catPlural='Fees'
            catEnum='fees'
        />
    )

}