import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React from 'react'
import Preloader from './Preloader';


export function getCookie( name ) {
    var parts = document.cookie.split(name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
  }

export function expireCookie( cName ) {
    document.cookie = 
        encodeURIComponent(cName) + "=deleted; expires=" + new Date( 0 ).toUTCString();
}


export function ReportPreloader(props) {
    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.setDialogOpen(false)}
            disableBackdropClick
        >
            <DialogTitle>
                Generating Report
            </DialogTitle>
            <DialogContent>
                <Typography>
                    We are generating your report. This may take a few minutes.
                </Typography>
                <Preloader 
                    centered
                />
            </DialogContent>
    </Dialog>
    )
}