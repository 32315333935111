import React from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import { SchoolContext } from 'components/SchoolProvider.js'
import { listStaff } from 'api/staff.js'
import { make_params_from_react_table } from 'api/base.js'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant'
import { useHistory, Link } from "react-router-dom";
import ReactTablePreloader from 'components/Preloader/ReactTablePreloader.js';


const useStyles = makeStyles(styles);


export default function SchoolStaffPage() {
    const classes = useStyles();
    const [currentSchool, setcurrentSchool] = React.useContext(SchoolContext)
    const [staffList, setstaffList] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    //const [currentPage, setCurrentPage] = React.useState(0)
    const [pages, setPages] = React.useState(0)
    const reactTable = React.createRef();
    let history = useHistory();

    React.useEffect(() => {
        const params = make_params_from_react_table(reactTable.current.state)
        listStaff(currentSchool.value, params)
            .then(r => {
                setstaffList(r.data.results)
                setPages(r.data.pages)
                setLoading(false)
            })
    }, [currentSchool]) // Only fire when currentSchool changes

    // Debounce listStaff API call so it doesn't fire on every filter keystroke
    const listStaffDebounced = useConstant(() =>
        AwesomeDebouncePromise(
            listStaff,
            400
        )
    );

    async function handleOnFetchData(state, instance) {
        // Don't fire on initial page load. Intial data load is handled in useEffect
        if (!loading) {
            setLoading(true)
            const params = make_params_from_react_table(state)
            const r = await listStaffDebounced(currentSchool.value, params)
            setstaffList(r.data.results)
            setPages(r.data.pages)
            //setCurrentPage()
            setLoading(false)
        }
    }

    function rowHandlers(state, rowInfo, column, instance) {
        return{
            onClick: e => {
                history.push('/admin/school_staff/' + rowInfo.original.id + '/edit')
            },
            style: {
                cursor: 'pointer',
            }
        } 
    }

    const columns = [
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'SIS ID',
            accessor: 'school_staff_sis_id'
        }
    ]

    return(
        <Paper
            className={classes.pagePaper}
        >
            <Link to='/admin/school_staff/new'>
                <Button
                    variant='contained'
                    color='primary'
                >
                    Create New Staff Member
                </Button>
            </Link>
            <br/><br/>
            <ReactTable
                ref={reactTable}
                data={staffList}
                columns={columns}
                //page={currentPage}
                pages={pages}
                defaultPageSize={10}
                filterable={true}
                loading={loading}
                LoadingComponent={ReactTablePreloader}
                onFetchData={handleOnFetchData}
                getTrProps={rowHandlers}
                className='-highlight'
                manual
            />
        </Paper>
    )
}