import { Checkbox, FormControlLabel, Grid, TextField, MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Card from "components/Card/Card.js";
import { SubmitButton } from "components/CustomButtons/Button";
import { ControlledInputInternals } from "components/CustomInput/CustomInput";
import Preloader from "components/Preloader/Preloader";
import { UserContext } from 'components/UserProvider.js';
import React from "react";
import { verifySchool } from 'api/school'
import { useSnackbar } from 'notistack';
import { useStripe } from '@stripe/react-stripe-js';
import { listPayoutMethods, createPayoutMethod } from 'api/payoutmethods'


export default function SchoolVerification(props) {
    const [loading, setLoading] = React.useState(true);
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const currentUser = React.useContext(UserContext)[0]
    const { enqueueSnackbar } = useSnackbar();
    const stripe = useStripe();
    const s = props.s

    let TYPE_STRING_POS = null
    let TYPE_STRING = null
    if (props.school) {
        TYPE_STRING = 'School'
        TYPE_STRING_POS = "School's"
    }
    else if (props.payout_account) {
        TYPE_STRING = 'Organization '
        TYPE_STRING_POS = "Organization's"
    }

    const [formState, dispatch, basicInputOnChange, onChange] = ControlledInputInternals()
    function sOnChange(s, e) {
        let newFormState = { ...formState[s.id] }
        if (e.target.hasOwnProperty('checked')) {
            newFormState[e.target.name] = e.target.checked
        }
        else {
            newFormState[e.target.name] = e.target.value
        }

        onChange(s.id, newFormState)
    }

    React.useEffect(() => {
        if (props.school) {
            if (currentUser.rep_for_schools.length !== 0) {
                currentUser.rep_for_schools.map(s => {
                    onChange(s.id, {})
                })
            }
        }
        if (props.payout_account) {
            if (currentUser.rep_for_payout_accounts.length !== 0) {
                currentUser.rep_for_payout_accounts.map(s => {
                    onChange(s.id, {})
                })
            }
        }
        setLoading(false)
    }, [])


    async function handleVerifySchool(s, e) {
        e.preventDefault()
        const form = document.getElementById(s.id + '-verify-form')
        if (!form.reportValidity()) { return }
        setSubmitting(true)

        const fS = formState[s.id]

        const stripeR = await stripe.createToken('bank_account', {
            country: 'US',
            currency: 'usd',
            routing_number: fS.routing_number,
            account_number: fS.account_number,
            account_holder_name: fS.account_holder_name,
            account_holder_type: fS.account_holder_type
        })
        if (stripeR.error) {
            enqueueSnackbar(stripeR.error.message, { variant: 'error' })
            setSubmitting(false)
            return
        }

        let body = {}
        body['company'] = {
            address: {
                line1: fS.line1,
                line2: fS.line2,
                city: fS.city,
                state: fS.state,
                postal_code: fS.postal_code,
            },
            name: fS.name,
            tax_id: fS.tax_id,
        }
        body['business_profile'] = {
            url: fS.url
        }
        body['bank_account_token'] = stripeR.token.id

        let r = null

        if (props.school) {
            r = await verifySchool(s.id, body)
        }
        else if (props.payout_account) {
            r = await createPayoutMethod(s.school_id, { payout_method_id: s.id, verification_info: body, account_external_to_school: true, create_verify_account: true})
        }

        
        if (!r.ok) {
            enqueueSnackbar('An error occurred', {variant: 'error'})
            setSubmitting(false)
            return
        }
        enqueueSnackbar('Verification Submitted to Stripe', {variant: 'success'})
        setSuccess(true)
        setSubmitting(false)

    }

    if (loading) { return <Preloader /> }

    if (!s.stripe_verification_complete && !s.stripe_verification_submitted) {
        return (
            <Card variant='outlined'>
                <CardContent>
                    <Typography style={{ fontSize: 22 }}>
                        Please complete Stripe verification for {props.school && s.display_name} {props.payout_account && 'your organization'}.
                  </Typography>
                    <br />
                    <form id={s.id + '-verify-form'}>
                        <TextField
                            name={'name'}
                            label={TYPE_STRING_POS + " Legal Name"}
                            value={formState[s.id]['name']}
                            onChange={(e) => sOnChange(s, e)}
                            required
                        />
                        <br />
                        <TextField
                            name={'tax_id'}
                            label={TYPE_STRING_POS + " Tax ID (EIN)"}
                            value={formState[s.id]['tax_id']}
                            onChange={(e) => sOnChange(s, e)}
                            required
                        />
                        <br />
                        <TextField
                            name={'url'}
                            label={TYPE_STRING_POS + " Website URL"}
                            value={formState[s.id]['url']}
                            onChange={(e) => sOnChange(s, e)}
                            required
                        />
                        <br /><br />
                        <Typography>
                            {TYPE_STRING_POS} Address
                        </Typography>
                        <TextField
                            name={'line1'}
                            label='Address Line 1'
                            value={formState[s.id]['line1']}
                            onChange={(e) => sOnChange(s, e)}
                            required
                        />
                        <br />
                        <TextField
                            name={'line2'}
                            label='Address Line 2'
                            value={formState[s.id]['line2']}
                            onChange={(e) => sOnChange(s, e)}
                        />
                        <br />
                        <TextField
                            name={'city'}
                            label='City'
                            value={formState[s.id]['city']}
                            onChange={(e) => sOnChange(s, e)}
                            required
                        />
                        <br />
                        <TextField
                            name={'state'}
                            label='State'
                            value={formState[s.id]['state']}
                            onChange={(e) => sOnChange(s, e)}
                            required
                        />
                        <br />
                        <TextField
                            name={'postal_code'}
                            label='ZIP Code'
                            value={formState[s.id]['postal_code']}
                            onChange={(e) => sOnChange(s, e)}
                            required
                        />
                        <br /><br />
                        <Typography>
                            {TYPE_STRING_POS} bank account for recieving payments. Additional accounts can be set up later.
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item>
                                <TextField
                                    required
                                    name='account_number'
                                    label='Account Number'
                                    type='number'
                                    value={formState[s.id]['account_number']}
                                    onChange={(e) => sOnChange(s, e)}
                                    style={{ minWidth: 300 }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    name='routing_number'
                                    label='Routing Number'
                                    type='number'
                                    value={formState[s.id]['routing_number']}
                                    onChange={(e) => sOnChange(s, e)}
                                    style={{ minWidth: 300 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item>
                                <TextField
                                    required
                                    name='account_holder_name'
                                    label="Account Holder's Name"
                                    value={formState[s.id]['account_holder_name']}
                                    onChange={(e) => sOnChange(s, e)}
                                    style={{ minWidth: 300 }}
                                />
                            </Grid>
                            <Grid item>
                                <FormControl required>
                                    <InputLabel id='account-type-label'>Account Holder Type</InputLabel>
                                    <Select
                                        labelId='account-type-label'
                                        name='account_type'
                                        value={formState.accountType}
                                        onChange={(e) => sOnChange(s, e)}
                                        style={{ minWidth: 300 }}
                                    >
                                        <MenuItem value='individual'>Individual</MenuItem>
                                        <MenuItem value='company'>Company</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <br /><br />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={'tos_acceptance'}
                                    checked={formState[s.id]['tos_acceptance']}
                                    onChange={(e) => sOnChange(s, e)}
                                    required
                                />
                            }
                            label={<><span>I have read and accept the </span>
                                <a
                                    href='https://stripe.com/connect-account/legal'
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    Stripe Terms of Service
                          </a>
                            </>
                            }
                        />
                    </form>
                    <Grid
                        container
                        direction='row'
                        justify='flex-end'
                        alignItems='center'
                    >
                        <SubmitButton
                            submitting={submitting}
                            success={success}
                            buttonText={'Verify ' + TYPE_STRING}
                            onClick={(e) => handleVerifySchool(s, e)}
                        />
                    </Grid>
                </CardContent>
            </Card>
        )
    }
    
    else if (!s.stripe_verification_complete && s.stripe_verification_submitted) {
        return (
            <Card variant='outlined'>
                <CardContent>
                    <Typography style={{ fontSize: 22 }}>
                        Stripe verification is pending for {props.school && s.display_name} {props.payout_account && 'your organization'}.
                  </Typography>
                </CardContent>
            </Card>
        )
    }

    return null

}