import logo from 'logo.svg';
// import 'App.css';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useFilters, useTable } from 'react-table'
import { listStudents } from 'api/students.js'
import React from 'react'
import { TextField, Checkbox, Typography, Grid, FormControlLabel, Switch } from '@material-ui/core';
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant'
import {FilterStateProvider, FilterStateContext} from 'components/FilterStateContext'
import Preloader from './Preloader/Preloader';
import ReactTablePreloader from './Preloader/ReactTablePreloader';
import { check } from 'prettier';
//import useWhyDidYouUpdate from 'wdyu';

// We use the Nonce variables (one global, one local to the table)
// to check if our async filter() calls are the latest
// If localNonce !== globalNonce, we know that the filter() call in question
// is not the latest, as another has taken place and overwritten 
// globalNonce with a new Object()
// If localNonce !== globalNonce, we stop execution so only the latest
// user filter input is used to display data 
let globalNonce

function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;
	    
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;
	
    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
	
    if (callNow) func.apply(context, args);
  };
};





const TextFilter = React.memo(props => {
  // useWhyDidYouUpdate('TextFilter', props)
  //console.log('TextFilter Rerender. props:', props)
  const [filterState, basicInputOnChange] = React.useContext(FilterStateContext)
  //console.log('filterState:', filterState)
  return (
  <TextField
    id={props.column.id}
    value={filterState[props.column.id] || ''}
    onChange={basicInputOnChange}
    //variant='outlined'
    placeholder={'Filter by ' + props.column.Header}
    disabled={props.disabled}
    type={props.column.type || 'text'}
  />
  )
})


const RowCheckBox = React.memo(props => {
  //useWhyDidYouUpdate('RowCheckBox', props)
  return (
    <Checkbox
      id={props.id}
      checked={props.checked}
      onChange={props.handleChange}
    />
  )
})


const HeaderCheckBox = React.memo(props => {
  return (
    <Checkbox
      id='all-rows'
      checked={props.checked}
      onChange={props.handleChange}
      //checkedIcon
    />
  )
})



const RenderTable = React.memo(props => {
  //useWhyDidYouUpdate('RenderTable', props)
  //console.log('RenderTable Rerender. props:', props)

  const [checked, setChecked] = React.useState({})
  const [checkedObjects, setCheckedObjects] = React.useState({})
  // const checked = props.checked
  // const setChecked = props.setChecked


  const handleCheckChange = React.useCallback((e, row) => {
    e.persist()
    // console.log('checked:', checked)
    // let c = {...checked}
    // console.log(c)
    // c[e.target.id] = e.target.checked
    // console.log(c)
    // setChecked(c)
    setChecked(prevChecked => {
      //console.log('prev:', prevChecked)
      let c = {...prevChecked}
      //c[e.target.id] = e.target.checked
      if (e.target.checked) {
        c[e.target.id] = true
      }
      else {
        delete c[e.target.id]
      }
      //console.log(c)
      if (props.informChecked !== undefined) {
        props.informChecked(c)
      }
      return c
    })
    setCheckedObjects(prevCheckedObjects => {
      let cObj = { ...prevCheckedObjects }
      if (e.target.checked) {
        cObj[e.target.id] = row.original
      }
      else {
        delete cObj[e.target.id]
      }
      if (props.informObjChecked !== undefined) {
        props.informObjChecked(cObj)
      }
      return cObj
    })
  }, [])


  React.useEffect(() => {
    setChecked({})
    setCheckedObjects({})
    if (props.informChecked !== undefined) {
      props.informChecked([])
    }
    if (props.informObjChecked !== undefined) {
      props.informObjChecked([])
    }
  }, [props.clearChecked])


  const handleAllCheckChange = React.useCallback(e => {
    if (e.target.checked) {
      let c = {}
      let cObj = {}
      props.data.map(s => {
        c[s.id] = true
        cObj[s.id] = s
      })
      setChecked(c)
      setCheckedObjects(cObj)
      if (props.informChecked !== undefined) {
        props.informChecked(c)
      }
      if (props.informObjChecked !== undefined) {
        props.informObjChecked(cObj)
      }
    }
    else {
      setChecked({})
      setCheckedObjects({})
      if (props.informChecked !== undefined) {
        props.informChecked({})
      }
      if (props.informObjChecked !== undefined) {
        props.informObjChecked({})
      }
    }
  })

//   const columns = React.useMemo(() => 
// //   [
// //     {
// //         Header: 'First Name',
// //         accessor: 'first_name',
// //     },
// //     {
// //         Header: 'Last Name',
// //         accessor: 'last_name',
// //     },
// //     {
// //         Header: 'Grade Level',
// //         accessor: 'grade_level',
// //     },
// // ]
// props.columns
// , [props.columns])

const columns = props.columns

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable({
    columns,
    data: props.data,
  //   autoResetPage: false,

  //  autoResetExpanded: false,

  //  autoResetGroupBy: false,

  //  autoResetSelectedRows: false,

  //  autoResetSortBy: false,

  //  autoResetFilters: false,

  //  autoResetRowState: false,
  },
  )




  return (
    <>
    <Grid
      container
      direction='row'
      justify='flex-end'
      alignItems='center'
    >
      <FormControlLabel
        control={
          <Switch
            checked={props.showAll}
            onChange={props.handleShowAll}
          />
        }
        label={'Display all students'}
        style={{margin: '1em'}}
      />
    </Grid>
    <TableContainer style={{maxHeight: 500}}>
    <Table style={{display: 'block'}} {...getTableProps()} stickyHeader>
    <TableHead>
    {headerGroups.map(headerGroup => (
      <>
      <TableRow>
        {props.selectable &&
          <TableCell>Select</TableCell>
        }
        {headerGroup.headers.map(column => (
          <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
        ))}
      </TableRow>
      <TableRow>
        {props.selectable &&
        <TableCell>
          <HeaderCheckBox
            id={'all-rows-checkbox'}
            checked={Object.entries(checked).length > 0}
            handleChange={handleAllCheckChange}
          />
        </TableCell>
        }
      {headerGroup.headers.map(column => {
        return(
          <TableCell>
            <TextFilter
              column={column}
              onChange={props.handleFilterStateChange}
              disabled={props.showAll && !props.defaultShowAll}
            />
          </TableCell>
        )})}
      </TableRow>
      </>
    ))}
    </TableHead>
  {props.loading ? <Preloader /> :
  
    <TableBody {...getTableBodyProps()}>
    {rows.map((row, i) => {
      prepareRow(row)
      return(
        <TableRow {...row.getRowProps()}>
          {props.selectable &&
          <TableCell>
            <RowCheckBox
              id={row.original.id}
              checked={checked[row.original.id] || false}
              handleChange={(e) => handleCheckChange(e, row)}
            />
          </TableCell>
          }
          {row.cells.map(cell => {
            return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
          })}
        </TableRow>
      )
    })}
    </TableBody>
    }
  </Table>
    </TableContainer>
  </>
    )
  })




export default function ReactTable(props) {
  //useWhyDidYouUpdate('ReactTable', props)
  const [data, setData] = React.useState([])
  // const data = props.data
  // const setData = props.setData
  //const [filterState, setFilterState] = React.useState({})
  const filterState = React.useContext(FilterStateContext)[0] 
  const [loading, setLoading] = React.useState(false)
  const [showAll, setShowAll] = React.useState(props.defaultShowAll || false)
  const [checked, setChecked] = React.useState({})

  let schoolId = '5d2ab3bb-1f28-426f-8148-e47543b1cbee'

  // const listStudentsDebounced = useConstant(() =>
  //   AwesomeDebouncePromise(listStudents, 500)
  // )

  const fetchFunctionDebounced = useConstant(() =>
    AwesomeDebouncePromise(props.fetchFunction, 500)
  )

  // function handleFilterStateChange(e) {
  //   const v = e.target.value || ''
  //   let fS = filterState
  //   fS[e.target.id] = v
  //   setFilterState(fS)
  //   console.log(fS)
  // }
  
  async function filter() {
    const localNonce = globalNonce = new Object()
    if (showAll && !props.defaultShowAll) { return }
    if (Object.keys(filterState).length === 0) {
      return
    }
    setLoading(true)

    // Skip filtering if all filter fields are blank
    let skipFilter = true
    for (const [k, v] of Object.entries(filterState)) {
      if (v) {
        skipFilter = false
      }
      else if (v === '') {
        delete filterState[k]
      }
    }

    if (skipFilter) {
      // All filters are blank
      if (!showAll) {
        setData([])
        if (props.informDisplayed !== undefined) {
          props.informDisplayed([])
        }
      }
      else {
        const r = await props.fetchFunction(params)
        if (localNonce !== globalNonce) { return }
        setData(r.data.results)
        if (props.informDisplayed !== undefined) {
          props.informDisplayed(r.data.results)
        }
      }
      setLoading(false)
      return
    }

    let params = {}
    Object.entries(filterState).map(o => {
      if (o[0].includes('grade_level')) {
        params[o[0] + '[eq]'] = o[1]
      }
      else {
        params[o[0] + '[contains]'] = o[1]
      }
    })
    //params['first_name' + '[contains]'] = filterState.first_name
    const r = await props.fetchFunction(params)
    // console.log('check', localNonce === globalNonce, filterState)
    if (localNonce !== globalNonce) { return }
    setData(r.data.results)
    if (props.informDisplayed !== undefined) {
      props.informDisplayed(r.data.results)
    }
    setLoading(false)
  }



  React.useEffect(() => {
    // console.log('filter called', filterState)
    filter()
  }, [filterState])


  async function doShowAll() {
    if (!showAll) {
      setData([])
      if (props.informDisplayed !== undefined) {
        props.informDisplayed([])
      }
      setLoading(false)
      return
    }
    setLoading(true)
    const r = await props.fetchFunction()
    setData(r.data.results)
    if (props.informDisplayed !== undefined) {
      props.informDisplayed(r.data.results)
    }
    setLoading(false)
  }

React.useEffect(() => {
  doShowAll()
}, [showAll])


async function handleShowAll(e) {
  setLoading(true)
  // console.log(e)
  setShowAll(e.target.checked)
  // if (!e.target.checked) {
  //   setData([])
  //   setLoading(false)
  //   return
  // }
  // const r = await props.fetchFunction()
  // setData(r.data.results)
  // props.columns.map(c => {
  //   onChange(c.accessor, '')
  // })
  setLoading(false)
}


React.useEffect(() => {
  if (showAll) {
    doShowAll()
  }
  else {
    filter()
  }
  setChecked([])
  if (props.informChecked !== undefined) {
    props.informChecked([])
  }
}, [props.refetch])


const hSAMemo = React.useCallback((e) => handleShowAll(e), [])

  return (
    <>
    <RenderTable
      data={data}
      loading={loading}
      selectable={props.selectable || true}
      showAll={showAll}
      handleShowAll={hSAMemo}
      defaultShowAll={props.defaultShowAll}
      columns={props.columns}
      informChecked={props.informChecked}
      informObjChecked={props.informObjChecked}
      informDisplayed={props.informDisplayed}
      clearChecked={props.clearChecked}
    />
    {
    data.length === 0 && !loading && Object.keys(filterState).length === 0 &&
    <Typography
      style={{ marginTop: '1em', fontSize: 26 }}
    >
      Please use the filters to find students.
      </Typography>
  }
  {
        data.length === 0 && !loading && Object.keys(filterState).length > 0 &&
        <Typography
          style={{ marginTop: '1em', fontSize: 26 }}
        >
          No students found.
      </Typography>
      }
  </>
  )
}