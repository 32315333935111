import { api_call, make_response_object } from 'api/base'


export async function calculateTotals(body) {
    const r = await api_call('/cart/totals', 'POST', body)
    return await make_response_object(r)
}


export async function checkout(body, params) {
    const r = await api_call('/cart/checkout', 'POST', body, params)
    return await make_response_object(r)
}