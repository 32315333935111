import React from 'react'
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver'
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import {TextField, Typography} from '@material-ui/core'


export default function TinyMCE(props) {

    const ref = React.createRef();

    React.useEffect(() => {
        tinymce.init({
            // Use of 'selector' causes editor to not be initialized on
            // subsequent loads after initial refresh
            //  Use target with a ref instead
            //selector: '#' + props.id,
            target: ref.current,
            plugins: ['paste', 'link'],
            skin_url: '/tinymce-skins/ui/oxide',
            width: '100%',
            setup: (editor) => {
                editor.on('change', () => { props.onChange(props.id, editor.getContent()) })
                editor.on('init', () => {
                    editor.setContent(props.value || '')
                })
            }
        })
    }, [])


    // This was used with selector in tinymce.init() above
    // Initialize TinyMCE, but wait for DOM node to be ready using Callback Ref
    // const ref = React.useCallback(node => {
    //     tinymce.init({
    //         selector: '#' + props.id,
    //         plugins: ['paste', 'link'],
    //         skin_url: '/tinymce-skins/ui/oxide',
    //         width: '100%',
    //         setup: (editor) => {
    //             console.log('hi')
    //             editor.on('change', () => { props.onChange(props.id, editor.getContent()) })
    //             editor.on('init', () => {
    //                 editor.setContent(props.value || '')
    //             })
    //         }
    //     })
    // }, [])


    return (
        <React.Fragment>
            <Typography>{props.label}</Typography>
            <TextField
                id={props.id}
                value={props.value}
                multiline
                fullWidth
                ref={ref}
            />
        </React.Fragment>
    )
}