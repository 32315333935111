import React from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { SchoolContext } from 'components/SchoolProvider.js'
import { useSnackbar } from 'notistack'
import { getStaff, createStaff, updateStaff } from 'api/staff.js'
import { SubmitButton } from 'components/CustomButtons/Button.js'
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js'
import { useHistory } from "react-router-dom";
import { deleteStaff } from 'api/staff';


const useStyles = makeStyles(styles);


export default function EditSchoolStaff(props) {
    const classes = useStyles();
    const [currentSchool, setcurrentSchool] = React.useContext(SchoolContext);
    const [staff, setStaff] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [submitting, setSubmitting] = React.useState(false);
    const [checkboxState, setCheckboxState] = React.useState({});
    const [success, setSuccess] = React.useState(false);
    const [deleteSuccess, setDeleteSuccess] = React.useState(false)
    let history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    // Determine if we are creating or editing
    var editing = false;
    var staffId = '';
    if( props.location.pathname.split('/')[4] === 'edit') {
        editing = true;
        staffId = props.location.pathname.split('/')[3];
    }
    
    const [ formState, dispatch, onChange ] = ControlledInputInternals();

    // Inputs
    const { first_name, last_name, email, school_staff_sis_id } = formState;

    // const [sFormState, sDispatch, sOnChange] = ControlledInputInternals();
    // const { sis_id } = sFormState;
    
    // Load user data if editing
    React.useEffect(() => {
        if (editing) {
            getStaff(currentSchool.value, staffId)
                .then(r => {
                    setStaff(r.data)
                    // Set initial form values using dispatch function from useReducer in ControlledInputInternals
                    for (var key in r.data) {
                        dispatch({ field: key, value: r.data[key]})
                    }
                    // r.data.school_staff_memberships.map(school_staff => {
                    //     if (school_staff.school.id === currentSchool.value) {
                    //         sDispatch({field: 'sis_id', value: school_staff.sis_id})
                    //     }
                    // })
                    if (r.data.school_staff_grants_mapping !== undefined) {
                        setCheckboxState(r.data.school_staff_grants_mapping)
                    }
                    else {
                        setCheckboxState({})
                    }
                    setLoading(false)
                })
        }
        else {
            const noPerms = {
                create_share_reports: false,
                issue_refunds: false,
                process_on_site_transactions: false,
                create_store_item_requests: false,
                approve_store_item_requests: false,
                manage_students: false,
                manage_guardians: false,
                manage_school_staff: false,
                manage_payout_methods: false,
                manage_lunches: false,
                manage_childcare: false,
                manage_sis_imports: false,
                manage_school: false,
            };
            setCheckboxState(noPerms);
            setStaff({school_staff_grants_mapping: noPerms})
            setLoading(false)
        }
    }, [])

    const handleChange = name => event => {
        setCheckboxState({ ...checkboxState, [name]: event.target.checked});
    };

    function createCheckboxes() {
        let checkboxes = []

        let labels = {
            create_share_reports: 'Create and Share Reports',
            issue_refunds: 'Issue Refunds',
            process_on_site_transactions: 'Process On-Site Transactions',
            create_store_item_requests: 'Create Store Item Requests',
            approve_store_item_requests: 'Approve Store Item Requests',
            manage_students: 'Manage Students',
            manage_guardians: 'Manage Guardians',
            manage_school_staff: 'Manage School Staff',
            manage_payout_methods: 'Manage Payout Methods',
            manage_lunches: 'Manage Lunches',
            manage_childcare: 'Manage Before & After Care',
            manage_sis_imports: 'Manage SIS Imports',
            manage_school: 'Manage School',
        }
        for (let [key, value] of Object.entries(checkboxState)) {
            checkboxes.push(
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkboxState[key]}
                            value={key}
                            onChange={handleChange(key)}
                        />
                    }
                    label={labels[key]}
                />
            )
        }

        return checkboxes
    }

    async function handleUpdateStaff() {
        setSubmitting(true);
        setSuccess(false);
        let currentPerms = staff.school_staff_grants_mapping;
        let newPerms = {add: [], rm: []};
        for (let [key, value] of Object.entries(checkboxState)) {
            // No change
            if (currentPerms[key] == value) {
                continue;
            }
            // Remove permission
            if (currentPerms[key] && !value) {
                newPerms.rm.push(key)
                continue;
            }
            // Add permission
            if (!currentPerms[key] && value) {
                newPerms.add.push(key)
                continue;
            }
        }
        var r = null;
        var snackbarText = '';
        if (editing) {
            r = await updateStaff(
                currentSchool.value,
                staffId,
                { user: { first_name: formState.first_name, last_name: formState.last_name, email: formState.email },
                permissions: newPerms}
                );
            snackbarText = 'updated'
        }
        else {
            r = await createStaff(currentSchool.value, {user: formState, permissions: newPerms });
            snackbarText = 'created'
        }
        setSubmitting(false);
        if (r.ok) {
            enqueueSnackbar('Staff member ' + snackbarText, {variant: 'success'});
            setSuccess(true);
            if (!editing) {
                history.push('/admin/school_staff/' + r.data.id + '/edit')
            }
        } else {
            enqueueSnackbar('An error occurred', {variant: 'error'});
        }
    }

    async function handleDeleteStaff(e) {
        e.preventDefault();
        setSubmitting(true);
        setDeleteSuccess(false)
        const r = await deleteStaff(currentSchool.value, staffId)
        if (!r.ok) {
            enqueueSnackbar('An error occurred', { variant: 'error' });
            setSubmitting(false)
            return
        }
        enqueueSnackbar('Staff deleted', { variant: 'success' });
        setDeleteSuccess(true)
        setSubmitting(false)
        history.push('/admin/school_staff')
    }


    if (loading) {
        return (null)
    } else{
        return (
            <Paper
                className={classes.pagePaper}
            >
                <strong>User Information</strong>
                <br/><br/>
                <div>
                    <TextField
                        id='first_name'
                        label='First Name'
                        variant='outlined'
                        value={first_name}
                        onChange={onChange}
                        style={{marginRight: '1em'}}
                        required
                    />
                    <TextField
                        id='last_name'
                        label='Last Name'
                        variant='outlined'
                        value={last_name}
                        onChange={onChange}
                        required
                    />
                </div>
                <br/>
                <div>
                    <TextField
                        disabled={editing}
                        id='email'
                        label='Email'
                        variant='outlined'
                        value={email}
                        onChange={onChange}
                        style={{width: '26em'}}
                        required
                    />
                </div>
                <br/>
                <div>
                    <TextField
                        disabled={editing}
                        id='school_staff_sis_id'
                        label='SIS ID'
                        variant='outlined'
                        value={school_staff_sis_id}
                        onChange={onChange}
                        style={{width: '16em'}}
                        required
                    />
                </div>
                <br/>
                <strong>Assigned Roles</strong>
                <br/><br/>
                <FormControl component='fieldset'>
                    <FormGroup>
                        {createCheckboxes()}
                    </FormGroup>
                </FormControl>
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    <SubmitButton
                        submitting={submitting}
                        success={success}
                        buttonText={editing ? 'Update Staff Member' : 'Create Staff Member'}
                        onClick={handleUpdateStaff}
                    />
                    {editing &&
                        <SubmitButton
                            submitting={submitting}
                            success={deleteSuccess}
                            buttonText='Delete Staff'
                            variant='outlined'
                            color='default'
                            onClick={handleDeleteStaff}
                        />
                    }
                </Grid>
            </Paper>
        )
    }
    
}