import { api_call, make_response_object } from 'api/base'
import { param } from 'jquery'


export async function listChildcareEnrollments(schoolId, storeItemId, params) {
    const r = await api_call(
        '/schools/' + schoolId + '/store_items/' + storeItemId + '/childcare_enrollments',
        'GET',
        {},
        params
    )
    return await make_response_object(r)
}


export async function updateChildcareEnrollment(schoolId, storeItemId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/store_items/' + storeItemId + '/childcare_enrollments',
        'PUT',
        body
    )
    return await make_response_object(r)
}


export async function bulkUnenrollChildcare(schoolId, storeItemId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/store_items/' + storeItemId + '/childcare_enrollments/unenroll',
        'DELETE',
        body
    )
    return await make_response_object(r)
}


export async function bulkEnrollChildcare(schoolId, storeItemId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/store_items/' + storeItemId + '/childcare_enrollments/enroll',
        'PUT',
        body
    )
    return await make_response_object(r)
}