import React from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import { SchoolContext } from 'components/SchoolProvider.js';
import { useSnackbar } from 'notistack';
import { getGuardian,  updateGuardian } from 'api/guardians.js';
import { listStudents } from 'api/students.js';
import { make_params_from_react_table } from 'api/base.js'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant'
import remove from 'lodash/remove';
import without from 'lodash/without';
import some from 'lodash/some';
import { SubmitButton } from 'components/CustomButtons/Button.js'
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js'
import { useHistory } from "react-router-dom";
import { createGuardian } from 'api/guardians';


const useStyles = makeStyles(styles);


export default function EditGuardian(props) {
    const classes = useStyles();
    const [currentSchool, setcurrentSchool] = React.useContext(SchoolContext);
    const [guardian, setGuardian] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [tableLoading, setTableLoading] = React.useState(true);
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [pages, setPages] = React.useState(0)
    let history = useHistory();
    // const [formState, setFormState] = React.useState({});
    const [selectedStudents, setSelectedStudents] = React.useState([]);
    const [availableStudents, setAvailableStudents] = React.useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const reactTable = React.useRef();
    const timer = React.useRef();

    // Determine if we are creating or editing
    var editing = false;
    var guardianId = '';
    if ( props.location.pathname.split('/')[4] === 'edit'){
        editing = true;
        guardianId = props.location.pathname.split('/')[3];
    }
    
    const [ formState, dispatch, onChange ] = ControlledInputInternals();

    // Inputs
    const { first_name, last_name, email } = formState;
    
    // Initialize page
    React.useEffect(() => {
        async function doStuff(schoolId, guardianId) {
                if (editing) {
                let r = await getGuardian(schoolId, guardianId);
                if (r.ok) {
                    setGuardian(r.data)
                    // Set initial form values using dispatch function from useReducer in ControlledInputInternals
                    for (var key in r.data) {
                        dispatch({ field: key, value: r.data[key]})
                    }
                    setSelectedStudents(r.data.school_students)
                    setLoading(false)
                }
                else {
                    window.location = '/admin'
                }
                
                // May not need to populate available students table here
                // handleOnFetchData should fire on page load
                // let r2 = await listStudents(schoolId);
                // if (r2.ok) {
                //     let apiStudents = r2.data.results
                //     r.data.students.map(s => {
                //         remove(apiStudents, (s2) => {
                //             return s.id == s2.id
                //         })
                //     })
                //     setAvailableStudents(apiStudents)
                //     setLoading(false)
                // }
                // else {
                //     window.location = '/admin'
                // }
            }
            else {
                setGuardian({students: []})
                setLoading(false);
            }
        }
        doStuff(currentSchool.value, guardianId);
        // getGuardian(currentSchool.value, guardianId)
        //     .then(r => {
        //         if (r.ok) {
        //             setGuardian(r.data)
        //             //setFormState(r.data.school_staff_grants_mapping)
        //             setSelectedStudents(r.data.students)
        //             setLoading(false)
        //         }
        //         else {
        //             window.location = '/admin'
        //         }
        //     })
        //     .then(r => {
        //         return listStudents(currentSchool.value)
        //     })
        //     .then(r => {
        //         console.log(selectedStudents)
        //     })
    }, [])


    // Update available students table on selectedStudents change
    React.useEffect(() => {
        async function doStuff() {
            setTableLoading(true)
            const params = make_params_from_react_table(reactTable.current.state)
            const r = await listStudents(currentSchool.value, params)
            let apiStudents = r.data.results
            selectedStudents.map(s => {
                remove(apiStudents, (s2) => {
                    return s.id == s2.id
                })
            })
            setAvailableStudents(apiStudents)
            setPages(r.data.pages)
            //setCurrentPage()
            setTableLoading(false)
        }
        // Don't fire on initial page load. Intial data load is handled in useEffect
        if (!loading){
            doStuff();
        }   
    }, [selectedStudents])


    function createCurrentStudentCheckboxes() {
        let checkboxes = [];
        
        selectedStudents.map(s => {
            checkboxes.push(
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedStudents.includes(s)}
                            value={s.id}
                            onChange={handleChange(s)}
                        />
                    }
                    label={s.first_name + ' ' + s.last_name}
                />
            )
        })

        return checkboxes
    }

    // Handle uncheck for selected students
    const handleChange = student => event => {
        setSelectedStudents(without(selectedStudents, student))
        //setFormState({ ...formState, [id]: event.target.checked});
    };


    async function handleUpdateGuardian() {
        setSubmitting(true);
        setSuccess(false);
        let currentStudents = guardian.school_students || [];
        let newStudents = {add: [], rm: []};

        selectedStudents.map(s => {
            let sIn = some(currentStudents, {id: s.id});
            // Add student
            if (!sIn) {
                newStudents.add.push(s.id)
            }
        })
        
        currentStudents.map(s => {
            let sIn = some(selectedStudents, {id: s.id});
            // Remove student
            if (!sIn) {
                newStudents.rm.push(s.id)
            }
        })
        var r = null;
        var snackbarText = '';
        if (editing) {
            r = await updateGuardian(
                currentSchool.value,
                guardianId,
                {user: {first_name: formState.first_name, last_name: formState.last_name, email: formState.email},
                students: newStudents});
            snackbarText = 'updated'
        }
        else {
            r = await createGuardian(currentSchool.value, {user: formState, students: newStudents});
            snackbarText = 'created'
        } 
        // timer.current = setTimeout(() => {
        //     setSuccess(true);
        //     setSubmitting(false);
        // }, 2000)
        setSubmitting(false);
        if (r.ok) {
            enqueueSnackbar('Guardian ' + snackbarText, {variant: 'success'});
            setGuardian(r.data);
            setSuccess(true);
            if (!editing) {
                history.push('/admin/guardians/' + r.data.id + '/edit')
            }
        } else {
            enqueueSnackbar('An error occurred', {variant: 'error'});
        }
    }

    // Debounce listStudents API call so it doesn't fire on every filter keystroke
    const listStudentsDebounced = useConstant(() =>
        AwesomeDebouncePromise(
            listStudents,
            400
        )
    );

    async function handleOnFetchData(state, instance) {
        setTableLoading(true)
        const params = make_params_from_react_table(state)
        const r = await listStudentsDebounced(currentSchool.value, params)
        let apiStudents = r.data.results
        selectedStudents.map(s => {
            remove(apiStudents, (s2) => {
                return s.id == s2.id
            })
        })
        setAvailableStudents(apiStudents)
        setPages(r.data.pages)
        //setCurrentPage()
        setTableLoading(false)
    }

    function rowHandlers(state, rowInfo, column, instance) {
        return{
            onClick: e => {
                setSelectedStudents([...selectedStudents, rowInfo.original])
            },
            style: {
                cursor: 'pointer',
            }
        } 
    }

    const columns = [
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'Grade Level',
            accessor: 'grade_level',
        },
    ]

    if (loading) {
        return (null)
    } else{
        return (
            <Paper
                className={classes.pagePaper}
            >
                <strong>Guardian Information</strong>
                <br/><br/>
                <div>
                    <TextField
                        id='first_name'
                        label='First Name'
                        variant='outlined'
                        value={first_name}
                        onChange={onChange}
                        style={{marginRight: '1em'}}
                    />
                    <TextField
                        id='last_name'
                        label='Last Name'
                        variant='outlined'
                        value={last_name}
                        onChange={onChange}
                    />
                </div>
                <br/>
                <div>
                    <TextField
                        disabled={editing}
                        id='email'
                        label='Email'
                        variant='outlined'
                        value={email}
                        onChange={onChange}
                        style={{width: '26em'}}
                    />
                </div>
                <br/>
                <strong>Assigned Students</strong>
                <br/><br/>
                <FormGroup>
                    {createCurrentStudentCheckboxes()}
                </FormGroup>
                <br/>
                <strong>Available Students</strong>
                <br/><br/>
                <ReactTable
                    ref={reactTable}
                    data={availableStudents}
                    columns={columns}
                    //page={currentPage}
                    defaultPageSize={10}
                    filterable={true}
                    loading={tableLoading}
                    onFetchData={handleOnFetchData}
                    getTrProps={rowHandlers}
                    className='-highlight'
                    pages={pages}
                    manual
                />
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    {/* <div className={classes.buttonRoot}>
                        <div className={classes.buttonWrapper}>
                            <Button
                                color='primary'
                                variant='contained'
                                className={buttonClassname}
                                onClick={handleUpdateGuardian}
                                disabled={submitting}
                            >
                                Update Guardian
                            </Button>
                            {submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </div> */}
                    <SubmitButton
                        submitting={submitting}
                        success={success}
                        buttonText={editing ? 'Update Guardian' : 'Create Guardian'}
                        onClick={handleUpdateGuardian}
                    />
                </Grid>
            </Paper>
        )
    }
    
}