import { api_call, make_response_object } from 'api/base'


export async function getPurchasesReport(schoolId, storeItemId) {
    const params = {format: 'json'}
    const r = await api_call('/schools/' + schoolId + '/store_items/' + storeItemId + '/reports/purchases', 'GET', {}, params)
    return await make_response_object(r)
}


export async function getByStudentReport(schoolId, storeItemId) {
    const params = { format: 'json' }
    const r = await api_call('/schools/' + schoolId + '/store_items/' + storeItemId + '/reports/by_student', 'GET', {}, params)
    return await make_response_object(r)
}


export async function getLunchesFRLSummaryReport(schoolId, params) {
    const r = await api_call(
        'schools/' + schoolId + '/reports/categories/lunches/frl_summary',
        'GET',
        {},
        params
    )
    return await make_response_object(r)
}