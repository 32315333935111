import { api_call, make_response_object } from 'api/base'
import { updateSchool } from './school'


export async function getSchoolSettings(schoolId) {
    const r = await api_call(
        '/schools/' + schoolId + '/school_settings'
    )
    return await make_response_object(r)
}


export async function updateSchoolSettings(schoolId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/school_settings',
        'PUT',
        body
    )
    return await make_response_object(r)
}