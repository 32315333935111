import React from 'react'


export const UserSettingsContext = React.createContext(['', () => {}])

export const UserSettingsProvider = (props) => {
    const [userSettings, setUserSettings] = React.useState({})

    let cSettings = localStorage.getItem('userSettings')
    if (cSettings === null) {
        cSettings = {}
    }

    else {
        cSettings = JSON.parse(cSettings)
    }

    function updateUserSetting(setting, value) {
        cSettings[setting] = value
        localStorage.setItem('userSettings', JSON.stringify(cSettings))
        setUserSettings(cSettings)
    }

    return (
        <UserSettingsContext.Provider value ={[cSettings, updateUserSetting]}>
            {props.children}
        </UserSettingsContext.Provider>
    )
}