import React from 'react'
import { getStoreItem } from 'api/storeitems'
import { useSnackbar } from 'notistack';
import { make_filter_params } from 'api/base';
import Preloader from 'components/Preloader/Preloader';
import { SchoolContext } from 'components/SchoolProvider.js'
import { UserContext } from 'components/UserProvider.js'
import { useHistory, Link } from "react-router-dom";
import { Typography, TableContainer, Table, TableBody, Paper, Button, Select, FormControl, InputLabel, MenuItem, TextField, Grid, Input, ListItemText, Checkbox } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { ControlledInputInternals } from 'components/CustomInput/CustomInput';
import { MaterialNumberFormat } from 'components/CustomInput/NumberFormat.js'
import UserFormSelect from 'components/CustomInput/UserFormSelect'
import { SubmitButton } from 'components/CustomButtons/Button.js'
import { updateStoreItem } from 'api/storeitems';
import { createStoreItem } from 'api/storeitems';
import { getStoreGroup } from 'api/storegroups';
import * as moment from 'moment'
import { updateStoreGroup } from 'api/storegroups';
import { createStoreGroup } from 'api/storegroups';


const useStyles = makeStyles(styles)


export default function EditBeforeAfterCarePlanGroup(props) {
    const classes = useStyles()
    const currentSchool = React.useContext(SchoolContext)[0]
    const currentUser = React.useContext(UserContext)[0]
    const [loading, setLoading] = React.useState(true);
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [group, setGroup] = React.useState({})
    let history = useHistory()

    const { enqueueSnackbar } = useSnackbar();

    // Determine if we are creating or editing
    var editing = false;
    var groupId = '';
    if (props.location.pathname.split('/')[5] === 'edit') {
        editing = true;
        groupId = props.location.pathname.split('/')[4];
    }

    const [formState, dispatch, basicInputOnChange, onChange] = ControlledInputInternals()
    // Inputs
    const inputs = ['display_name']

    // Childcare group fields
    const [fFormState, fDispatch, fBasicInputOnChange, fOnChange] = ControlledInputInternals()
    const fInputs = ['subscription_exclude_billing_months']


    React.useEffect(() => {
        async function init() {
            if (editing) {
                const r = await getStoreGroup(currentSchool.value, groupId)
                if (!r.ok) {
                    window.location = '/admin'
                }
                setGroup(r.data)
                inputs.map(key => {
                    onChange(key, r.data[key])
                })
                if (r.data.childcare_group_fields !== null) {
                    fInputs.map(key => {
                        fOnChange(key, r.data.childcare_group_fields[key])
                    })
                }
                fOnChange('group_plan_type', 'monthly') 
            }
            else {
                fOnChange('subscription_exclude_billing_months', [])
                fOnChange('group_plan_type', 'monthly') 
            }
            
            setLoading(false)
        }

        init()
    }, [])


    async function handleUpdateGroup(e) {
        e.preventDefault()
        let form = document.querySelector('form')
        if (!form.reportValidity()) {
            return
        }
        setSubmitting(true)
        setSuccess(false)
        let r = null
        let snackbarText = ''
        if (editing) {
            r = await updateStoreGroup(
                currentSchool.value,
                groupId,
                {...formState, childcare_group_fields: fFormState}
            )
            snackbarText = 'updated'
        }
        else {
            r = await createStoreGroup(
                currentSchool.value,
                {...formState, category_type: 'childcare', childcare_group_fields: fFormState}
            )
            snackbarText = 'created'
        }
        setSubmitting(false)
        if (!r.ok) {
            enqueueSnackbar('An error occurred', { variant: 'error' })
            return
        }
        enqueueSnackbar('Plan group ' + snackbarText, { variant: 'success' })
        setGroup(r.data)
        setSuccess(true)
        if(!editing) {
            history.push('/admin/before_after_care/plan_groups/' + r.data.id + '/edit')
        }
    }


    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]


    if (loading) { return <Preloader /> }

    return (
        <Paper
            className={classes.pagePaper}
        >
            <form>
                <Typography>
                    Plan Type: Monthly
                </Typography>
                <br /><br />
                <TextField
                    id='display_name'
                    label='Plan Group Name'
                    variant='outlined'
                    value={formState.display_name}
                    onChange={basicInputOnChange}
                    required
                />
                <br /><br />
                <Typography>
                    Months to exclude from automatic billing:
                </Typography>
                <FormControl
                    style={{minWidth: '200px'}}
                >
                    <Select
                        id='subscription_exclude_billing_months'
                        multiple
                        value={fFormState.subscription_exclude_billing_months}
                        onChange={(e) => fOnChange('subscription_exclude_billing_months', e.target.value)}
                        input={<Input />}
                        renderValue={(selected) => {
                            const sMN = selected.map(s => months[s-1])
                            return sMN.join(', ')
                        }
                        }
                    >
                        {months.map((name, i) => (
                            <MenuItem
                                key={i+1}
                                value={i+1}
                            >
                                <Checkbox checked={
                                    fFormState.subscription_exclude_billing_months.indexOf(i+1) > -1
                                    }
                                />
                                <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <br />
                <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                >
                    <SubmitButton
                        submitting={submitting}
                        success={success}
                        buttonText={editing ? 'Update Plan Group' : 'Create Plan Group'}
                        onClick={handleUpdateGroup}
                    />
                </Grid>
            </form>
        </Paper>
    )
}