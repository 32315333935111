import { api_call, make_response_object } from 'api/base'


export async function listUserForms(schoolId, params) {
    const r = await api_call('/schools/' + schoolId + '/user_forms', 'GET', {}, params)
    return await make_response_object(r)
}


export async function getUserForm(schoolId, userFormId) {
    const r = await api_call('/schools/' + schoolId + '/user_forms/' + userFormId, 'GET')
    return await make_response_object(r)
}


export async function createUserForm(schoolId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/user_forms',
        'POST',
        body
    )
    return await make_response_object(r)
}


export async function updateUserForm(schoolId, userFormId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/user_forms/' + userFormId,
        'PUT',
        body
    )
    return await make_response_object(r)
}


export async function deleteUserForm(schoolId, userFormId) {
    const r = await api_call(
        '/schools/' + schoolId + '/user_forms/' + userFormId,
        'DELETE'
    )
    return await make_response_object(r)
}
