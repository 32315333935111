import React from 'react';
import { faBusAlt, faDollarSign, faHandHoldingUsd, faShoppingCart, faTrashAlt, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { faBellSchool } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, TableBody, TableContainer, List, ListItem, ListItemIcon, Button, Box, TextField, FormControl, InputLabel, MenuItem, FormLabel, Checkbox } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from "@material-ui/core/styles";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { SchoolContext } from 'components/SchoolProvider.js';
import { UserContext } from 'components/UserProvider.js';
import { useSnackbar } from 'notistack';
import useConstant from 'use-constant'
import currency from 'currency.js';
import stripeLogo from 'assets/img/powered_by_stripe.png';
import { calculateTotals, checkout } from 'api/cart';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-table-6/react-table.css';
import { make_filter_params } from 'api/base';
import { listStoreItems } from 'api/storeitems';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { listCategories } from 'api/category';
import { getGuardian } from 'api/guardians'
import GuardiansPage from 'views/Guardians/Guardians'
import ItemView from 'views/OnSiteTransaction/ItemView'
import Select from '@material-ui/core/Select';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import 'assets/css/stripeStyle.css';
import { SubmitButton } from 'components/CustomButtons/Button';
import Preloader from 'components/Preloader/Preloader';
import { api_base } from 'api/base';
import { listUsers} from 'api/users'
import { listCustomerOrders } from 'api/customerorders';
import { getCustomerOrder } from 'api/customerorders';
import { refundOrderItems } from 'api/orderitems'

const useStyles = makeStyles(styles)


export default function Refund(props) {
    const classes = useStyles()
    const currentSchool = React.useContext(SchoolContext)[0]
    const currentUser = React.useContext(UserContext)[0]
    const [activeStep, setActiveStep] = React.useState(0)
    const { enqueueSnackbar } = useSnackbar();
    const [transactions, setTransactions] = React.useState([])
    const [selectedTransaction, setSelectedTransaction] = React.useState({})
    const [searching, setSearching] = React.useState(false)
    const [users, setUsers] = React.useState([])
    const [email, setEmail] = React.useState('')
    const [selectedUser, setSelectedUser] = React.useState({})
    // const tZ = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
    const tZ = Intl.DateTimeFormat().resolvedOptions().timeZone
    const [selectedItems, setSelectedItems] = React.useState([])
    const [refundTotal, setRefundTotal] = React.useState(currency(0))
    const [success, setSuccess] = React.useState(false)
    const [submitting, setSubmitting] = React.useState(false)

    const steps = [
        'Select Customer',
        'Select Transaction',
        'Select Items',
        'Confirm'
    ]


    function handleToggle(v) {
        let newChecked = [...selectedItems]
        const i = selectedItems.indexOf(v)                                 
        if (i === -1) {
            newChecked.push(v)
        }
        else {
            newChecked.splice(i, 1)
        }
        setSelectedItems(newChecked)
    }


    async function handleUserSearch(value) {
        setSearching(true)
        setEmail(value)
        if (value) {
            const r = await listUsers(
                make_filter_params([{
                    field: 'email',
                    op: 'contains',
                    value: value,
                }])
            )
            setUsers(r.data.results)
        }
        else {
            setUsers([])
        }
        setSearching(false)
    }


    const handleUserSearchDebounced = useConstant(() => 
    AwesomeDebouncePromise(
        handleUserSearch,
        250
    ))


    async function handleTransactionSearch(value) {
        setSearching(true)
        const r = await listCustomerOrders(
            selectedUser.id,
            {...make_filter_params([{
                field: 'id',
                op: 'contains',
                value: value,
            }]), ...{school_id: currentSchool.value}}
        )
        setTransactions(r.data.results)
        setSearching(false)
    }


    const handleTransactionSearchDebounced = useConstant(() =>
        AwesomeDebouncePromise(
            handleTransactionSearch,
            250
        ))


    function handleCustomerPick(user) {
        setSelectedUser(user)
        setActiveStep(1)
    }


    async function handleTransactionPick(t) {
        setActiveStep(2)
        setSearching(true)

        const r = await getCustomerOrder(selectedUser.id, t.id)
        setSelectedTransaction(r.data)
        console.log(r.data)

        setSearching(false)
    }


    async function handleDoRefund() {
        setSubmitting(true)
        const r = await refundOrderItems(
            selectedUser.id,
            { order_item_ids: selectedItems }
        )
        if (!r.ok) {
            enqueueSnackbar('Refund Failed', {variant: 'error'})
            setSubmitting(false)
            return
        }
        enqueueSnackbar('Refund Complete', {variant: 'success'})
        setSuccess(true)
        setSubmitting(false)
    }


    React.useEffect(() => {
        switch (activeStep) {
            case 1:
                async function init() {
                    const r = await listCustomerOrders(
                        selectedUser.id,
                        { school_id: currentSchool.value }
                    )
                    setTransactions(r.data.results)
                    console.log(r)
                }
                init()

            case 3:
                let rT = currency(0)
                selectedItems.map(iD => {
                    const oI = selectedTransaction.order_items.filter(i => i.id === iD)[0]
                    rT = rT.add(currency(oI.payout_amount))
                    console.log(rT)
                })
                rT.divide(100)
                setRefundTotal(rT.divide(100))
                
        }
    }, [activeStep])


    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <>
                        <Paper style={{padding: '1em'}}>
                            <TextField
                                id='customerEmail'
                                label="Search by Customer's Email"
                                onChange={e => handleUserSearchDebounced(e.target.value)}
                                style={{minWidth: '22em'}}
                            />
                            <br/><br/>
                            {searching ? <Preloader /> :
                            <List>
                                {users.length > 0 && email &&
                                    users.map(u => (
                                        <ListItem
                                            key={u.id}
                                            button
                                            onClick={() => handleCustomerPick(u)}
                                        >
                                            <ListItemText
                                                primary={u.last_name + ', ' + u.first_name}
                                                secondary={u.email}
                                            />
                                        </ListItem>
                                    ))
                                }
                                {users.length === 0 && email &&
                                    <Typography>No customers found</Typography>
                                }
                            </List>
                            }
                        </Paper>
                    </>
                )
            case 1:
                return (
                    <Paper style={{ padding: '1em' }}>
                        <Typography>
                            <strong>Customer:</strong> {selectedUser.first_name} {selectedUser.last_name}
                        </Typography>
                        <TextField
                            id='transactionId'
                            label='Search by Customer Order ID'
                            style={{ minWidth: '22em' }}
                            onChange={e => handleTransactionSearch(e.target.value)}
                        />
                        <br/><br/>
                        {searching ? <Preloader /> :
                        <List>
                            {transactions.length > 0 ?
                                transactions.map(t => {
                                    let d = new Date(Date.parse(t.create_time))
                                    d = d.toLocaleString('en-US', { timeZone: tZ, hour: '2-digit', minute: '2-digit', year: 'numeric', month: 'numeric', day: 'numeric'})
                                    return (
                                    <ListItem
                                        key={t.id}
                                        button
                                    >
                                        <ListItemText
                                            primary={d}
                                            secondary={t.id}
                                            onClick={() => handleTransactionPick(t)}
                                        />
                                    </ListItem>
                                    )
                                })
                                :
                                <Typography>No orders found</Typography>
                            }
                        </List>
                        }
                    </Paper>
                )
            case 2:
                let d = new Date(Date.parse(selectedTransaction.create_time))
                d = d.toLocaleString('en-US', { timeZone: tZ, hour: '2-digit', minute: '2-digit', year: 'numeric', month: 'numeric', day: 'numeric' })
                return (
                    <Paper style={{ padding: '1em' }}>
                        <Typography>
                            <strong>Customer:</strong> {selectedUser.first_name} {selectedUser.last_name}
                        </Typography>
                        <Typography>
                            <strong>Transaction:</strong> {selectedTransaction.id} at {d}
                        </Typography>
                        <List>
                            {searching ? <Preloader /> :
                            selectedTransaction.order_items.map(oI => {
                                let lunchText = ''
                                if (oI.category_type === 'lunches') {
                                    if (oI.student_permanent) {
                                        lunchText = ' - ' + oI.student_permanent.first_name + ' ' + oI.student_permanent.last_name
                                    }
                                }
                                if (!oI.refund_status ) {
                                    return (
                                        <ListItem
                                            key={oI.id}
                                            role={undefined}
                                            dense
                                            button
                                            onClick={() => handleToggle(oI.id)}
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge='start'
                                                    disableRipple
                                                    checked={selectedItems.indexOf(oI.id) !== -1}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={oI.category_type === 'lunches' ? oI.store_item_permanent.display_name + ' ' + oI.lunch_date + lunchText + ' (Lunches)': oI.store_item_permanent.display_name}
                                                secondary={currency(oI.payout_amount).divide(100).format()}
                                            />
                                        </ListItem>
                                    )
                                }
                                
                            })
                            }
                        </List>
                    </Paper>
                )

            case 3:
                return(
                    <Paper style={{ padding: '1em' }}>
                        <Typography>
                            <strong>Customer:</strong> {selectedUser.first_name} {selectedUser.last_name}
                        </Typography>
                        <Typography>
                            <strong>Transaction:</strong> {selectedTransaction.id} at {d}
                        </Typography>
                        <List>
                            {selectedItems.map(iD => {
                                const oI = selectedTransaction.order_items.filter(i => i.id === iD)[0]
                                return (
                                    <ListItem
                                        key={iD}
                                        dense
                                    >
                                        <ListItemText
                                            primary={oI.store_item_permanent.display_name}
                                            secondary={currency(oI.payout_amount).divide(100).format()}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                        <Typography>
                            <strong>Total: </strong>
                            {refundTotal.format()}
                        </Typography>
                    </Paper>
                )
        }
    }


    return (
        <Paper className={classes.pagePaper}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {}
                    const labelProps = {}
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            {getStepContent(activeStep)}
            <br/>
            <Grid
                container
                direction='row'
                justify='flex-end'
                alignItems='center'
                spacing={1}
            >
                <Grid item>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            setActiveStep(0)
                            setSelectedTransaction({})
                            setSelectedItems([])
                            setEmail('')
                            setSelectedUser({})
                            setSuccess(false)
                        }}
                        disabled={activeStep === 0 || submitting}
                    >
                        Start Over
                    </Button>
                </Grid>
                {activeStep === 2 &&
                <Grid item>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => setActiveStep(3)}
                        disabled={selectedItems.length === 0}
                    >
                        Continue
                    </Button>
                </Grid>
                }
                {activeStep === 3 &&
                <Grid item>
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={selectedItems.length === 0}
                        onClick={(handleDoRefund)}
                        disabled={success || submitting}
                    >
                        Complete Refund
                    </Button>
                </Grid>
                }
        </Grid>
        </Paper>
    )
}