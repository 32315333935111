import Paper from '@material-ui/core/Paper';
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import SchoolVerification from "components/Dashboard/SchoolVerification";
import { UserContext } from 'components/UserProvider.js';
import React from "react";




const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const currentUser = React.useContext(UserContext)[0]

  return (
    <Paper
      className={classes.pagePaper}
    >
      <h3>Welcome to Charter Pay</h3>
      {currentUser.rep_for_schools.length !== 0 &&
        (currentUser.rep_for_schools.map(s => (
          <SchoolVerification s={s} school/>
        )))
      }
      {currentUser.rep_for_payout_accounts.map(s => (
        <SchoolVerification s={s} payout_account/>
      ))}
    </Paper>
  );
}
