import React from 'react';
import StoreItemTabs from 'components/StoreItemTabs/StoreItemTabs'


export default function ActivitiesPage() {


    return (
        <StoreItemTabs
            catUrlSlug='activities'
            catSingular='Activity'
            catPlural='Activities and Field Trips'
            catEnum='activities'
        />
    )

}