import React from 'react'
import { listStoreItems } from 'api/storeitems'
import { useSnackbar } from 'notistack';
import { make_filter_params } from 'api/base';
import Preloader from 'components/Preloader/Preloader';
import { SchoolContext } from 'components/SchoolProvider.js'
import { UserContext } from 'components/UserProvider.js'
import { useHistory, Link } from "react-router-dom";
import { Typography, TableContainer, Table, TableBody, Paper, Button, TableRow, ListItemText, TableCell, Grid, Switch, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { listStoreGroups } from 'api/storegroups';
import { api_base } from 'api/base';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { MaterialNumberFormat } from 'components/CustomInput/NumberFormat';
import { getSchoolChildcareSettings, updateSchoolChildcareSettings } from 'api/schoolchildcaresettings';
import { SubmitButton } from 'components/CustomButtons/Button';
import UserFormSelect from 'components/CustomInput/UserFormSelect';
import { ControlledInputInternals } from 'components/CustomInput/CustomInput';

const useStyles = makeStyles(styles);



export default function BeforeAfterCarePage(props) {
    const classes = useStyles();
    const currentSchool = React.useContext(SchoolContext)[0]
    const currentUser = React.useContext(UserContext)[0]
    const [loading, setLoading] = React.useState(true);
    const [childcareItemsList, setChildcareItemsList] = React.useState([])
    const [groupList, setGroupList] = React.useState([])
    const [useDefaultRegFee, setUseDefaultRegFee] = React.useState(false)
    const [default_registration_fee_amount, set_default_registration_fee_amount] = React.useState(null)
    //const [default_registration_form_id, set_default_registration_form_id ] = React.useState(null)
    const [submitting, setSubmitting] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const [formState, dispatch, basicInputOnChange, onChange] = ControlledInputInternals()
    const [registration_fee_charge_strategy, set_registration_fee_charge_strategy] = React.useState(null)

    let history = useHistory();

    const { enqueueSnackbar } = useSnackbar();


    React.useEffect(() => {
        async function init() {
            let filters = []
            filters.push({ field: 'category_type', op: 'eq', value: 'childcare'})
            const params = make_filter_params(filters)
            const r = await listStoreItems(currentSchool.value, params)
            if (!r.ok) {
                enqueueSnackbar('An error occurred.', {variant: 'error'})
                setLoading(false)
                return
            }
            setChildcareItemsList(r.data.results)

            let filters1 = []
            filters1.push({ field: 'category_type', op: 'eq', value: 'childcare' })
            filters1.push({ field: 'childcare_group_fields.group_plan_type', op: 'eq', value: 'monthly'})
            const params1 = make_filter_params(filters1)
            const r1 = await listStoreGroups(currentSchool.value, params1)
            if (!r1.ok) {
                enqueueSnackbar('An error occurred.', { variant: 'error' })
                setLoading(false)
                return
            }
            setGroupList(r1.data.results)

            const r2 = await getSchoolChildcareSettings(currentSchool.value)
            if (!r2.ok) {
                enqueueSnackbar('An error occurred.', { variant: 'error' })
                setLoading(false)
                return
            }
            if (r2.data.default_registration_fee_amount) {
                setUseDefaultRegFee(true)
                set_default_registration_fee_amount(r2.data.default_registration_fee_amount)
            }
            if (r2.data.default_registration_form_id) {
                onChange('default_registration_form_id', r2.data.default_registration_form_id)
            }

            set_registration_fee_charge_strategy(r2.data.registration_fee_charge_strategy)

            setLoading(false)
            console.log(useDefaultRegFee)
        }

        init()
    }, [currentSchool])


    async function handleSubmit() {
        setSuccess(false)
        setSubmitting(true)
        let body = {}

        if (!useDefaultRegFee) {
            body.default_registration_fee_amount = null
        }
        else {
            body.default_registration_fee_amount = default_registration_fee_amount
        }

        if (!formState.default_registration_form_id) {
            body.default_registration_form_id = null
        }
        else {
            body.default_registration_form_id = formState.default_registration_form_id
        }
        body.registration_fee_charge_strategy = registration_fee_charge_strategy

        const r = await updateSchoolChildcareSettings(currentSchool.value, body)
        if (!r.ok) {
            enqueueSnackbar('An error occurred.', { variant: 'error' })
            setSubmitting(false)
            return
        }
        setSubmitting(false)
        setSuccess(true)
        enqueueSnackbar('Registration Settings Updated', {variant: 'success'})
    }


    if (loading) { return <Preloader />}

    return(
        <Paper className={classes.pagePaper}>
            <Grid
                container
                direction='row'
                justify='flex-end'
                alignItems='center'
                spacing={1}
            >
                <Grid item>
                    <a href={api_base + '/schools/' + currentSchool.value + '/reports/childcare_roster'} download>
                        <Button variant='contained' color='secondary'>
                            Export CSV: Full Roster
                        </Button>
                    </a>
                </Grid>
                <Grid item>
                    <a href={api_base + '/schools/' + currentSchool.value + '/reports/categories/childcare'} download>
                        <Button variant='contained' color='secondary'>
                            Export CSV: Childcare Report
                        </Button>
                    </a>
                </Grid>
            </Grid>
            <Link to='before_after_care/plan_groups/new'>
                <Button
                    variant='contained'
                    color='primary'
                >
                    Create New Plan Group
                </Button>
            </Link>
            <br /><br />
            <Paper className={classes.pageSubPaper}>
            <Typography style={{fontSize: 26}}>
                Plan Groups
            </Typography>
            {groupList.length === 0 ? 'No plan groups found.' :
                <TableContainer>
                    <Table>
                        <TableBody>
                            {groupList.map(group => (
                                <TableRow
                                    hover
                                    key={group.id}
                                    style={{cursor: 'pointer'}}
                                    onClick={() => history.push('/admin/before_after_care/plan_groups/' + group.id + '/edit')}
                                >
                                    <TableCell component='th' scope='row'>
                                        {group.display_name}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            </Paper>
            <hr />
            <Paper className={classes.pageSubPaper}>
                <Typography style={{fontSize: 26}}>
                    Registration
                </Typography>
                <Paper
                    className={classes.studentSelectionPaper}
                >
                <Switch
                    id={'default_reg_fee'}
                    checked={useDefaultRegFee}
                    onChange={(e) => setUseDefaultRegFee(e.target.checked)}
                    color='primary'
                />
                <label>Use a Standard Registration Fee</label>
                <br/>
                {useDefaultRegFee &&
                <>
                    <MaterialNumberFormat
                        id='default_registration_fee_amount'
                        value={default_registration_fee_amount}
                        onChange={(e) => set_default_registration_fee_amount(e.target.value)}
                        label='Amount'
                        required
                        variant='outlined'
                    />
                 </>
                }
                </Paper>
                <Paper className={classes.studentSelectionPaper}>
                    <FormControl style={{minWidth: '250px'}}>
                        <InputLabel id='registration_fee_charge_strategy-label'>Charge registration fee once per...</InputLabel>
                        <Select
                            labelId='registration_fee_charge_strategy-label'
                            id='registration_fee_charge_strategy'
                            value={registration_fee_charge_strategy}
                            onChange={(e) => set_registration_fee_charge_strategy(e.target.value)}
                        >
                            <MenuItem value='per_student'>Student</MenuItem>
                            <MenuItem value='per_family'>Family</MenuItem>
                        </Select>
                    </FormControl>
                </Paper>
                <UserFormSelect
                    id='default_registration_form_id'
                    switchText='Use Standard Registration Form'
                    value={formState.default_registration_form_id}
                    onChange={onChange}
                />

                <SubmitButton
                    onClick={handleSubmit}
                    submitting={submitting}
                    success={success} 
                    buttonText={'Update Registration Settings'}
                />
            </Paper>
            <hr />
            <Link to='before_after_care/new'>
                <Button
                    variant='contained'
                    color='primary'
                >
                    Create New Plan
                </Button>
            </Link>
            <br /><br />
            <Paper className={classes.pageSubPaper}>
            <Typography style={{fontSize: 26}}>
                Monthly Plans
            </Typography>
            {childcareItemsList.length === 0 ? 'No monthly plans found.' : 
                <TableContainer>
                    <Table>
                        <TableBody>
                            {childcareItemsList.map(item => (
                                (item.childcare_fields.plan_type === 'monthly' &&
                                    <TableRow
                                        hover
                                        key={item.id}
                                        style={{cursor: 'pointer'}}
                                        onClick={() => history.push('/admin/before_after_care/' + item.id + '/edit')}
                                    >
                                        <TableCell component='th' scope='row'>
                                            {item.display_name}
                                        </TableCell>
                                    </TableRow>
                                )
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            </Paper>
            <br /><br />
            <Paper className={classes.pageSubPaper}>
            <Typography style={{ fontSize: 26 }}>
                Weekly Plans
            </Typography>
            {childcareItemsList.length === 0 ? 'No weekly plans found.' :
                <TableContainer>
                    <Table>
                        <TableBody>
                            {childcareItemsList.map(item => (
                                (item.childcare_fields.plan_type === 'weekly' &&
                                    <TableRow
                                        hover
                                        key={item.id}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => history.push('/admin/before_after_care/' + item.id + '/edit')}
                                    >
                                        <TableCell component='th' scope='row'>
                                            {item.display_name}
                                        </TableCell>
                                    </TableRow>
                                )
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            </Paper>
            <br /><br />
            <Paper className={classes.pageSubPaper}>
            <Typography style={{ fontSize: 26 }}>
                Punch Cards
            </Typography>
            {childcareItemsList.length === 0 ? 'No punch cards found.' :
                <TableContainer>
                    <Table>
                        <TableBody>
                            {childcareItemsList.map(item => (
                                (item.childcare_fields.plan_type === 'punch_card' &&
                                    <TableRow
                                        hover
                                        key={item.id}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => history.push('/admin/before_after_care/' + item.id + '/edit')}
                                    >
                                        <TableCell component='th' scope='row'>
                                            {item.display_name}
                                        </TableCell>
                                    </TableRow>
                                )
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            </Paper>
        </Paper>
    )
}