import React from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import { SchoolContext } from 'components/SchoolProvider.js'
import { UserContext } from 'components/UserProvider.js'
import { getStoreItem, createStoreItem, updateStoreItem } from 'api/storeitems'
import { make_filter_params, make_params_from_react_table } from 'api/base.js'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant'
import { useHistory, Link } from "react-router-dom";
import Preloader from 'components/Preloader/Preloader.js'
import { useSnackbar } from 'notistack';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty'
import { SubmitButton } from 'components/CustomButtons/Button.js'
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js'
import { FormGroup, TextField, FormControl, InputLabel, Input, Box, IconButton } from '@material-ui/core';
import tinymce from 'tinymce/tinymce'
//import 'tinymce/themes/silver'
//import 'tinymce/plugins/paste';
//import 'tinymce/plugins/link';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import ReactTablePreloader from 'components/Preloader/ReactTablePreloader';
import { listStudents } from 'api/students.js'
import remove from 'lodash/remove'
import { MaterialNumberFormat } from 'components/CustomInput/NumberFormat.js'
import TinyMCE from 'components/CustomInput/TinyMCE.js'
import StudentSelection from 'components/StudentSelection/StudentSelection'
import StoreItemApproverSelect from 'components/CustomInput/StoreItemApproverSelect'
import ActivityFormSelect from 'components/CustomInput/ActivityFormSelect'
import StaffSelect from 'components/CustomInput/StaffSelect'
import { getStaff } from 'api/staff';
import * as moment from 'moment'
import some from 'lodash/some';
import MultiDayPicker from 'components/CustomInput/MultiDayPicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import DayPicker, { DateUtils } from 'react-day-picker';
import { approveStoreItem } from 'api/storeitems';
import StoreItemPayoutMethodSelect from 'components/CustomInput/StoreItemPayoutMethodSelect';


const useStyles = makeStyles(styles);


export default function EditActivity(props) {
    const classes = useStyles();
    const currentSchool = React.useContext(SchoolContext)[0]
    const currentUser = React.useContext(UserContext)[0]
    const [loading, setLoading] = React.useState(true);
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [selectedStudents, setSelectedStudents] = React.useState([])
    const [focusedInput, setFocusedInput] = React.useState(null);
    const [staffCanApprove, setStaffCanApprove] = React.useState(false)
    const [activity, setActivity] = React.useState({})
    const [selectedDays, setSelectedDays] = React.useState([])
    let history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    // Determine if we are creating or editing
    var editing = false;
    var activityId = '';
    if (props.location.pathname.split('/')[4] === 'edit') {
        editing = true;
        activityId = props.location.pathname.split('/')[3];
    }

    const [formState, dispatch, basicInputOnChange, onChange] = ControlledInputInternals();

    // Inputs
    const inputs = [ 'display_name', 'description',
        'cost', 'approver_id', 'form_id', 'owner_id', 'open_signup', 'payout_method_id']

    
    // activity_fields
    const [fFormState, fDispatch, fBasicInputOnChange, fOnChange] = ControlledInputInternals()

    // Inputs
    const fInputs = [
        'chaperones_needed',
        'chaperone_cost', 'chaperone_form_id', 'parent_drivers_needed',
        'parent_driver_form_id', 'permission_form_id', 'dates', 'collect_permission_form'
    ]


    function handleDayTrash(day) {
        var days = selectedDays.slice()
        const selectedIndex = days.findIndex(selectedDay =>
            DateUtils.isSameDay(selectedDay, day)
        )
        days.splice(selectedIndex, 1)
        setSelectedDays(days)
    }
    

    React.useEffect(() => {
        async function initPage() {
            if (editing) {
                let r = await getStoreItem(currentSchool.value, activityId)
                if (r.ok) {
                    setActivity(r.data)
                    setSelectedStudents(r.data.students)
                    inputs.map(key => {
                        dispatch({field: key, value: r.data[key]})
                    })
                    if (r.data.activity_fields !== null) {
                        fInputs.map(key => {
                            console.log(key, r.data.activity_fields[key])
                            if (['dates'].includes(key)) {
                                let dates = []
                                r.data.activity_fields[key].map(d => {
                                    dates.push(new Date(d))
                                })
                                r.data.activity_fields[key] = dates
                            }
                            fDispatch({ field: key, value: r.data.activity_fields[key] })
                        })
                    }
                    setLoading(false)
                }
                else {
                    window.location = '/admin'
                }
            }
            else {
                setActivity({students: []})
                fDispatch({ field: 'dates', value: []})
                setLoading(false)
            }
        }
        initPage()
    }, [])


    React.useEffect(() => {
        // Handle case where currentUser is not staff for currentSchool
        const pem = !currentUser.isSchoolStaff
            ? false : currentUser.school_staff_grants_mapping.approve_store_item_requests
        setStaffCanApprove(pem)
    }, [currentUser])


    async function handleApproveActivity() {
        setSubmitting(true)
        const r = await approveStoreItem(
            currentSchool.value,
            activityId
        )
        if (!r.ok) {
            enqueueSnackbar('An error occurred', { variant: 'error' })
            setSubmitting(false)
            return
        }
        setActivity(r.data)
        setSubmitting(false)
        enqueueSnackbar('Activity Approved', { variant: 'success' })
    }


    async function handleUpdateActivity(e) {
        e.preventDefault()
        let form = document.querySelector('form')
        if (!form.reportValidity()) {
            return
        }
        if (fFormState.dates.length === 0) {
            enqueueSnackbar('Please select at least one activity date.', {variant: 'error'})
            return
        }
        setSubmitting(true)
        setSuccess(false)

        let currentStudents = activity.students
        let newStudents = { add: [], rm: [] }

        selectedStudents.map(s => {
            let sIn = some(currentStudents, { id: s.id })
            // Add Student
            if (!sIn) {
                newStudents.add.push(s.id)
            }
        })

        currentStudents.map(s => {
            let sIn = some(selectedStudents, { id: s.id })
            // Remove student
            if (!sIn) {
                newStudents.rm.push(s.id)
            }
        })

        // Sort Dates Ascending
        fFormState.dates.sort((a, b) => a - b)
        // Set first_day and last_day
        fFormState['first_day'] = fFormState.dates[0]
        fFormState['last_day'] = fFormState.dates[fFormState.dates.length - 1]
        console.log(fFormState)

        var r = null
        var snackbarText = ''
        if (editing) {
            r = await updateStoreItem(
                currentSchool.value, activityId,
                { store_item: {...formState, activity_fields: fFormState}, students: newStudents }
            )
            snackbarText = 'updated'
        }
        else {
            // Set storeitem category_type
            // Setting category_id is handled on server
            r = await createStoreItem(
                currentSchool.value,
                { store_item: { ...formState, ...{ category_type: 'activities' }, activity_fields: fFormState }, students: newStudents }
            )
            snackbarText = 'created'
        }
        setSubmitting(false);
        if (r.ok) {
            enqueueSnackbar('Activity ' + snackbarText, { variant: 'success' })
            setActivity(r.data)
            setSuccess(true)
            if (!editing) {
                history.push('/admin/activities/' + r.data.id + '/edit')
            }
        }
        else {
            enqueueSnackbar('An error occurred', { variant: 'error' })
        }
    }


    if (loading) {
        return (null)
    }

    return (
        <Paper
            className={classes.pagePaper}
        >
            <Grid
                container
                direction='row'
                justify='flex-end'
                alignItems='center'
            >
                {(activity.approval_status === 'pending' && (staffCanApprove || currentUser.super_admin_grants.length === 1)) &&
                    <SubmitButton
                        submitting={submitting}
                        success={null}
                        buttonText={'Approve Activity'}
                        onClick={handleApproveActivity}
                        noSubmitType={true}
                        color='default'
                    />
                }
                <Link to={'/admin/activities/' + activityId + '/report_by_student'}>
                    <Button variant='contained' color='secondary'>
                        Reports
                        </Button>
                </Link>
            </Grid>
            <form>
                <Paper
                    className={classes.pageSubPaper}
                >
                    <Typography>
                        <Box fontSize={20}>
                            Basic Information
                        </Box>
                    </Typography>
                    <TextField
                        id='display_name'
                        label='Name'
                        variant='outlined'
                        value={formState.display_name}
                        onChange={basicInputOnChange}
                        margin='normal'
                        required
                    />
                    <br /><br />
                    {!(currentUser.super_admin_grants.length === 1) ? null : (
                        <>
                            <Typography>Select a staff member to own this activity</Typography>
                            <StaffSelect
                                id='owner_id'
                                value={formState.owner_id}
                                onChange={onChange}
                            />
                            <br /> <br />
                        </>
                    )}
                    <TinyMCE
                        id='description'
                        value={formState.description}
                        label='Description'
                        onChange={onChange}
                        loading={loading}
                    />
                    <br /><br />
                    <MaterialNumberFormat
                        id='cost'
                        value={formState.cost}
                        onChange={basicInputOnChange}
                        label='Cost Per Student'
                        required
                        variant='outlined'
                    />
                    {(staffCanApprove || currentUser.super_admin_grants.length === 1) ? null : (
                        <>
                            <br /> <br />
                            <Typography>Select an approver</Typography>
                            <br />
                            <StoreItemApproverSelect
                                id='approver_id'
                                value={formState.approver_id}
                                onChange={onChange}
                            />
                        </>
                    )}
                </Paper>
                <br />
                <Paper
                    className={classes.pageSubPaper}
                >
                    <Typography>
                        <Box fontSize={20}>
                            Select Activity Days
                        </Box>
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item>
                            <MultiDayPicker
                                selectedDays={fFormState.dates}
                                setSelectedDays={(d) => fOnChange('dates', d)}
                                allDates
                            />
                        </Grid>
                        <Grid item>
                            <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
                                {selectedDays.map(day => (
                                    <Box m={1}>
                                        <Grid
                                            container
                                            key={day.getTime()}
                                        >
                                            <Grid item>
                                                <IconButton
                                                    onClick={() => handleDayTrash(day)}
                                                >
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </IconButton>
                                            </Grid>
                                            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                                {day.toLocaleDateString(
                                                    undefined,
                                                    { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
                <br />
                <StudentSelection
                    label='Students'
                    selectedStudents={selectedStudents}
                    setSelectedStudents={setSelectedStudents}
                    open_signup={formState.open_signup}
                    basicInputOnChange={basicInputOnChange}
                />
                <br />
                <ActivityFormSelect
                    formId='chaperone_form_id'
                    switchId='chaperones_needed'
                    label='Chaperones'
                    switchText='Chaperones Needed'
                    switchValue={fFormState.chaperones_needed}
                    formValue={fFormState.chaperone_form_id}
                    onChange={fOnChange}
                    chaperoneCost
                    chaperoneCostValue={fFormState.chaperone_cost}
                    chaperoneCostOnChange={fBasicInputOnChange}
                />
                <br />
                <ActivityFormSelect
                    formId='parent_driver_form_id'
                    switchId='parent_drivers_needed'
                    label='Parent Drivers'
                    switchText='Parent Drivers Needed'
                    switchValue={fFormState.parent_drivers_needed}
                    formValue={fFormState.parent_driver_form_id}
                    onChange={fOnChange}
                />
                <br />
                <ActivityFormSelect
                    formId='permission_form_id'
                    switchId='collect_permission_form'
                    label='Permission Form'
                    switchText='Collect Permission Forms'
                    switchValue={fFormState.collect_permission_form}
                    formValue={fFormState.permission_form_id}
                    onChange={fOnChange}
                />
                {(staffCanApprove || currentUser.super_admin_grants.length === 1) && (
                    <>
                        <br /><br />
                        <StoreItemPayoutMethodSelect
                            id='payout_method_id'
                            value={formState.payout_method_id}
                            onChange={onChange}
                            storeItemId={activityId}
                        />
                    </>
                )}
                <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                >
                    <SubmitButton
                        submitting={submitting}
                        success={success}
                        buttonText={editing ? 'Update Activity' : 'Create Activity'}
                        onClick={handleUpdateActivity}
                    />
                </Grid>
            </form>
        </Paper>
    )
}