import React from 'react'
import { SchoolContext } from 'components/SchoolProvider.js'
import Select from 'react-select'
import {listStoreItemApprovers} from 'api/school.js'


export default function StoreItemApproverSelect(props) {
    const [currentSchool, setcurrentSchool] = React.useContext(SchoolContext)
    const [approvers, setApprovers] = React.useState([])
    const [approversIsLoading, setApproversIsLoading] = React.useState(true)


    React.useEffect(() => {
        setApproversIsLoading(true)
        listStoreItemApprovers(currentSchool.value)
            .then(r => {
                var apiApprovers = r.data.results.map(a => {
                    var as = {};
                    as['value'] = a.id
                    as['label'] = a.last_name + ', ' + a.first_name
                    return as
                })
                setApprovers(apiApprovers)
                setApproversIsLoading(false)
            })
    }, [currentSchool])


    function selectValue() {
        return approvers.filter(a => {
            return a.value === props.value
        })[0]
    }


    return (
        <>
        <Select
            id={props.id}
            options={approvers}
            isClearable={false}
            defaultValue={approvers[0]}
            value={selectValue()}
            isLoading={approversIsLoading}
            onChange={(selected) => props.onChange(props.id, selected.value)}
            placeholder='Select an approver *'
        />
        {!props.disabled && (
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0, position: 'absolute' }}
              value={selectValue()}
              required
            />
            )}
        </>
    )


}