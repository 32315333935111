import React from 'react'
import { getStoreItem } from 'api/storeitems'
import { useSnackbar } from 'notistack';
import { make_filter_params } from 'api/base';
import Preloader from 'components/Preloader/Preloader';
import { SchoolContext } from 'components/SchoolProvider.js'
import { UserContext } from 'components/UserProvider.js'
import { useHistory, Link } from "react-router-dom";
import { Typography, TableContainer, Table, TableBody, Paper, Button, Select, FormControl, InputLabel, MenuItem, TextField, Grid, Menu, Switch } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { ControlledInputInternals } from 'components/CustomInput/CustomInput';
import { MaterialNumberFormat } from 'components/CustomInput/NumberFormat.js'
import UserFormSelect from 'components/CustomInput/UserFormSelect'
import { SubmitButton } from 'components/CustomButtons/Button.js'
import { updateStoreItem } from 'api/storeitems';
import { createStoreItem } from 'api/storeitems';
import { listStoreGroups } from 'api/storegroups';
import { listChildcareEnrollments, bulkUnenrollChildcare, bulkEnrollChildcare } from 'api/childcareenrollments';
import { FilterStateProvider } from 'components/FilterStateContext'
import ReactTable from 'components/Table'
import { listStudents } from 'api/students';
import { api_base } from 'api/base';




export default function ManageRoster(props) {
    const currentSchool = React.useContext(SchoolContext)[0]
    const currentUser = React.useContext(UserContext)[0]
    const [loading, setLoading] = React.useState(true);
    const [enrollments, setEnrollments] = React.useState([])

    const { enqueueSnackbar } = useSnackbar();
    const planId = props.location.pathname.split('/')[3];
    const [plan, setPlan] = React.useState(null)
    const [currentChecked, setCurrentChecked] = React.useState({})
    const [newChecked, setNewChecked] = React.useState({})
    const [currentStus, setCurrentStus] = React.useState([])
    const [availStus, setAvailStus] = React.useState([])
    const [refetch, setRefetch] = React.useState(false)
    const [clearChecked, setClearChecked] = React.useState(false)

    const rosterColumns = React.useMemo(() => [
        {
            Header: 'First Name',
            accessor: 'student.first_name',
        },
        {
            Header: 'Last Name',
            accessor: 'student.last_name',
        },
        {
            Header: 'Grade Level',
            accessor: 'student.grade_level',
        },
    ], [])

    // async function rosterFetchFunction(params = {}) {
    //     return await listChildcareEnrollments(currentSchool.value, planId, params)
    // }

    const rosterFetchFunction = React.useCallback(async (params={}) => {
        return await listChildcareEnrollments(currentSchool.value, planId, params)
    }, [])


    const studentColumns = React.useMemo(() => [
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'Grade Level',
            accessor: 'grade_level',
        },
    ], [])

    async function doFetchStudents(params={}) {
        let r = await listStudents(currentSchool.value, params)
        const r1 = await listChildcareEnrollments(currentSchool.value, planId)

        r1.data.results.map(en => {
            const idx = r.data.results.findIndex(s => s.id === en.student.id)
            if (idx > -1) {
                r.data.results.splice(idx, 1)
            }
        })

        return r
    }

    // async function studentFetchFunction(params={}) {
    //     return await doFetchStudents(params)
    // }

    const studentFetchFunction = React.useCallback(async (params={}) => {
        return await doFetchStudents(params)
    }, [])



    React.useEffect(() => {
        async function init() {
            const r = await getStoreItem(currentSchool.value, planId)
            if (!r.ok) {
                enqueueSnackbar('An error occurred.', {variant: 'error'})
                setLoading(false)
                return
            }
            setPlan(r.data)
            setLoading(false)
        }
        init()
    }, [])


    async function handleUnenroll() {
        console.log(currentChecked)
        let eIds = []
        for (const eId of Object.keys(currentChecked)) {
            eIds.push(eId)
        }
        const r = await bulkUnenrollChildcare(currentSchool.value, planId, eIds)
        if (!r.ok) {
            enqueueSnackbar('An error occurred unenrolling students.', {variant: 'error'})
            return
        }
        enqueueSnackbar('Students unenrolled', {variant: 'success'})
        setRefetch(!refetch)
        setClearChecked(!clearChecked)
    }


    async function handleEnroll() {
        console.log(newChecked)
        let sIds = []
        for (const sId of Object.keys(newChecked)) {
            sIds.push(sId)
        }
        const r = await bulkEnrollChildcare(currentSchool.value, planId, newChecked)
        if (!r.ok) {
            enqueueSnackbar('An error occurred enrolling students.', { variant: 'error' })
            return
        }
        enqueueSnackbar('Students enrolled', { variant: 'success' })
        setRefetch(!refetch)
        setClearChecked(!clearChecked)
    }

    if (loading) {
        return (<Preloader />)
    }

    return (
        <>
        <Typography
            style={{fontSize: 30}}
        >
            Manage Roster for {plan.display_name}
        </Typography>
        <Paper style={{marginTop: '3em', padding: '1em'}}>
        <Typography style={{fontSize: 24}}>
            Current Roster
        </Typography>
        <a href={api_base + '/schools/' + currentSchool.value + '/store_items/' + planId + '/reports/roster'} download>
            <Button variant='contained' color='primary'>
                Export Roster
            </Button>
        </a>
        <FilterStateProvider>
            <ReactTable
                columns={rosterColumns}
                fetchFunction={rosterFetchFunction}
                defaultShowAll={true}
                informChecked={setCurrentChecked}
                refetch={refetch}
                clearChecked={clearChecked}
            />
        </FilterStateProvider>
        <Button
            variant='contained'
            onClick={handleUnenroll}
            disabled={Object.keys(currentChecked).length === 0}
        >
            Unenroll all {Object.keys(currentChecked).length} selected students
        </Button>
        </Paper>
            <Paper style={{ marginTop: '3em', padding: '1em'}}>
        <Typography style={{ fontSize: 24 }}>
            Enroll New Students
        </Typography>
        <FilterStateProvider>
            <ReactTable
                columns={studentColumns}
                fetchFunction={studentFetchFunction}
                informChecked={setNewChecked}
                refetch={refetch}
                clearChecked={clearChecked}
            />
        </FilterStateProvider>
        <Button
            variant='contained'
            onClick={handleEnroll}
            disabled={Object.keys(newChecked).length === 0}
        >
            Enroll all {Object.keys(newChecked).length} selected students
        </Button>
        </Paper>
        </>
    )

}