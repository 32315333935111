import React from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import { SchoolContext } from 'components/SchoolProvider.js';
import { useSnackbar } from 'notistack';
import { getStudent, updateStudent, createStudent, listUnpaidAssignedStoreItems, listPaidStoreItems } from 'api/students';
import { listStudents } from 'api/students.js';
import { make_params_from_react_table } from 'api/base.js'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant'
import remove from 'lodash/remove';
import without from 'lodash/without';
import some from 'lodash/some';
import clsx from 'clsx';
import { SubmitButton } from 'components/CustomButtons/Button.js'
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js'
import { useHistory } from "react-router-dom";
import Preloader from 'components/Preloader/Preloader';
import { Typography, List, ListItem, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, RadioGroup, Radio } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesSquare } from '@fortawesome/pro-solid-svg-icons';
import { deleteStudent, undeleteStudent } from 'api/students';
import { MaterialNumberFormat } from 'components/CustomInput/NumberFormat';
import NumberFormat from 'react-number-format';
import currency from 'currency.js';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(styles);


export default function EditStudent(props) {
    const classes = useStyles();
    const [currentSchool, setcurrentSchool] = React.useContext(SchoolContext);
    const [student, setStudent] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [submitting, setSubmitting] = React.useState(false);
    const [editSuccess, setEditSuccess] = React.useState(false);
    const [deleteSuccess, setDeleteSuccess] = React.useState(false)
    const [showUnpaid, setShowUnpaid] = React.useState(false);
    const [unpaidLoading, setUnpaidLoading] = React.useState(false)
    const [unpaid, setUnpaid] = React.useState([]);
    const [unpaidTotal, setUnpaidTotal] = React.useState(null)
    const [undeleteDialogOpen, setUndeleteDialogOpen] = React.useState(false)
    const [prevDelStu, setPrevDelStu] = React.useState({})
    const [paidLoading, setPaidLoading] = React.useState(false)
    const [paid, setPaid] = React.useState([]);
    const [showPaid, setShowPaid] = React.useState(false)
    const [guardians, setGuardians] = React.useState([])
    let history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    // Determine if we are creating or editing
    var editing = false;
    var studentId = '';
    if( props.location.pathname.split('/')[4] === 'edit') {
        editing = true;
        studentId = props.location.pathname.split('/')[3];
    }
    const [ formState, dispatch, basicInputOnChange, onChange ] = ControlledInputInternals();

    // Inputs
    const { first_name, last_name, sis_id, grade_level, nslp_status, nslp_reduced_flat_rate, nslp_reduced_percentage } = formState


    // Intialize page
    React.useEffect(() => {
        async function initializePage(schoolId, studentId) {
            if (editing) {
                let r = await getStudent(schoolId, studentId, {include_guardians: true});
                if (r.ok) {
                    setStudent(r.data)
                    setGuardians(r.data.guardians)
                    // Set initial form values using dispatch function from useReducer in ControlledInputInternals
                    for (var key in r.data) {
                        dispatch({ field: key, value: r.data[key]})
                    }
                    setLoading(false)
                }
                else {
                    window.location = '/admin'
                }
            }
            else {
                setLoading(false)
            }
        }
        initializePage(currentSchool.value, studentId)
    }, []) // Never refire


    async function handleUpdateStudent(e) {
        e.preventDefault();
        let form = document.querySelector('form')
        if (!form.reportValidity()) {
            return
        }
        setSubmitting(true);
        setEditSuccess(false);
        var r = null;
        var snackbarText = '';
        if (editing) {
            r = await updateStudent(currentSchool.value, studentId, formState)
            snackbarText = 'updated'
        }
        else {
            r = await createStudent(currentSchool.value, formState)
            snackbarText = 'created'
        }
        setSubmitting(false);
        if (r.ok) {
            enqueueSnackbar('Student ' + snackbarText, {variant: 'success'});
            setEditSuccess(true);
            if (!editing) {
                history.push('/admin/students/' + r.data.id + '/edit')
            }
        } else {
            if (r.status === 409) {
                // Existing Deleted Student
                setPrevDelStu(r.data)
                setUndeleteDialogOpen(true)
            }
            else {
                enqueueSnackbar('An error occurred', { variant: 'error' });
            }
            
        }
    }


    async function handleDeleteStudent(e) {
        e.preventDefault();
        setSubmitting(true);
        setDeleteSuccess(false)
        const r = await deleteStudent(currentSchool.value, studentId)
        if (!r.ok) {
            enqueueSnackbar('An error occurred', { variant: 'error' });
            setSubmitting(false)
            return
        }
        enqueueSnackbar('Student deleted', { variant: 'success' });
        setDeleteSuccess(true)
        setSubmitting(false)
        history.push('/admin/students')
    }


    async function handleUndeleteStudent() {
        setSubmitting(true)
        const r = await undeleteStudent(currentSchool.value, prevDelStu.id)
        if (!r.ok) {
            enqueueSnackbar('An error occurred', { variant: 'error' });
            setSubmitting(false)
            return
        }
        enqueueSnackbar('Student restored', { variant: 'success' });
        setSubmitting(false)
        history.push('/admin/students/' + r.data.id + '/edit')
    }

    async function getUnpaid() {
        setUnpaidLoading(true)
        setShowUnpaid(true)
        const r = await listUnpaidAssignedStoreItems(currentSchool.value, studentId)
        setUnpaid(r.data)
        let total = currency(0)
        r.data.map(item => {
            total = total.add(currency(item.cost))
        })
        setUnpaidTotal(total.format())
        setUnpaidLoading(false)
    }


    async function getPaid() {
        setPaidLoading(true)
        setShowPaid(true)
        const r = await listPaidStoreItems(currentSchool.value, studentId)
        setPaid(r.data)
        setPaidLoading(false)
    }


    function renderPaid() {
        if (paidLoading) {
            return <Preloader />
        }

        return (
            <>
            <br />
            <Typography>
                Paid Fees and Activities
            </Typography>
            {paid.length > 0 &&
                <List>
                    {paid.map( s => (
                        <ListItem>
                            <ListItemText
                                primary={s.display_name}
                                secondary={s.cost}
                            />
                        </ListItem>
                    ))}
                </List>
            }
            {paid.length === 0 &&
                <Typography>
                    No paid items found.
                </Typography>
            }
            </>
        )
    }


    function renderUnpaid() {
        if (unpaidLoading) {
            return <Preloader />
        }

        return (
            <>
            <br />
            <Typography>
                Unpaid Fees and Activities
            </Typography>
            <Typography>
                Total: {unpaidTotal}
            </Typography>
            {unpaid.length > 0 &&
                <List>
                    {unpaid.map(s => (
                        <ListItem>
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    icon={faTimesSquare}
                                    size='2x'
                                    color='red'
                                />
                        </ListItemIcon>
                        <ListItemText primary={s.display_name} />
                    </ListItem>
                    ))}
                    
                </List>
            }
            {unpaid.length === 0 &&
                <Typography>
                    No unpaid items found.
                </Typography>
            }
            </>
        )
    }

    React.useEffect(() => {
        renderUnpaid()
    }, [unpaidLoading, unpaid])

    React.useEffect(() => {
        renderPaid()
    }, [paidLoading, paid])

    if (loading) {
        return (null)
    }
    else {
        return (
            <Paper
                className={classes.pagePaper}
            >
                <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                    spacing={1}
                >
                    <Grid item>
                        <Button variant='contained' color='secondary' onClick={getUnpaid}>
                            Report: Unpaid Fees and Activities
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='contained' color='secondary' onClick={getPaid}>
                            Report: Paid Fees and Activities
                        </Button>
                    </Grid>
                    
                </Grid>
                <Typography>Student Information</Typography>
                <br/><br/>
                <form>
                    <div>
                    <TextField
                            id='first_name'
                            label='First Name'
                            variant='outlined'
                            value={first_name}
                            onChange={basicInputOnChange}
                            style={{marginRight: '1em'}}
                            required
                        />
                        <TextField
                            id='last_name'
                            label='Last Name'
                            variant='outlined'
                            value={last_name}
                            onChange={basicInputOnChange}
                            required
                        />
                    </div>
                    <br/>
                    <div>
                        <TextField
                            id='sis_id'
                            label='SIS ID'
                            variant='outlined'
                            value={sis_id}
                            onChange={basicInputOnChange}
                            style={{marginRight: '1em'}}
                            required
                        />
                        <TextField
                            id='grade_level'
                            label='Grade Level'
                            variant='outlined'
                            value={grade_level}
                            onChange={basicInputOnChange}
                            required
                        />
                    </div>
                    <br />
                    {editing && 
                    <Typography>Guardians</Typography>
                    }
                    {guardians.length === 0 && editing && 
                        'No Guardians Found'
                    }
                    {guardians.length > 0 && 
                    <List>
                        {guardians.map((g) => (
                            <ListItem
                                button
                                onClick={() => history.push('/admin/guardians/' + g.id + '/edit')}
                                //href={'/admin/guardians/' + g.id + '/edit'}
                                key={g.id}
                            >
                                <ListItemIcon>
                                    <FontAwesomeIcon
                                        icon={faUser}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={g.first_name + ' ' + g.last_name + ' | ' + g.email}
                                />
                            </ListItem>
                        ))}
                    </List>
                    }
                    <br /><br/>
                    {currentSchool.lunches_settings.nslp_enabled &&
                    <>
                        <Typography>Free and Reduced Lunch</Typography>
                        <br />
                        <FormControl component='fieldset'>
                            <FormControl component='legend'>FRL Setting</FormControl>
                            <RadioGroup
                                value={nslp_status}
                                name='nslp_status'
                                onChange={(e) => onChange('nslp_status', e.target.value)} 
                            >
                                <FormControlLabel
                                    value='none'
                                    control={<Radio required />}
                                    label='None'
                                />
                                <FormControlLabel
                                    value='reduced'
                                    control={<Radio required />}
                                    label='Reduced'
                                />
                                <FormControlLabel
                                    value='free'
                                    control={<Radio required />}
                                    label='Free'
                                />
                            </RadioGroup>
                        </FormControl>
                        <br />
                        {nslp_status == 'reduced' && currentSchool.lunches_settings.reduced_setting == 'flat_rate_per_student' &&
                            <MaterialNumberFormat
                                id='nslp_reduced_flat_rate'
                                label='Flat rate'
                                variant='outlined'
                                value={nslp_reduced_flat_rate}
                                onChange={basicInputOnChange}
                                margin='normal'
                                required={nslp_status === 'reduced'}
                            />
                        }
                        {nslp_status == 'reduced' && currentSchool.lunches_settings.reduced_setting == 'percentage_per_student' &&
                            <TextField
                                id='nslp_reduced_percentage'
                                label='Percent discount'
                                variant='outlined'
                                value={nslp_reduced_percentage}
                                onChange={basicInputOnChange}
                                margin='normal'
                                required={nslp_status === 'reduced'}
                                InputProps={{
                                    inputComponent: NumberFormat
                                }}
                            />
                        }
                    </>
                    }
                    <Grid
                        container
                        direction="column"
                        justify="flex-end" 
                        alignItems="flex-end"
                    >
                        <SubmitButton
                            submitting={submitting}
                            success={editSuccess}
                            buttonText={editing ? 'Update Student' : 'Create Student'}
                            onClick={handleUpdateStudent}
                        />
                        {editing &&
                        <SubmitButton
                            submitting={submitting}
                            success={deleteSuccess}
                            buttonText='Delete Student'
                            variant='outlined'
                            color='default'
                            onClick={handleDeleteStudent}
                        />
                        }
                    </Grid>
                </form>
                <Dialog
                    open={undeleteDialogOpen}
                    onClose={() => setUndeleteDialogOpen(false)}
                >
                    <DialogTitle>
                        Existing Student Found
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            An existing but previously deleted student was found with SIS ID {sis_id}.
                            The student's name is {prevDelStu.first_name} {prevDelStu.last_name}.
                            Do you wish to restore this student?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setUndeleteDialogOpen(false)}
                            color='primary'
                        >
                            No
                        </Button>
                        <SubmitButton
                            submitting={submitting}
                            success={null}
                            buttonText='Yes'
                            variant={null}
                            onClick={handleUndeleteStudent}
                        />
                    </DialogActions>
                </Dialog>
                {showUnpaid &&
                    renderUnpaid()
                }
                {showPaid &&
                    renderPaid()
                }
            </Paper>
        )
    }
}