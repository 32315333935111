import { api_call, make_response_object } from 'api/base'


export async function listStoreItems(schoolId, params) {
    const r = await api_call('/schools/' + schoolId + '/store_items', 'GET', {}, params)
    return await make_response_object(r)
}


export async function getStoreItem(schoolId, storeItemId) {
    const r = await api_call('/schools/' + schoolId + '/store_items/' + storeItemId, 'GET')
    return await make_response_object(r)
}


export async function createStoreItem(schoolId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/store_items',
        'POST',
        body
    )
    return await make_response_object(r)
}


export async function updateStoreItem(schoolId, storeItemId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/store_items/' + storeItemId,
        'PUT',
        body
    )
    return await make_response_object(r)
}


export async function approveStoreItem(schoolId, storeItemId) {
    const r = await api_call(
        '/schools/' + schoolId + '/store_items/' + storeItemId + '/approve',
        'PUT',
    )
    return await make_response_object(r)
}


export async function deleteStoreItem(schoolId, storeItemId) {
    const r = await api_call(
        '/schools/' + schoolId + '/store_items/' + storeItemId,
        'DELETE'
    )
    return await make_response_object(r)
}


export async function listOrderItemsByStoreItem(schoolId, storeItemId, params) {
    const r = await api_call(
        '/schools/' + schoolId + '/store_items/' + storeItemId + '/order_items',
        'GET',
        {},
        params
    )
    return await make_response_object(r)
}