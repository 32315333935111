import { api_call, make_response_object } from 'api/base'


export async function listStoreGroups(schoolId, params, enqueueSnackbar) {
    const r = await api_call('/schools/' + schoolId + '/store_groups', 'GET', {}, params)
    if (!r.ok) {
        enqueueSnackbar('Error fetching vendors. Please try again.', { variant: 'error' })
    }
    return await make_response_object(r)
}


export async function getStoreGroup(schoolId, storeGroupId, enqueueSnackbar) {
    const r = await api_call('/schools/' + schoolId + '/store_groups/' + storeGroupId, 'GET')
    return await make_response_object(r)
}


export async function createStoreGroup(schoolId, body, enqueueSnackbar) {
    const r = await api_call(
        '/schools/' + schoolId + '/store_groups',
        'POST',
        body
    )
    return await make_response_object(r)
}


export async function updateStoreGroup(schoolId, storeGroupId, body, enqueueSnackbar) {
    const r = await api_call(
        '/schools/' + schoolId + '/store_groups/' + storeGroupId,
        'PUT',
        body
    )
    return await make_response_object(r)
}