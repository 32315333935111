import React from 'react'
import { SchoolContext } from 'components/SchoolProvider.js'
import Select from 'react-select'
import Switch from '@material-ui/core/Switch'
import { listUserForms } from 'api/userforms.js'
import { UserContext } from 'components/UserProvider.js'
import { make_filter_params } from 'api/base'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";


const useStyles = makeStyles(styles);


export default function UserFormSelect(props) {
    const classes = useStyles();
    const [currentSchool, setCurrentSchool] = React.useContext(SchoolContext)
    const currentUser = React.useContext(UserContext)[0]
    const [formList, setFormList] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [switchState, setSwitchState] = React.useState(Boolean(props.value))


    React.useEffect(() => {
        setLoading(true)
        const filterParams = make_filter_params(
            //[{ field: 'owner_id', op: 'eq', value: currentUser.id }]
            []
        )
        listUserForms(currentSchool.value, filterParams)
            .then(r => {
                var apiForms = r.data.results.map(f => {
                    var fs = {}
                    fs['value'] = f.id
                    fs['label'] = f.display_name
                    return fs
                })
                setFormList(apiForms)
                setLoading(false)
            })
    }, [currentSchool, currentUser]) // Refire when school or user changes


    React.useEffect(() => {
        if (props.value) {
            setSwitchState(true)
        }
    }, [props.value])


    function selectValue() {
        return formList.filter(f => {
            return f.value === props.value
        })[0]
    }


    // This select is clearable, so we have to handle this case
    function onChange(selected) {
        if (selected === null) {
            return props.onChange(props.id, null)
        }
        else {
            return props.onChange(props.id, selected.value)
        }
    }


    function handleSwitchChange(e) {
        setSwitchState(e.target.checked)
        // Clear the selected form
        if (!e.target.checked) {
            onChange(null)
        }
    }


    return (
        <Paper
            className={classes.studentSelectionPaper}
        >
            <div>{props.label}</div>
            <br />
            <Switch
                checked={switchState}
                onChange={handleSwitchChange}
                color='primary'
                disabled={props.disabled}
            />
            <label>{props.switchText}</label>
            { !switchState ? null : (
                <Select
                    id={props.id}
                    options={formList}
                    isClearable={true}
                    value={selectValue()}
                    isLoading={loading}
                    onChange={onChange}
                    placeholder='Select a form...'
                    disabled={props.disabled}
                />
            )}
           
        </Paper>
    )
}