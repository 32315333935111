import React from 'react'
import { SchoolContext } from 'components/SchoolProvider.js'
import Select from 'react-select'
import Switch from '@material-ui/core/Switch'
import { listStaff } from 'api/staff.js'
import { UserContext } from 'components/UserProvider.js'
import { make_filter_params } from 'api/base'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";


const useStyles = makeStyles(styles);


export default function StaffSelect(props) {
    const classes = useStyles()
    const [currentSchool, setCurrentSchool] = React.useContext(SchoolContext)
    const currentUser = React.useContext(UserContext)[0]
    const [staffList, setStaffList] = React.useState([])
    const [loading, setLoading] = React.useState(true)


    React.useEffect(() => {
        setLoading(true)
        const filterParams = make_filter_params(
            [{
                field: 'school_staff_grants.school_staff_permission',
                op: 'eq',
                value: 'create_store_item_requests'
            }]
        )
        listStaff(currentSchool.value, filterParams)
            .then(r => {
                var apiStaff = r.data.results.map(s => {
                    var ss = {}
                    ss['value'] = s.id
                    ss['label'] = s.last_name + ', ' + s.first_name
                    return ss
                })
                setStaffList(apiStaff)
                setLoading(false)
            })
    }, [currentSchool])


    function selectValue() {
        return staffList.filter(s => {
            return s.value === props.value
        })[0]
    }


    return (
        <Select
            id={props.id}
            options={staffList}
            isClearable={false}
            value={selectValue()}
            isLoading={loading}
            onChange={(selected) => props.onChange(props.id, selected.value)}
            placeholder='Select a user...'
        />
    )
}