import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';

export default function MultiDayPicker(props){

    function handleMultiDayClick(day, modifiers) {
        var days = props.selectedDays.slice()
        if (modifiers.disabled) {
            return
        }
        if (modifiers.selected) {
            const selectedIndex = days.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            days.splice(selectedIndex, 1);
        }
        else {
            days.push(day);
        }
        props.setSelectedDays(days)
    }


    return (
        <DayPicker
            selectedDays={props.selectedDays}
            onDayClick={handleMultiDayClick}
            disabledDays={
                props.allDates ? {} :
                {
                    before: new Date()
                }
            }
        />
    )
}
