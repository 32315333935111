import React from 'react'
import $ from 'jquery'
import 'assets/css/styles.css';
import styles from "assets/jss/storefront/views/dashboardStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid, IconButton, Paper, MenuItem, MenuList, InputLabel, Select, Button, ListItem, List, ListItemText, FormHelperText, Checkbox } from "@material-ui/core";
import { getStoreItem } from 'api/storeitems'
import { listOrderItems } from 'api/orderitems'
import { make_filter_params } from 'api/base';
import { MaterialNumberFormat } from 'components/CustomInput/NumberFormat.js'
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js'
import { SubmitButton } from 'components/CustomButtons/Button.js'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch'
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import currency from 'currency.js';
import Preloader from 'components/Preloader/Preloader';
import { listOrderItemsByGuardianStudents } from 'api/guardians'
import { SchoolContext } from 'components/SchoolProvider.js';
import { getSchool } from 'api/school'
import { listStudentOrderItems } from 'api/students'
import { getSchoolChildcareSettings } from 'api/schoolchildcaresettings'




const useStyles = makeStyles(styles)
window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");
require('formBuilder/dist/form-render.min.js')


export default function ItemView(props) {
    const classes = useStyles()
    const [loading, setLoading] = React.useState(true)
    const [storeItem, setStoreItem] = React.useState({})
    const schoolId = props.schoolId
    const itemId = props.itemId
    const customer = props.customer
    const userFormRef = React.createRef()
    const permissionFormRef = React.createRef()
    const chaperoneFormRef = React.createRef()
    const driverFormRef = React.createRef()
    const studentSelectForm = React.createRef()
    const registrationFormRef = React.createRef()
    const costForm = React.createRef()
    const costField = React.createRef()
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [allowMore, setAllowMore] = React.useState(false);
    const [needsStudentSelection, setNeedsStudentSelection] = React.useState(false)
    const [availStudents, setAvailStudents] = React.useState([])
    const [childcareStudents, setChildcareStudents] = React.useState([])
    const [displayChildcareStudentForm, setDisplayChildcareStudentForm] = React.useState(true)
    const [childcareStudentFormHasError, setChildcareStudentFormHasError] = React.useState(false)
    const [childcareDisplayNoAvailStudentsError, setChildcareDisplayNoAvailStudentsError] = React.useState(false)
    const [childcareAvailStudents, setChildcareAvailStudents] = React.useState([])
    const currentSchool = React.useContext(SchoolContext)[0]

    const [childcareStudentsRegNeeded, setChildcareStudentsRegNeeded] = React.useState([])
    const [childcareRegistrationFeeAmount, setChildcareRegistrationFeeAmount] = React.useState(null)
    const [childcareRegForm, setChildcareRegForm] = React.useState(null)
    const [recurringCost, setRecurringCost] = React.useState(currency(0))
    const [childcareRegFeeStrat, setChildcareRegFeeStrat] = React.useState(null)
    const [perFamilyRegFeeNeeded, setPerFamilyRegFeeNeeded] = React.useState(true)
    const [regFormRefs, setRegFormRefs] = React.useState({})
    



    const { enqueueSnackbar } = useSnackbar();

    const [formState, dispatch, basicInputOnChange, onChange] = ControlledInputInternals()

    // Inputs
    const inputs = ['cost',]

    React.useEffect(() => {
        async function initPage() {
            const r = await getStoreItem(schoolId, itemId)
            if (r.ok) {
                setStoreItem(r.data)
                inputs.map(key => {
                    if (key === 'cost' && r.data.category_type === 'childcare') {
                        dispatch({field: key, value: '0'})
                    }                            

                    else {
                        dispatch({field: key, value: r.data[key]})
                    }
                })
                setAllowMore(r.data.category_fields !== null && r.data.category_fields.allow_more === true)
                setNeedsStudentSelection(r.data.category_type === 'fees' || r.data.category_type === 'activities' || r.data.category_type === 'childcare')
                
                const item = r.data

                // Needs student selection
                if (r.data.category_type === 'fees' || r.data.category_type === 'activities' || r.data.category_type === 'childcare') {
                    // Guardian's students at this school
                    let schoolStudents = []
                    props.customer.school_students.map(s => {
                        if (s.school_id === r.data.school_id) {
                            schoolStudents.push(s)
                        }
                    })
                    const schoolStudentIds = schoolStudents.map(s => s.id) // Ids only

                    // Get already paid-for students
                    let filters = []

                    // let stuIdsFilterValue = '('
                    // schoolStudentIds.map(id => {
                    //     stuIdsFilterValue = stuIdsFilterValue + "'" + id + "'" + ','
                    // })
                    // stuIdsFilterValue = stuIdsFilterValue + ')'
                    //filters.push({ field: 'student_id', op: 'in', value: stuIdsFilterValue})
                    filters.push({ field: 'store_item_id', op: 'eq', value: itemId})
                    const params = make_filter_params(filters)
                    const r1 = await listOrderItemsByGuardianStudents(currentSchool.value, customer.id, params)
                    if (!r1.ok) {
                        enqueueSnackbar('An error occurred', {variant: 'error'})
                        return
                    }

                    // Only students that have not yet been paid for
                    let aS = []
                    schoolStudents.map(s => {
                        const paidStu = r1.data.results.find(item => item.students.includes(s.id))
                        if (paidStu === undefined) {
                            aS.push(s)
                        }
                    })
                    
                    // Set available students
                    if (item.open_signup === true) {
                        setAvailStudents(aS)
                    }
                    else if (item.category_type === 'childcare') {
                        setAvailStudents(aS)
                        setChildcareAvailStudents(aS)
                    }
                    else {
                        let aSItem = []
                        aS.map(s => {
                            const newStu = item.students.find(iS => iS.id === s.id)
                            if (newStu !== undefined) {
                                aSItem.push(newStu)
                            }
                        })
                        setAvailStudents(aSItem)
                    }
                }

                if (r.data.category_type === 'childcare') {
                    const r1 = await getSchoolChildcareSettings(r.data.school_id)
                    if (r.data.childcare_fields.use_default_registration_settings === true) {
                        setChildcareRegistrationFeeAmount(r1.data.default_registration_fee_amount)
                        setChildcareRegForm(r1.data.default_registration_form)
                        setChildcareRegFeeStrat(r1.data.registration_fee_charge_strategy)
                    }
                    else {
                        if (r.data.childcare_fields.require_registration_fee) {
                            setChildcareRegistrationFeeAmount(r.data.childcare_fields.registration_fee_amount)
                            setChildcareRegForm(r.data.childcare_fields.registration_form)
                        }
                    }
                }
                
                

                // if (props.location.state !== undefined) {
                //     if (props.location.state.preSelectStudent) {
                //         onChange('student', props.location.state.preSelectStudent)
                //     }
                // }
                
                setLoading(false)
            }
            else {
                window.location = '/guardians/dashboard'
            }
        }
        initPage()
    }, [])


    React.useEffect(() => {

        async function handle() {
            if (!storeItem || !childcareRegFeeStrat) { return }
            if (!storeItem.category_type === 'childcare') { return }

            // Determine if registration fees are needed or not
            // For per student, handle on student select
            if (childcareRegFeeStrat === 'per_family') {
                const sR = await getSchool(schoolId, { 'public': true }) // in case schoolId is slug
                const schoId = sR.data.id
                let filters = []
                filters.push({ field: 'category_type', op: 'eq', value: 'childcare' })
                filters.push({ field: 'school_id', op: 'eq', value: schoId })
                const params = make_filter_params(filters)
                for (const s of childcareAvailStudents) {
                    const cR = await listStudentOrderItems(schoId, s.id, params)
                    if (cR.data.results.length > 0) {
                        setPerFamilyRegFeeNeeded(false)
                        break
                    }
                    //If none found, then default is true (set in useState init)
                }
            }
        }
        handle()
    }, [childcareRegFeeStrat, storeItem])


    React.useEffect(() => {
        let rFR = {}
        childcareStudentsRegNeeded.map(s => {
            rFR[s.id] = React.createRef()
        })
        setRegFormRefs(rFR)
    }, [childcareStudentsRegNeeded])


    function formMods(form) {
        form.map(input => {
            if (input.type === 'checkbox') {
                input.addEventListener('input', (e) => onChange(input.value, e.target.checked))
            }
            else {
                input.addEventListener('input', (e) => onChange(input.name, e.target.value))
            }

            let inputName = null
            if (formState[input.name] !== undefined) {
                const inputName = input.name
            }
            if (input.type !== 'radio' && input.type !== 'checkbox') {
                input.value = inputName
            }

        })
    }


    React.useEffect(() => {
        if (loading) { return }

        if (storeItem.form === null) { return }

        let fR = $(userFormRef.current).formRender(
            {
                dataType: 'json',
                formData: storeItem.form.json
            }
        )

        const form = [...userFormRef.current.elements]
        formMods(form)

    }, [loading])


    React.useEffect(() => {
        if (loading) { return }
        if (storeItem.activity_fields === null) { return }
        if (permissionFormRef.current === null) { return }
        if (permissionFormRef.current.hasChildNodes()) { return }

        let fR = $(permissionFormRef.current).formRender(
            {
                dataType: 'json',
                formData: storeItem.activity_fields.permission_form.json
            }
        )

        const form = [...permissionFormRef.current.elements]
        formMods(form)

    }, [permissionFormRef])


    React.useEffect(() => {
        if (loading) { return }
        if (storeItem.activity_fields === null) { return }
        if (storeItem.activity_fields.chaperone_form === null) { return }
        if (chaperoneFormRef.current === null) { return }
        if (chaperoneFormRef.current.hasChildNodes()) { return }

        let fR = $(chaperoneFormRef.current).formRender(
            {
                dataType: 'json',
                formData: storeItem.activity_fields.chaperone_form.json
            }
        )
        
        const form = [...chaperoneFormRef.current.elements]
        formMods(form)

    }, [chaperoneFormRef])


    React.useEffect(() => {
        if (loading) { return }
        if (storeItem.activity_fields === null) { return }
        if (storeItem.activity_fields.parent_driver_form === null) { return }
        if (driverFormRef.current === null) { return }
        if (driverFormRef.current.hasChildNodes()) { return }

        let fR = $(driverFormRef.current).formRender(
            {
                dataType: 'json',
                formData: storeItem.activity_fields.parent_driver_form.json
            }
        )

        const form = [...driverFormRef.current.elements]
        formMods(form)

    }, [driverFormRef])


    React.useEffect(() => {
        if (loading) { return }
        if (storeItem.childcare_fields === null) { return }
        if (childcareRegForm === null) { return }
        //if (registrationFormRef.current === null) { return }
        //if (registrationFormRef.current.hasChildNodes()) { return }
        if (Object.values(regFormRefs).length === 0) { return }
        if (Object.values(regFormRefs)[0].current === null) { return }
        //if (Object.values(regFormRefs)[0].current.hasChildNodes()) { return }

        let rFR = { ...regFormRefs }
        for (const [key, value] of Object.entries(rFR)) {
            let fR = $(value.current).formRender(
                {
                    dataType: 'json',
                    formData: storeItem.childcare_fields.registration_form.json
                }
            )

            const form = [...value.current.elements]
            formMods(form)
        }
    }, [regFormRefs])


    React.useEffect(() => {
        if (loading) { return }
        if (formState.chaperone) {
            const cost = currency(formState.cost).add(storeItem.activity_fields.chaperone_cost).format()
            onChange('cost', cost)
        }
        else {
            onChange('cost', storeItem.cost)
        }

    }, [formState.chaperone])


    function serializeUserForm(cartFormName, formRef, cartItem) {
        const form = [...formRef.current.elements]
        cartItem[cartFormName] = {}
        form.map(input => {
            if (input.type === 'checkbox') {
                if (!cartItem[cartFormName].hasOwnProperty(input.name)) {
                    cartItem[cartFormName][input.name] = {}
                }
                cartItem[cartFormName][input.name][input.value] = formState[input.value]
            }
            else {
                cartItem[cartFormName][input.name] = formState[input.name]
            }
            
        })
    }

    
    function handleAddToCart(e) {
        e.preventDefault()
        setSubmitting(true)
        setSuccess(false)
        if (!costForm.current.reportValidity()) {
            setSubmitting(false)
            return
        }
        if (needsStudentSelection && formState.student === undefined && storeItem.category_type !== 'childcare'){
            enqueueSnackbar('Please select a student', {variant: 'error'})
            setSubmitting(false)
            return
        }
        if (studentSelectForm.current !== null && !studentSelectForm.current.reportValidity()) {
            setSubmitting(false)
            return
        }
        if (userFormRef.current !== null && !userFormRef.current.reportValidity()) {
            setSubmitting(false)
            return
        }
        if (permissionFormRef.current !== null && !permissionFormRef.current.reportValidity()) {
            setSubmitting(false)
            return
        }
        if (chaperoneFormRef.current !== null && !chaperoneFormRef.current.reportValidity()) {
            setSubmitting(false)
            return
        }
        if (driverFormRef.current !== null && !driverFormRef.current.reportValidity()) {
            setSubmitting(false)
            return
        }
        // if (registrationFormRef.current !== null && !registrationFormRef.current.reportValidity()) {
        //     setSubmitting(false)
        //     return
        // }
        if (childcareStudentsRegNeeded.length > 0) {
            for (const [key, value] of Object.entries(regFormRefs)) {
                if (value.current !== null && !value.current.reportValidity()) {
                    setSubmitting(false)
                    return
                }
            }
        }
        if (storeItem.category_type === 'childcare' && childcareStudents.length === 0) {
            setChildcareStudentFormHasError(true)
            setSubmitting(false)
            return
        }
        
        console.log('good!')

        let student = undefined
        if (formState.student !== undefined) {
            student = availStudents.find(s => s.id === formState.student)
        }
        
        var newCartItem = {
            store_item: storeItem,
            cost: formState.cost,
            student: student,
            subscribe: formState.subscribe
        }

        if (userFormRef.current !== null) {
            serializeUserForm('user_form', userFormRef, newCartItem)
        }
        if (permissionFormRef.current !== null) {
            serializeUserForm('permission_form', permissionFormRef, newCartItem)
        }
        if (formState.chaperone) {
            newCartItem['chaperone'] = true
            if (storeItem.activity_fields.chaperone_form !== null) {
                serializeUserForm('chaperone_form', chaperoneFormRef, newCartItem)
            }
        }
        if (formState.driver) {
            newCartItem['parent_driver'] = true
            if (storeItem.activity_fields.parent_driver_form !== null) {
                serializeUserForm('parent_driver_form', driverFormRef, newCartItem)
            } 
        }
        // if (registrationFormRef.current != null) {
        //     serializeUserForm('registration_form', registrationFormRef, newCartItem)
        // }

        if (childcareStudentsRegNeeded.length > 0 && childcareRegForm) {
            let f = []
            for (const [key, value] of Object.entries(regFormRefs)) {
                serializeUserForm('childcare_registration_form_' + key, value, newCartItem)
            }
        }

        if (storeItem.category_type === 'childcare') {
            newCartItem['childcare_students'] = childcareStudents
            newCartItem['registration_students'] = childcareStudentsRegNeeded
            newCartItem['family_registration'] = perFamilyRegFeeNeeded
        }
        
        props.addToCart(newCartItem)

        setSuccess(true)
        setSubmitting(false)
        enqueueSnackbar('Item added to cart.', { variant: 'success' })

    }


    function checkCost() {
        if (storeItem.category_type !== 'donations') { return }
        const cost = document.querySelector('#cost')
        if (cost === null) { return }
        if (Number(formState.cost) < Number(storeItem.cost)) {          
            cost.setCustomValidity('The minimum donation is $' + storeItem.cost)
        }
        else {
            cost.setCustomValidity('')
        }
    }


    async function handleChildcareNewStudentChange(e) {
        let newStudents = childcareStudents.slice()
        newStudents.push(childcareAvailStudents.find(s => s.id === e.target.value))
        setChildcareStudents(newStudents)
        setDisplayChildcareStudentForm(false)
        setChildcareStudentFormHasError(false)

        const sR = await getSchool(schoolId, { 'public': true }) // in case schoolId is slug
        const schoId = sR.data.id

        let filters = []
        filters.push({ field: 'category_type', op: 'eq', value: 'childcare' })
        filters.push({ field: 'school_id', op: 'eq', value: schoId })
        const params = make_filter_params(filters)



        const r = await listStudentOrderItems(schoId, e.target.value, params)

        if (r.data.results.length === 0) {
            let rS = childcareStudentsRegNeeded.slice()
            rS.push(availStudents.find(s => s.id === e.target.value))
            setChildcareStudentsRegNeeded(rS)
        }
    }


    function handleChildcareNewStudentAdd() {
        if (displayChildcareStudentForm) {
            setChildcareStudentFormHasError(true)
            return
        }
        if (childcareAvailStudents.length === 0) {
            setChildcareDisplayNoAvailStudentsError(true)
        }
        else {
            setDisplayChildcareStudentForm(true)
        }
    }


    async function handleChildcareSelectedStudentChange(sId, i) {
        let newStudents = childcareStudents.slice()
        newStudents[i] = childcareAvailStudents.find(s => s.id === sId)
        setChildcareStudents(newStudents)

        const sR = await getSchool(schoolId, { 'public': true }) // in case schoolId is slug
        const schoId = sR.data.id

        let filters = []
        filters.push({ field: 'category_type', op: 'eq', value: 'childcare' })
        filters.push({ field: 'school_id', op: 'eq', value: schoId })
        const params = make_filter_params(filters)

        const r = await listStudentOrderItems(schoId, sId, params)

        if (r.data.results.length === 0) {
            let rS = childcareStudentsRegNeeded.slice()
            rS[i] = availStudents.find(s => s.id === sId)
            setChildcareStudentsRegNeeded(rS)
        }
    }


    React.useEffect(() => {
        const newStudents = availStudents.filter(aS => {
            const found = childcareStudents.find(s => s.id === aS.id)
            if (!found) {
                return aS
            }
        })
        setChildcareAvailStudents(newStudents)
        
        if (storeItem.childcare_fields !== undefined) {
            // const newCost = currency(storeItem.cost).add(
            //      currency(storeItem.childcare_fields.sibling_cost).multiply(childcareStudents.length - 1)
            // )
            let newCost = currency(0)
            const l = childcareStudents.length
            const sibling_cost = currency(storeItem.childcare_fields.sibling_cost)
            const sibling_cost_2nd = currency(storeItem.childcare_fields.sibling_cost_2nd)

            const base = currency(storeItem.cost, {symbol: ''})
            if (l === 1) {
                newCost = base
            }
            else if ( l === 2) {
                newCost = base.add(sibling_cost)
            }
            else if (l > 2) {
                newCost = base.add(sibling_cost).add(
                    sibling_cost_2nd.multiply(l - 2)
                )
            }


            setRecurringCost(newCost) // Cost without registration fees

            if (childcareRegFeeStrat === 'per_family') {
                if (perFamilyRegFeeNeeded){
                    onChange(
                        'cost',
                        newCost.add(
                            currency(childcareRegistrationFeeAmount)
                        ).format()
                    )
                }
                else (
                    onChange(
                        'cost',
                        newCost.format()
                    )
                )
            }

            else {
                onChange(
                    'cost',
                    newCost.add(
                        currency(childcareRegistrationFeeAmount).multiply(childcareStudentsRegNeeded.length)
                    ).format()
                )
            }
            
            
        }
    }, [childcareStudents, childcareStudentsRegNeeded])


    if (loading) {
        return (<Preloader />)
    }

    const cT = storeItem.category_type
    if (cT === 'lunches') {
        window.location = '/guardians/dashboard'
    }

    return (
        <>
            <Typography component='span'>
                <Box fontSize={32}>
                    {storeItem.display_name}
                </Box>
            </Typography>
            <Typography component='span'>
                <Box dangerouslySetInnerHTML={{__html: storeItem.description}} />
            </Typography>
            <br />
            {allowMore &&
                <Typography style={{marginBottom: 8}}>
                    Minimum Donation: ${storeItem.cost}
                </Typography>
            }
            <form id='costForm' ref={costForm} onSubmit={(e) => handleAddToCart(e)}>
                <MaterialNumberFormat
                    id='cost'
                    value={formState.cost}
                    onChange={basicInputOnChange}
                    onInput={checkCost()}
                    label='Amount'
                    required
                    variant='outlined'
                    disabled={!allowMore}
                />
            </form>
            <br />
            {(needsStudentSelection && storeItem.category_type !== 'childcare') &&
                <Paper className={classes.storeItemViewSection}>
                    <Typography component='span'>
                        <Box fontSize={22}>Please select a student.</Box>
                    </Typography>
                    <form id='studentSelectForm' ref={studentSelectForm}>
                        <FormControl component='fieldset'>
                            <RadioGroup
                                name='student'
                                id='student'
                                value={formState.student}
                                onChange={(e => onChange('student', e.target.value))}
                            >
                                {availStudents.map(s => (
                                    <FormControlLabel
                                        key={s.id}
                                        value={s.id}
                                        control={<Radio required/>}
                                        label={s.first_name + ' ' + s.last_name}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </form>
                </Paper>
            }
            {storeItem.category_type === 'childcare' &&
                <>
                <Paper className={classes.storeItemViewSection}>
                    <Typography style={{ fontSize: 22 }}>
                        Students
                    </Typography>
                        {childcareStudents.map((s, i) => {
                            let aS = childcareAvailStudents.slice()
                            aS.push(s)

                            return (
                                <>
                                <FormControl style={{ minWidth: '250px' }}>
                                    <Select
                                        id={'childcare-student-' + i}
                                        value={s.id}
                                        onChange={(e) => handleChildcareSelectedStudentChange(e.target.value, i)}
                                    >
                                        {aS.map(s => (
                                            <MenuItem value={s.id}>{s.first_name} {s.last_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <br /><br />
                                </>
                            )

                        })}
                    <br /><br />
                    <form
                        id='childcareStudentSelectForm'
                        style={{display: displayChildcareStudentForm ? '' : 'none'}}
                    >
                        <Typography style={{ fontSize: 22 }}>
                            Select a Student
                        </Typography>
                        <Grid
                            container
                            direction='row'
                            alignItems='flex-end'
                            spacing={2}
                        >
                            <Grid item>
                                <FormControl required style={{ minWidth: '250px' }}>
                                    <InputLabel id='childcare-student-select-label'>Student</InputLabel>
                                    <Select
                                        labelId='childcare-student-select-label'
                                        id='childcare-student-select'
                                        onChange={handleChildcareNewStudentChange}
                                    >
                                        {childcareAvailStudents.map(s => (
                                            <MenuItem value={s.id}>{s.first_name} {s.last_name}</MenuItem>
                                        ))}
                                    </Select>
                                    {childcareStudentFormHasError &&
                                        <FormHelperText>Please select a student.</FormHelperText>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    Cost: {childcareStudents.length === 0 ? 
                                        currency(storeItem.cost).format() :
                                        currency(storeItem.childcare_fields.sibling_cost).format()
                                        }
                                </Typography>
                            </Grid>
                        </Grid>
                        
                    </form>
                    <Typography style={{display: childcareDisplayNoAvailStudentsError ? '' : 'none'}}>
                        You have no additional students available to add.
                    </Typography>
                    <br />
                    <Button
                        variant='contained'
                        color='primary'
                        startIcon={<FontAwesomeIcon icon={faPlus} />}
                        onClick={handleChildcareNewStudentAdd}
                        style={{display: displayChildcareStudentForm ? 'none' : ''}}
                    >
                        Add another Student for {currency(storeItem.childcare_fields.sibling_cost).format()}
                    </Button>
                </Paper>
                <br/>
                {storeItem.childcare_fields.registration_form !== null &&
                    childcareStudentsRegNeeded.map(s => (
                        <>
                            <Paper className={classes.storeItemViewSection}>
                                <Typography component='span'>
                                    <Box fontSize={22}>Please fill out the registration form for {s.first_name}.</Box>
                                </Typography>
                                <br />
                                <form id={s.id + '_registrationForm'} ref={regFormRefs[s.id]} />
                            </Paper>
                            <br /><br /><br />
                        </>
                    ))
                }
                <br />
                {storeItem.childcare_fields.plan_type === 'monthly' &&
                    <Paper className={classes.storeItemViewSection}>
                        <Typography component='span'>
                            <Box fontSize={22}>Subscribe to this plan?</Box>
                        </Typography>
                        <br />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formState.childcareSubscribe}
                                    onChange={basicInputOnChange}
                                    name='subscribe'
                                    id='subscribe'
                                    color='primary'
                                />
                            }
                            label={
                                'Yes, subscribe to this plan and automatically bill me each month for ' +
                                formState.cost
                            }
                        />
                    </Paper>
                }
                </>
            }
            {storeItem.form !== null &&
                <Paper className={classes.storeItemViewSection}>
                    <Typography component='span'>
                        <Box fontSize={22}>Please fill out this form to provide additional information.</Box>
                    </Typography>
                    <form id='userForm' ref={userFormRef} />
                </Paper>
            }
            {storeItem.activity_fields !== null &&
                (storeItem.activity_fields.collect_permission_form &&
                    <Paper className={classes.storeItemViewSection}>
                        <Typography component='span'>
                            <Box fontSize={18}>Permission Form</Box>
                        </Typography>
                        <form id='permissionForm' ref={permissionFormRef} />
                    </Paper>
                )
            }
            {storeItem.activity_fields !== null &&
                (storeItem.activity_fields.chaperones_needed &&
                    <Paper className={classes.storeItemViewSection}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formState.chaperone}
                                    onChange={(e) => onChange('chaperone', e.target.checked)}
                                    color='primary'
                                />
                            }
                            label='I will be a chaperone.'
                        />
                        {formState.chaperone && storeItem.activity_fields.chaperone_form !== null && (
                            <>
                                <Typography component='span'>
                                    <Box fontSize={18}>Please fill out this form to provide additional information.</Box>
                                </Typography>
                                <form id='chaperoneForm' ref={chaperoneFormRef} />
                            </>
                        )}
                    </Paper>
                )
            }
            {storeItem.activity_fields !== null &&
                (storeItem.activity_fields.parent_drivers_needed &&
                    <Paper className={classes.storeItemViewSection}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formState.driver}
                                    onChange={(e) => onChange('driver', e.target.checked)}
                                    color='primary'
                                />
                            }
                            label='I will drive students.'
                        />
                        {formState.driver && storeItem.activity_fields.parent_driver_form !== null && (
                            <>
                                <Typography component='span'>
                                    <Box fontSize={18}>Please fill out this form to provide additional information.</Box>
                                </Typography>
                                <form id='driverForm' ref={driverFormRef} />
                            </>
                        )}
                    </Paper>    
                )
            }
            <Grid
                container
                direction='row'
                justify='flex-end'
                alignItems='center'
            >
                <SubmitButton
                    submitting={submitting}
                    success={success}
                    buttonText='Add to Cart'
                    onClick={handleAddToCart}
                />
            </Grid>
        </>
    )
}