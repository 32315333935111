/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import Login from 'layouts/Login.js'
import RTL from "layouts/RTL.js";
import Register from 'layouts/Register.js'

import "assets/css/material-dashboard-react.css?v=1.8.0";
import { checkAuth, whoAmI } from 'api/auth'

import { SchoolProvider } from 'components/SchoolProvider.js'
import { UserProvider, UserContext } from 'components/UserProvider.js'
import { SnackbarProvider } from 'notistack';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Button from '@material-ui/core/Button';
import { UserSettingsProvider } from "components/UserSettingsProvider";


const hist = createBrowserHistory();

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)


class PrivateRoute extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      loaded: false
    };
  }

  async componentDidMount() {
    //const [user, setUser] = React.useContext(UserContext)
    let r = await whoAmI();
    if (r.ok && (r.data.school_staff_grants.length > 0 || r.data.super_admin_grants.length > 0 || r.data.rep_for_schools.length > 0) ) {
      localStorage.setItem('appUser', JSON.stringify(r.data))
      //setUser(r.data)
      this.setState({
        isAuthenticated: true,
        loaded: true
      })
    } else {
      this.setState({
        loaded: true
      })
    }
  }


  render() {
     const {component: Component, ...rest} = this.props;

     // add action to all snackbars
     const notistackRef = React.createRef();
     const onClickDismiss = key => () => { 
        notistackRef.current.closeSnackbar(key);
     }

     if (!this.state.loaded) return null; 
     return (
         <Route {...rest} render={props => (
             this.state.isAuthenticated ? (
               <UserProvider>
                <UserSettingsProvider>
                <SchoolProvider>
                  <SnackbarProvider
                    maxSnack={3}
                    ref={notistackRef}
                    action={(key) => (
                      <Button onClick={onClickDismiss(key)}>
                        Dismiss
                      </Button>
                    )}
                  >
                    <Elements stripe={stripePromise}>
                      <Component {...props}/>
                    </Elements>
                  </SnackbarProvider>
                </SchoolProvider>
                </UserSettingsProvider>
              </UserProvider> 
         ) : (
          <Redirect to={{
              pathname: '/login', 
              state: {from: props.location }
          }}/>
         )
       )}/>
     );
  }
}


class SnackbarRoute extends React.Component {
  render() {
    const {component: Component, ...rest} = this.props

    // add action to all snackbars
    const notistackRef = React.createRef();
    const onClickDismiss = key => () => {
      notistackRef.current.closeSnackbar(key);
    }

    return (
      <Route {...rest} render={props => (
        <SnackbarProvider
          maxSnack={3}
          ref={notistackRef}
          action={(key) => (
            <Button onClick={onClickDismiss(key)}>
              Dismiss
            </Button>
          )}
        >
          <Component {...props}/>
        </SnackbarProvider>
      )}
    />
    )
  }
}


ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <PrivateRoute path="/admin" component={Admin} />
      <SnackbarRoute path='/login' component={Login} />
      <SnackbarRoute path='/register' component={Register} />
      <Route path="/rtl" component={RTL} />
      <Redirect from="/" to="/admin/dashboard" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
