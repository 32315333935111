import React from 'react'
import $ from 'jquery'
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { SubmitButton } from 'components/CustomButtons/Button.js'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TextField, Typography } from '@material-ui/core'
import { ControlledInputInternals } from 'components/CustomInput/CustomInput.js'
import { getUserForm, createUserForm, updateUserForm } from 'api/userforms.js'
import { SchoolContext } from 'components/SchoolProvider.js';
import { useSnackbar } from 'notistack';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';


const useStyles = makeStyles(styles);
window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");
require('formBuilder/dist/form-render.min.js')


export default function EditForm(props) {
    const classes = useStyles();
    const [currentSchool, setcurrentSchool] = React.useContext(SchoolContext);
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const formBuilderRef = React.createRef();
    const [formBuilder, setFormBuilder] = React.useState({});
    const formRenderRef = React.createRef();
    const [formBuilderData, setFormBuilderData] = React.useState({})
    const [loading, setLoading] = React.useState(true);
    const [inUse, setInUse] = React.useState(false);

    const [formState, dispatch, basicInputOnChange] = ControlledInputInternals();
    const { display_name } = formState;

    const { enqueueSnackbar } = useSnackbar();
    let history = useHistory();

    // Determine if we are creating or editing
    var editing = false;
    var formId = '';
    if (props.location.pathname.split('/')[4] === 'edit') {
        editing = true;
        formId = props.location.pathname.split('/')[3];
    }

    // Initialize Page
    React.useEffect(() => {
        async function initializePage(schoolId, formId) {
            if (editing) {
                let r = await getUserForm(schoolId, formId)
                if (r.ok) {
                    dispatch({field: 'display_name', value: r.data['display_name']})
                    setLoading(false)
                    setFormBuilderData(r.data['json'])
                    setInUse(r.data.in_use)
                    console.log('in use', r.data.in_use)
                }
                else {
                    window.location = '/admin'
                }
            }
            else {
                setLoading(false)
                setFormBuilderData({})
            }
        }
        initializePage(currentSchool.value, formId)
    }, []) // Never refire


    // Set up Form Builder
    React.useEffect(() => {
        if (!loading) {
            var options = {
                disableFields: [
                    'autocomplete',
                    'button',
                    'file',
                    'hidden',
                    'select',
                    'date',
                ],
                disabledActionButtons: [
                    'data',
                    'save'
                ],
                dataType: 'json',
                formData: formBuilderData,
            }
            setFormBuilder($(formBuilderRef.current).formBuilder(options));
        }
    }, [formBuilderData])


    async function handleUpdateForm(e) {
        e.preventDefault();
        let form = document.querySelector('form')
        if (!form.reportValidity()) {
            return
        }

        setSubmitting(true);
        setSuccess(false);

        var form_json = formBuilder.actions.getData('json', true)
        const userForm = {
            display_name: display_name,
            json: form_json
        }

        var r = null;
        var snackbarText = '';
        if (editing) {
            r = await updateUserForm(currentSchool.value, formId, userForm)
            snackbarText = 'updated'
        }
        else {
            r = await createUserForm(currentSchool.value, userForm)
            snackbarText = 'created'
        }
        setSubmitting(false);
        if (r.ok) {
            enqueueSnackbar('Form ' + snackbarText, {variant: 'success'})
            setSuccess(true)
            if (!editing) {
                history.push('/admin/forms/' + r.data.id + '/edit')
            }
        }
        else {
            enqueueSnackbar('An error occurred', {variant: 'error'})
        }


        // console.log(d)
        // var fR = $(formRenderRef.current).formRender(
        //     {
        //         dataType: 'json',
        //         formData: d,
        //     }
        // );
        
    }


    if (loading) {
        return (null)
    }

    return (
        <Paper
            className={classes.pagePaper}
        >
            <h4>Do not request private information from parents or customers, such as SSN.</h4>
            <br />
            {inUse &&
            <>
            <Grid
                container
                direction='row'
                spacing={1}
            >
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={faExclamationTriangle} size='3x' color='red'/>
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{fontSize: 22}}>
                        This form is in use. Changes will affect reporting and future payments.
                    </Typography>
                </Grid>
            </Grid>
            <br />
            </>
            }
            <form>
                <TextField
                    id='display_name'
                    label='Form Name'
                    variant='outlined'
                    value={display_name}
                    onChange={basicInputOnChange}
                    required
                />
                <br /><br />
                <div id="fb-editor" ref={formBuilderRef} />
                <br /><br />
                <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                >
                    {inUse &&
                    <>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={faExclamationTriangle} size='3x' color='red'/>
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{fontSize: 22}}>
                        This form is in use. Changes will affect reporting and future payments.
                    </Typography>
                </Grid>
                </>
                    }
                    <SubmitButton
                        submitting={submitting}
                        success={success}
                        buttonText={editing ? 'Update Form' : 'Create Form'}
                        onClick={handleUpdateForm}
                    />
                </Grid>
            </form>
            {/* <br /><br /><br /><br />
            <div id="form-render" ref={formRenderRef} /> */}
        </Paper>
    )

}