import React from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import { SchoolContext } from 'components/SchoolProvider.js'
import { UserContext } from 'components/UserProvider.js'
import { listUserForms } from 'api/userforms.js'
import { make_params_from_react_table, make_filter_params } from 'api/base.js'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant'
import { useHistory, Link } from "react-router-dom";
import ReactTablePreloader from 'components/Preloader/ReactTablePreloader.js';


const useStyles = makeStyles(styles);


export default function UserFormsPage() {
    const classes = useStyles();
    const [currentSchool, setCurrentSchool] = React.useContext(SchoolContext)
    const currentUser = React.useContext(UserContext)[0]
    const [formList, setFormList] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [pages, setPages] = React.useState(0)
    const reactTable = React.createRef();
    let history = useHistory();


    function makeParams(state) {
        var params = make_params_from_react_table(state)
        let filterParams = []
        if (currentUser.super_admin_grants.length === 1) {
            filterParams = []
        }
        else {
            filterParams = make_filter_params(
                [{field: 'owner_id', op: 'eq', value: currentUser.id}]
            )
        }
        return {...params, ...filterParams}
    }


    React.useEffect(() => {
        const params = makeParams(reactTable.current.state)
        listUserForms(currentSchool.value, params)
            .then(r => {
                setFormList(r.data.results)
                setPages(r.data.pages)
                setLoading(false)
            })
        
    }, [currentSchool, currentUser]) // Refire when school or user changes


    // Debounce listUserForms API call so it doesn't fire on every filter keystroke
    const listUserFormsDebounced = useConstant(() =>
        AwesomeDebouncePromise(
            listUserForms,
            400
        )
    )


    async function handleOnFetchData(state, instance) {
        if (!loading) {
            setLoading(true)
            const params = makeParams(state)
            const r = await listUserFormsDebounced(currentSchool.value, params)
            setFormList(r.data.results)
            setPages(r.data.pages)
            setLoading(false)
        }
    }


    function rowHandlers(state, rowInfo, column, instance) {
        return{
            onClick: e => {
                history.push('/admin/forms/' + rowInfo.original.id + '/edit')
            },
            style: {
                cursor: 'pointer'
            }
        } 
    }


    const columns = [
        {
            Header: 'Form Name',
            accessor: 'display_name',
        },
    ]


    return(
        <Paper
                className={classes.pagePaper}
            >
                <Link to='/admin/forms/new'>
                    <Button
                        variant='contained'
                        color='primary'
                    >
                        Create New Form
                    </Button>
                </Link>
                <br/><br/>
                <ReactTable
                    ref={reactTable}
                    data={formList}
                    columns={columns}
                    pages={pages}
                    //page={currentPage}
                    defaultPageSize={10}
                    filterable={true}
                    loading={loading}
                    LoadingComponent={ReactTablePreloader}
                    onFetchData={handleOnFetchData}
                    getTrProps={rowHandlers}
                    className='-highlight'
                    manual
                />
            </Paper>
    )
}