import { api_call, make_response_object } from 'api/base'


export async function getSchoolChildcareSettings(schoolId) {
    const r = await api_call(
        '/schools/' + schoolId + '/school_childcare_settings',
        'GET'
    )
    return await make_response_object(r)
}



export async function updateSchoolChildcareSettings(schoolId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/school_childcare_settings',
        'PUT',
        body
    )
    return await make_response_object(r)
}