import { api_call, make_response_object } from 'api/base'


export async function listPayoutMethods(schoolId, params) {
    const r = await api_call('/schools/' + schoolId + '/payout_methods', 'GET', {}, params)
    return await make_response_object(r)
}

export async function getPayoutMethod(schoolId, methodId) {
    const r = await api_call(
        '/schools/' + schoolId + '/payout_methods/' + methodId,
        'GET'
    )
    return await make_response_object(r)
}


export async function createPayoutMethod(schoolId, body) {
    const r = await api_call(
        '/schools/' + schoolId + '/payout_methods',
        'POST',
        body
    )
    return await make_response_object(r)
}